/*
 * @Author: Src
 * @Date: 2024-10-10 15:39:20
 * @LastEditTime: 2024-11-13 09:39:48
 * @LastEditors: Src
 * @FilePath: /work-station/src/models/brand.model.ts
 * @Description: 头部注释配置模板
 */
import { IBrand } from '@/common/interface/account';

export class Brand implements IBrand {
  name = '';
  id = 0;
  brandcode = '';
  brandAdmin = '';
  createTime = '';
  shopNum = '';
  protection = '';
  logo = '';
  headlineImage = '';
  isLinked = false;
  autoWithdrawl = '否';
  serviceProvider = '';
  primaryAccount = '';
  withdrawal_account_name = '';
  withdrawal_account = '';
  headerPic = [];

  static from(data: any) {
    const brand = new Brand();
    brand.id = data.id || 0;
    brand.name = data.name;
    brand.brandcode = data.brand_code || '';
    brand.createTime = data.create_time || '';
    brand.shopNum = data.shop_count;
    brand.protection = data.protection_rules || '--';
    brand.logo = data.logo || '';
    brand.headlineImage =
      data.header_pic.length > 0 ? data.header_pic[0].url : '';
    brand.isLinked = data.is_abutment;
    brand.autoWithdrawl = data.is_auto_payment;
    brand.serviceProvider = data.service_name || '--';
    brand.primaryAccount = data.owner_id || '--';
    brand.brandAdmin = data.staff_id || '--';
    brand.headerPic = data.header_pic || [];
    brand.withdrawal_account_name = data.withdrawal_account_name || '';
    brand.withdrawal_account = data.withdrawal_account || '';
    return brand;
  }
}
