
import { Vue, Component, Watch, Model } from 'vue-property-decorator';

@Component({})
export default class OnSiteService extends Vue {
  val: any = '0';

  @Model('change', {
    type: String,
  })
  value!: string;

  @Watch('val')
  watchInternalVal() {
    this.$emit('change', this.val);
  }

  @Watch('value')
  watchValue() {
    this.val = this.value;
  }


  changeVal(e: any) {
    this.val = e.target.value;
  }

  mounted() {
    this.watchValue();
    this.$emit('updateSubmittable', true);
  }
}
