import { render, staticRenderFns } from "./AuditList.vue?vue&type=template&id=10d1b4c7&scoped=true"
import script from "./AuditList.vue?vue&type=script&lang=ts"
export * from "./AuditList.vue?vue&type=script&lang=ts"
import style0 from "./AuditList.vue?vue&type=style&index=0&id=10d1b4c7&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10d1b4c7",
  null
  
)

export default component.exports