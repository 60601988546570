/*
 * @Author: Src
 * @Date: 2024-07-16 14:41:02
 * @LastEditTime: 2024-12-26 10:40:11
 * @LastEditors: Src
 * @FilePath: /work-station/src/services/withdraw.service.ts
 * @Description: 头部注释配置模板
 */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { WithdrawOrderItem, WithdrawOrderRes } from '@/common/interface/withdraw';
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

@injectable()
export default class WithdrawService {
  @inject(HttpClient)
  protected http!: HttpClient;

  async all(
    params: {
      pageSize?: number;
      page: number;
      shopName?: string;
      status?: number;
    },
    loadingEl?: Element | Vue,
  ): Promise<
    {
      lastPage: number;
      page: number;
      total: number;
      pageSize: string;
      list: WithdrawOrderItem[];
    }> {
    const res = await this.http.get('withdraw/list', { params, data: { loadingEl } }) as {
      list: WithdrawOrderRes[];
      lastPage: number;
      page: number;
      pageSize: string;
      total: number;
    };

    const { list, lastPage, page, pageSize, total } = res;
    return {
      lastPage,
      page,
      total,
      pageSize,
      list: list.map((item) => {
        const { account, amountCent, createTime, note, orderId, payOrderId, shopId, shopName, status, updateTime, userName, regionName, withdrawStatus, encrypt_account } = item;
        return {
          alipay: {
            account,
            name: userName,
            encrypt_account,
          },
          createTime,
          amountCent,
          remark: note,
          orderId,
          encrypt_account,
          alipayOrderId: payOrderId,
          shopId,
          shopName,
          status,
          updateTime,
          regionName: regionName || '--',
          withdrawStatus: withdrawStatus || '--',
        };
      }),
    };
  }

  /** 转账 */
  async transfer(
    orderId: number,
    loadingEl?: Element | Vue,
  ) {
    const res = await this.http.post(`withdraw/agreeWithDraw/${orderId}`,
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      }) as string;
    return res;
  }

  /** 提现撤销 */
  async cancel(orderId: number, loadingEl?: Element | Vue) {
    const res = await this.http.post(`withdraw/revokeApply/${orderId}`,
      {
        data: {
          loadingEl: loadingEl || document.body,
        },
      }) as string;
    return res;
  }

  async kaApplyList(params: any) {
    const res = await this.http.get('withdraw/ka_apply_list', { params }) as any;
    return res;
  }

  /** 申请提现 */
  async applyFor(params: any) {
    const res = await this.http.post('withdraw/ka_apply', params) as any;
    return res;
  }

  /** 提现审核列表 */
  async withdrawalOrderList(params: any) {
    const res = await this.http.get('withdraw/withdrawal_order_list', { params }) as any;
    return res;
  }

  /** 提现审明细 */
  async kaWithdrawalRecord(params: any) {
    const res = await this.http.get('withdraw/ka_withdrawal_record', { params }) as any;
    return res;
  }

  /** 提现操作日志 */
  async withdrawalOperateLog(withdrawId: any) {
    const res = await this.http.get(`query/withdraw/${withdrawId}/operate/log`) as any;
    return res;
  }

  /** 提现审核详情 */
  async withdrawalDetail(params: any) {
    const res = await this.http.get('withdraw/withdrawal_detail', { params }) as any;
    return res;
  }

  /** 提现审核验证 */
  async verifyWithdrawalOrder(params: any) {
    const res = await this.http.post('withdraw/verify_withdrawal_order', params) as any;
    return res;
  }

  /** 结算单 */
  async withdrawVerifyOrder(params: any) {
    const data: any = {
      ka_id: params.brand,
      verify_ts_start: params.verifyts_start,
      verify_ts_end: params.verifyts_end,
      page: params.page,
      pageSize: params.pageSize,
    };
    if (params.order_no) {
      data.order_no = params.order_no;
    }
    if (params.shopName) {
      data.shop_name = params.shopName;
    }
    if (params.regionId) {
      data.regionId = params.regionId;
    }
    if (params.ctime_start) {
      data.order_ts_start = params.ctime_start;
    }
    if (params.ctime_end) {
      data.order_ts_end = params.ctime_end;
    }
    const url = params.export ? 'withdraw/verify_order/export' : 'withdraw/verify_order';
    const res = await this.http.get(url, { params: data, responseType: params.export ? 'arraybuffer' : 'json' }) as any;
    return res;
  }
}
