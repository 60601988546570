
import lazyInject from '@/di';
import { Vue, Component, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';
import { BASE_URL } from '@/common/constant';
import { ISPUImageUploadOptions } from '@/common/interface/spu';
import UserService from '@/services/user.service';
import { BrandService } from '@/services/brand_service';
import { component as Viewer } from 'v-viewer';
import { Brand } from '@/models/brand.model';


@Component({
  components: {
    Viewer,
  },
})
export default class DetailBrandModal extends Vue {
  uploading = false;

  BASE_URL = BASE_URL;

  titleName = '品牌详情';

  oldImages = '';

  isLoading = false;

  isVisible = false;

  token: string | undefined = undefined;

  fileList: Array<{
    id: number;
    uid: any;
    name: any;
    status: any;
    url: string;
  }> = [];

  imageListId: any = [];

  form = {
    name: '',
    primaryAccount: '',
    brandAdmin: '',
    brandcode: '',
    prodLink: '',
    logo: '',
    withdrawal_account_name: '',
    withdrawal_account: '',
    headlineImage: { id: 0, url: '' },
    id: undefined as number | undefined,
  };

  options: ISPUImageUploadOptions = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  rules = {
    name: [{ required: true, message: '请输入品牌名称', trigger: 'blur' }],
    primaryAccount: [
      { required: true, message: '请输入主账号', trigger: 'blur' },
    ],
    brandAdmin: [
      {
        required: true,
        message: '请输入品牌管理员账号，需要与主账号不一致',
        trigger: 'blur',
      },
    ],
    brandcode: [
      { required: true, message: '请输入brandcode', trigger: 'blur' },
    ],
    logo: [{ required: true, message: '请输入品牌logo链接', trigger: 'blur' }],
    headlineImage: [
      {
        validator: this.headlineImageValidator,
        message: '请上传品牌头图',
        trigger: 'blur',
      },
    ],
  };

  @Ref()
  readonly ruleForm!: FormModel;

  @lazyInject(BrandService)
  protected brandService!: BrandService;

  @lazyInject(UserService)
  protected userService!: UserService;

  get getId() {
    console.log(this.form.id);
    return this.form.id !== undefined;
  }

  headlineImageValidator() {
    if (this.fileList.length > 0) {
      return true;
    }
    return false;
  }

  // async submit() { }

  reset() {

  }

  async open(val: any) {
    this.fileList = [];
    this.imageListId = [];
    if (val) {
      const data = await this.brandService.brandDetail(val.id);
      const d = JSON.parse(JSON.stringify(Brand.from(data)));
      console.log(d);
      this.form = d;
      this.fileList = d.headerPic;
      interface resD {
        uid: any;
        id: any;
        name: any;
        status: any;
      }
      const oldImages: number[] = [];
      this.fileList.forEach((res: resD) => {
        oldImages.push(res.id);
        res.uid = res.id;
        res.name = 'mage.png';
        res.status = 'done';
        res.uid = res.id;
      });
      this.oldImages = oldImages.join(',');
      this.titleName = '品牌详情';
    }
    setTimeout(() => {
      this.isVisible = true;
    }, 100);
  }

  close() {
    this.isVisible = false;
    this.reset();
  }

  created() {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }
}
