
import { $delay } from '@/common/utils';
import { AgentAccountRes, UserRes } from '@/common/interface/account';
import lazyInject from '@/di';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { EDYHUserStatus, EDYHUserType } from '@/common/enum/account';
import { AccountService } from '@/services/account_service';
import { AgentService } from '@/services/agent.service';
import { AuthEnum } from '@/services/permission.service';

@Component({})
export default class EditUserModal extends Vue {
  @lazyInject(AgentService)
  protected agentService!: AgentService;

  @lazyInject(AccountService)
  protected accountService!: AccountService;

  @Prop({
    default: () => {
      return null;
    },
  })
  user?: UserRes;

  @Prop({
    default: () => {
      return [];
    },
  })
  treeData?: [];

  AuthEnum = AuthEnum;

  loading = false;

  typeFocus = false;

  isVisible = false;

  agentList: AgentAccountRes[] = [];

  form: Omit<UserRes, 'createTime' | 'adStaffAuth'> & {
    aid: number;
    password: string;
    adAccess: boolean;
    dept_id: string;
  } = {
      userId: -1,
      userName: '',
      userType: EDYHUserType.地推员, // 默认设置为地推员
      phone: '',
      customerName: '',
      agentName: '',
      status: EDYHUserStatus.正常,
      aid: -1,
      password: '',
      adAccess: false,
      dept_id: '',
    };

  EDYHUserType = EDYHUserType;

  userTypeList: EDYHUserType[] = [
    EDYHUserType.全部,
    EDYHUserType.管理员,
    EDYHUserType.审核员,
    EDYHUserType.地推员,
    EDYHUserType.店铺老板,
    EDYHUserType.雷达安装工,
  ];

  @Watch('user', { deep: true, immediate: true })
  watchUserChange() {
    if (this.user == null) {
      this.reset();
      return;
    }
    console.log(this.user);
    const {
      userId,
      userName,
      userType,
      phone,
      customerName,
      agentName,
      status,
      adStaffAuth,
      dept_id,
    } = this.user;

    const agentArray = this.agentList.filter(
      (item) => item.agentName === agentName,
    );

    this.form = {
      userId,
      userName,
      userType,
      phone,
      customerName,
      agentName,
      status,
      aid: agentArray.length ? agentArray[0].aid : -1,
      password: '',
      dept_id,
      adAccess: !!(typeof adStaffAuth !== 'undefined' && adStaffAuth === 1),
    };
  }
  handleFocus() {
    console.log('000');
    this.typeFocus = !this.typeFocus;
  }

  validateForm() {
    if (this.form.password) {
      if (this.form.password.length < 6 || this.form.password.length > 20) {
        this.$message.warn('密码长度为6-20字符');
        return false;
      }
      const isNaturalNum = /^(?![a-zA-Z]+$)(?![0-9]+$)(?![!@#$%^&*()_+,.]+$)[a-zA-Z0-9!@#$%^&*()_+,.]{0,200}$/.test(`${this.form.password}`);
      if (!isNaturalNum) {
        this.$message.warn('密码必须包含数字、字母、符号中的任意两种');
        return false;
      }
    }
    if (this.form.aid === -1) {
      this.$message.warn('请选择所属渠道商');
      return false;
    }
    if (!this.form.userName) {
      this.$message.warn('用户名不能为空');
      return false;
    }

    if (!this.form.phone) {
      this.$message.warn('手机号不能为空！');
      return false;
    }

    if (
      EDYHUserType[this.form.userType] === '地推员' &&
      !this.form.customerName
    ) {
      this.$message.warn('请输入经理称呼！');
      return false;
    }

    if (!/1[3-9]\d{9}/.test(this.form.phone)) {
      this.$message.warn('手机号不合法，请检测并重新输入！');
      return false;
    }

    /** @note 创建账号时 密码不能为空 */
    if (this.user == null && !this.form.password) {
      this.$message.warn('新增用户时，密码不能为空！');
      return false;
    }

    return true;
  }

  async submit() {
    if (this.validateForm()) {
      try {
        if (this.loading) {
          return;
        }

        this.loading = true;
        const {
          aid,
          userType,
          userName,
          userId,
          phone,
          customerName,
          password,
          status,
          adAccess,
          dept_id,
        } = this.form;

        let res;
        if (this.user == null) {
          const createParam: {
            aid: number;
            phone: string;
            userName: string;
            customerName: string;
            password: string;
            deptId: string;
          } = {
            aid,
            phone,
            userName,
            customerName,
            password,
            deptId: dept_id,
          };
          res = await this.accountService.create(createParam);
        } else {
          const modifyParam: {
            aid: number;
            phone: string;
            userName: string;
            customerName: string;
            userId: number;
            status: number;
            userType: number;
            password?: string;
            adStaff: number;
            deptId: string;
          } = {
            aid,
            phone,
            userId,
            userName,
            customerName,
            status,
            userType,
            adStaff: adAccess === true ? 1 : 2,
            deptId: dept_id,
          };
          if (password) {
            modifyParam.password = password;
          }
          res = await this.accountService.modify(modifyParam);
        }

        if (res) {
          const msg = this.user == null ? '添加成功' : '修改成功';
          this.$message.success(msg);

          await $delay(500);
          this.$emit('refresh', true);
          this.isVisible = false;
          this.reset();
        }
      } catch (error) {
        this.$message.info(`${error}`);
      } finally {
        this.loading = false;
      }
    }
  }

  reset() {
    this.form = {
      userId: -1,
      userName: '',
      userType: EDYHUserType.地推员,
      phone: '',
      customerName: '',
      agentName: '',
      status: EDYHUserStatus.正常,
      aid: -1,
      password: '',
      dept_id: '',
      adAccess: false,
    };

    if (this.loading) {
      this.loading = false;
    }
  }

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
    this.$emit('close');
  }

  async getAgentList() {
    const res = await this.agentService.all();
    this.agentList = [{ aid: -1, agentName: '请选择所属渠道商' }].concat([
      ...res,
    ]) as AgentAccountRes[];
  }

  mounted() {
    this.getAgentList();
  }

  onSwitchChange(val: boolean) {
    this.$set(this.form, 'adAccess', val);
  }
}
