/*
 * @Author: Src
 * @Date: 2024-11-13 17:01:49
 * @LastEditTime: 2024-11-13 19:28:09
 * @LastEditors: Src
 * @FilePath: /work-station/src/services/system.service.ts
 * @Description: 头部注释配置模板
 */
import HttpClient from './http.service';
import { inject, injectable } from 'inversify';


@injectable()

export default class SystemService {
  @inject(HttpClient)
  protected http!: HttpClient;

  async getConfigList(params: any) {
    try {
      const res = await this.http.get('query/keyValue/list', { params }) as any;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  async addConfig(params: any) {
    try {
      const res = await this.http.post('add/keyValue', params) as any;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  async editConfig(params: any) {
    try {
      const res = await this.http.post('edit/keyValue', params) as any;
      return res;
    } catch (error) {
      return undefined;
    }
  }

  async refreshCache() {
    try {
      const res = await this.http.get('keyValue/refresh/cache') as any;
      return res;
    } catch (error) {
      return undefined;
    }
  }
}
