
import { ShopProfileDetail } from '@/common/interface/shop';
import lazyInject from '@/di';
import { AgentService } from '@/services/agent.service';
import ShopService from '@/services/shop.service';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class DyhStaffChange extends Vue {
  disabled = false;

  confirmLoading = false;

  isVisible = false;

  form: {
    userId: number;
    shopId: number;
    previousStaffName: string;
    shopName: string;
  } = {
    userId: -1,
    shopId: -1,
    previousStaffName: '',
    shopName: '',
  };

  staff: Array<{
    id: number;
    name: string;
    phone: string;
  }> = [];

  filteredStaff: Array<{
    id: number;
    name: string;
  }> = [];

  @Prop({})
  shopProfile!: ShopProfileDetail;

  @Watch('shopProfile', { deep: true, immediate: true })
  watchOrder() {
    if (this.shopProfile != null) {
      this.$set(this.form, 'previousStaffName', this.shopProfile.dyhStaffName);
      this.$set(this.form, 'shopName', this.shopProfile.shopName);
      this.$set(this.form, 'shopId', this.shopProfile.shopId);
    }
  }

  @lazyInject(AgentService)
  protected agentService!: AgentService;

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  open() {
    this.isVisible = true;
  }

  close() {
    this.isVisible = false;
    this.reset();
    this.watchOrder();
  }

  reset() {
    this.form = {
      userId: -1,
      shopId: -1,
      previousStaffName: '',
      shopName: '',
    };
  }

  async getStaff() {
    const res = await this.agentService.staff();

    if (res && res.length > 0) {
      this.staff = res.map((item) => {
        return item.staffs;
      }).filter((staffs) => {
        return staffs.length;
      }).flat();
    }
  }

  onChange(nameAndPhone: string) {
    const phone = nameAndPhone.split('|')[1];

    const person = this.staff.filter((item) => {
      return item.phone === phone;
    })[0];

    this.$set(this.form, 'userId', person.id);

    if (this.disabled) {
      this.disabled = false;
    }
  }

  async handleOk() {
    if (!this.form.shopId || this.form.shopId === -1) {
      return;
    }

    if (this.form.userId === -1 || !this.form.userId) {
      this.$message.warn('请选择地推人员。');
      return;
    }

    this.disabled = true;
    const res = await this.shopService.dyhStaffChange(
      this.form.shopId,
      this.form.userId,
    );

    if (!res) {
      return;
    }

    this.$message.success(res);
    this.disabled = false;
    this.$emit('refresh');
    this.close();
  }

  async mounted() {
    await this.getStaff();
  }
}
