
import { Vue, Component, Ref } from 'vue-property-decorator';
import { handleTree } from '@/common/utils';
import DepartmentModal from './components/DepartmentModal.vue';
import lazyInject from '@/di';
import { AccountService } from '@/services/account_service';
import { ActivitySignup } from '@/models/activity.model';
// sysDeptDelete

@Component({
  components: {
    'department-modal': DepartmentModal,
  },
})
export default class DepartmentList extends Vue {
  @lazyInject(AccountService)
  protected accountService!: AccountService;

  refresh() {
    this.sysDeptList({});
  }

  @Ref('departmentModal')
  departmentModal!: DepartmentModal;

  oepnEditUserModal(type: string) {
    console.log('oepnEditUserModal');
    this.departmentModal.init(null, type);
  }

  treeData: any[] = [];

  treeDataOld: any[] = [];

  treeDataArry: any[] = [];

  type = true;

  value: any = null;

  filterForm: any = {
    deptName: '',
    leader: '',
  };

  columns: any[] = [
    {
      title: '部门名称',
      dataIndex: 'deptName',
      key: 'deptName',
    },
    {
      title: '部门负责人',
      dataIndex: 'leader',
      key: 'leader',
      align: 'center',
      scopedSlots: { customRender: 'leader' },
    },
    {
      title: '联系电话',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center',
    },
    {
      title: '邮箱',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      scopedSlots: { customRender: 'status' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ];

  handleFilter() {
    const { deptName, status } = this.filterForm;
    const data = {
      dept_name: deptName,
      status,
    };
    this.sysDeptList(data);
  }

  resetFilter() {
    this.filterForm = {
      deptName: '',
      leader: '',
    };
    this.type = true;
    this.sysDeptList({});
  }

  toggleExpandAll() {
    this.treeData = [];
    this.$nextTick(() => {
      this.treeData = this.treeDataOld;
      this.type = !this.type;
    });
  }

  async confirm(record: any) {
    const res = await this.accountService.sysDeptDelete(record.id);
    if (res) {
      this.$message.success('删除成功');
      this.refresh();
    }
  }
  onExpand(expanded: any, record: any) {
    console.log(expanded, record);
  }


  openEditUserModal(record: any, type: string) {
    this.departmentModal.init(record, type);
  }

  async sysDeptList(params: any) {
    this.treeData = [];
    try {
      const res = await this.accountService.sysDeptList(params) as any;
      if (res) {
        const activitySignup = res.map((item: any) => ActivitySignup.from(item)) as any;
        const Arr = JSON.parse(JSON.stringify(activitySignup));
        const tree = handleTree(Arr, 'id', 'parentId', 'children');
        this.type = true;
        this.treeDataOld = tree;
        this.treeData = tree;
        this.treeDataArry = activitySignup;
      }
    } catch (error) {
      console.log(error);
    }
  }

  mounted() {
    this.sysDeptList({});
  }
}
