/*
 * @Author: Src
 * @Date: 2024-12-20 15:05:22
 * @LastEditTime: 2024-12-26 11:03:32
 * @LastEditors: Src
 * @FilePath: /work-station/src/models/operation.model.ts
 * @Description: 头部注释配置模板
 */
import { OperationShop, IOperationShop, OBatchManagement, IOBatchManagement } from '@/common/interface/operation';

export class CShop implements IOperationShop {
  id = ''; // 记录ID
  kaName = ''; // 品牌
  city = ''; // 城市
  shopName = ''; // 门店名称
  poi = ''; // 高德poi
  bossPhone = ''; // 老板电话
  showPrice = ''; // 刊例价
  entranceFee = ''; // 实际入驻金额
  reviewStatus = 0; // 审核状态：0 待审核 1 审核通过 2 待品牌代运营审批 3 品牌代运营审批通过
  agentEdit = ''; // 代运营是否修改刊例价/入驻金额 0-未修改，1-修改刊例价，2-修改入驻金额，3-赠送天数，例如：1,2 或 1,2,3 等
  batchNo = ''; // 入驻批次号
  kaId = 0; // 品牌ID
  entered = false; // 注册过黑卡门店 true|false
  encryptBossPhone = ''; // 加密老板姓名
  operatorName = ''; // 提交人
  static from(data: OperationShop) {
    const shop = new CShop();
    shop.id = data.id;
    shop.kaName = data.ka_name;
    shop.city = data.city;
    shop.shopName = data.shop_name;
    shop.poi = data.poi;
    shop.bossPhone = data.boss_phone;
    shop.showPrice = data.show_price;
    shop.entranceFee = data.entrance_fee;
    shop.reviewStatus = data.review_status;
    shop.agentEdit = data.agent_edit;
    shop.batchNo = data.batch_no;
    shop.kaId = data.ka_id;
    shop.entered = data.entered;
    shop.encryptBossPhone = data.encrypt_boss_phone;
    shop.operatorName = data.operator_name;
    return shop;
  }
}


export class CBatchManagement implements IOBatchManagement {
  id = 0;
  batchNo = '';
  kaName = '';
  kaId = 0;
  batchName = '';
  shopNum = 0;
  auditStatus = 0;
  createdAt = '';
  auditTs = '';
  submitTs = '';
  createUserName = '';
  auditUserName = '';
  auditTime = '';
  static from(data: OBatchManagement) {
    const batch = new CBatchManagement();
    batch.id = data.id;
    batch.batchNo = data.batch_no;
    batch.kaName = data.ka_name;
    batch.kaId = data.ka_id;
    batch.batchName = data.batch_name;
    batch.shopNum = data.shop_num;
    batch.auditStatus = data.audit_status;
    batch.createdAt = data.created_at;
    batch.auditTs = data.audit_ts || '';
    batch.submitTs = data.submit_ts || '';
    batch.createUserName = data.create_user_name;
    batch.auditUserName = data.audit_user_name;
    batch.auditTime = data.audit_time || '';
    return batch;
  }
}
