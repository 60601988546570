
import { Vue, Component, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import LogModal from './components/logModal.vue';

@Component({
  components: {
    'log-modal': LogModal,
  },
})
export default class LogList extends Vue {
  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @Ref()
  readonly logModal!: LogModal;

  type = '2';

  columns = [
    {
      title: '操作人',
      dataIndex: 'operator_name',
      align: 'center',
    },
    {
      title: '操作时间',
      dataIndex: 'created_at',
      align: 'center',
    },
    {
      title: '操作类型',
      dataIndex: 'category',
      align: 'center',
      scopedSlots: { customRender: 'category' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      scopedSlots: { customRender: 'action' },
      align: 'center',
    },
  ];
  data = [];

  pagination = {
    pageSize: 10,
    current: 1,
    total: 0,
    onChange: this.handlePageChange,
  };

  async getList(data: any) {
    const res = await this.spuService.getShopDetailItemLogList(data);
    if (res) {
      this.data = res.list;
      this.pagination.total = res.total;
    }
  }

  handleView(record: any) {
    this.logModal.open(record);
  }

  handlePageChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const data = {
      item_id: this.$route.params.id,
      type: this.$route.params.type,
      page,
      pageSize,
    };
    this.getList(data);
  }
  refresh() {
    this.getList({
      item_id: this.$route.params.id,
      type: this.$route.params.type,
      page: 1,
      pageSize: 10,
    });
  }

  mounted() {
    const { type } = this.$route.params;
    this.type = type;
    const data = {
      item_id: this.$route.params.id,
      type: this.$route.params.type,
      page: 1,
      pageSize: 10,
    };
    this.getList(data);
  }
}
