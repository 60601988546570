
import { Vue, Component, Ref } from 'vue-property-decorator';
import { ISPUCategory } from '@/common/interface/spu';
import SPUService from '@/services/spu.service';
import lazyInject from '@/di';
import SpuDefaultServicesModal from './components/SpuDefaultServicesModal.vue';
import { AuthEnum } from '@/services/permission.service';

@Component({
  components: {
    'spu-default-services-modal': SpuDefaultServicesModal,
  },
})
export default class SpuDefaultServices extends Vue {
  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @Ref('spu_default_services_modal')
  spuDefaultServicesModal!: SpuDefaultServicesModal;

  AuthEnum = AuthEnum;

  form = {
    fcate: '',
    cate: '',
    spuName: '',
    status: '',
  };

  columns: any[] = [
    {
      title: '类目',
      dataIndex: 'name',
      // align: 'center',
      width: 200,
    },
    {
      title: '服务详情默认内容',
      dataIndex: 'default_item',
      align: 'center',
      scopedSlots: { customRender: 'default_item' },
    },
    // {
    //   title: '添加时间',
    //   dataIndex: 'createTime',
    //   align: 'center',
    //   width: 280,
    // },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
      width: 300,
    },
  ];

  dataSource: any[] = [];

  allSpuCategory: ISPUCategory[] = [];

  secondCategoryList: ISPUCategory[] = [];

  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      loadingEl: this.$el,
    });

    this.allSpuCategory = data;

    this.secondCategoryList = data.reduce((prev, cur) => {
      return prev.concat(cur.children || []);
    }, [] as ISPUCategory[]);
  }

  onChangeFcate(val: number) {
    this.$set(this.form, 'cate', '');
    if (val) {
      this.$set(this.form, 'fcate', val);
      this.filterBySpuCategory();
    }
  }

  filterBySpuCategory() {
    if (Number(this.form.fcate) < 0 || !this.form.fcate) {
      return this.secondCategoryList;
    } else {
      for (const idx of this.allSpuCategory) {
        if (Number(this.form.fcate) === idx.id) {
          return idx.children;
        }
      }
    }
  }

  onChangeCate(val: number) {
    if (!val) {
      return;
    }

    const allSpuCategory = [...this.allSpuCategory];
    const extractFcate = (all: ISPUCategory[]) => {
      let fcateId;
      for (const item of all) {
        for (const child of item.children!) {
          if (val === child.id) {
            fcateId = item.id;
            break;
          }
        }
      }
      return fcateId;
    };

    this.$set(this.form, 'fcate', extractFcate(allSpuCategory));
    this.filterBySpuCategory();
    this.$set(this.form, 'cate', val);
  }

  search() { }

  copyProperty() {
    this.spuDefaultServicesModal.open('add');
  }

  addProperty() { }

  async editProperty(record: any) {
    const res = await this.spuService.getcategoryIdDetail(record.id);
    if (res) {
      this.spuDefaultServicesModal.open('edit', res);
    }
  }

  async lookProperty(record: any) {
    const res = await this.spuService.getcategoryIdDetail(record.id);
    if (res) {
      this.spuDefaultServicesModal.open('look', res);
    }
  }

  async deleteProperty(record: any) {
    const res = await this.spuService.deleteSpuDefaultItem(record.id);
    if (res) {
      this.$message.success('删除成功');
      this.getList();
    }
  }
  async getList() {
    const res = await this.spuService.getShopDetailItemListBySpuId({
      aggregation: true,
    });
    this.dataSource = res;
    console.log(res);
  }
  refresh() {
    console.log('refresh');
  }

  mounted() {
    this.getCategory();
    this.getList();
  }
}
