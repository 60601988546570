
import moment from 'moment';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import WithdrawService from '@/services/withdraw.service';
import lazyInject from '@/di';
import { WithdrawExamineStatus } from '@/common/enum/withdraw';
import RejectModal from './common/RejectModal.vue';
import { CApplyExamineList } from '@/models/apply.model';
import { IApplyExamineList } from '@/common/interface/apply';
import { AuthEnum } from '@/services/permission.service';
import UTILService from '@/services/utils.service';


@Component({
  components: {
    'reject-modal': RejectModal,
  },
})
export default class ApplyExamine extends Vue {
  selectedRowKeys: any = []; // Check here to configure the default column

  @lazyInject(UTILService)
  utilService!: UTILService;

  @lazyInject(WithdrawService)
  withdrawService!: WithdrawService;

  @Ref('rejectModal')
  rejectModal?: RejectModal;

  AuthEnum = AuthEnum;

  encryptAccount = '';

  WithdrawExamineStatus = WithdrawExamineStatus;

  statusList: WithdrawExamineStatus[] = [
    WithdrawExamineStatus.待审核,
    WithdrawExamineStatus.待转账,
    WithdrawExamineStatus.审核未通过,
    WithdrawExamineStatus.转账失败,
    WithdrawExamineStatus.提现成功,
  ];

  filterForm: {
    withdrawal_no: string;
    status: any;
    ctime_start: string;
    ctime_end: string;
  } = {
      withdrawal_no: '',
      status: undefined,
      ctime_start: '',
      ctime_end: '',
    };

  verifyts_data: any[] = [];

  columns = [
    {
      title: '提现单号',
      dataIndex: 'withdrawalNo',
      align: 'center',
    },
    {
      title: '提现状态',
      dataIndex: 'statusStr',
      align: 'center',
    },
    {
      title: '提现账号',
      dataIndex: 'accountNo',
      align: 'center',
      scopedSlots: { customRender: 'accountNo' },
    },
    {
      title: '申请时间',
      dataIndex: 'ctime',
      align: 'center',
    },
    {
      title: '审核时间',
      dataIndex: 'verifyTime',
      align: 'center',
    },
    {
      title: '转账时间',
      dataIndex: 'transferTime',
      align: 'center',
    },
    {
      title: '支付宝转账订单号',
      dataIndex: 'payNo',
      align: 'center',
    },
    {
      title: '支付宝转账流水号',
      dataIndex: 'flowNo',
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
    showTotal: (total: any) => `共 ${total} 条`,
  };

  data: any[] = [];

  loading = true;

  @Watch('$route')
  routeChange() {
    const { page, pageSize, withdrawal_no, status, ctime_start, ctime_end } = this.$route.query;
    const params: any = {
      page: page ? Number(page) : 1,
      pageSize: pageSize ? Number(pageSize) : 10,
    };
    this.pagination.current = page ? Number(page) : 1;
    if (withdrawal_no) {
      this.filterForm.withdrawal_no = withdrawal_no as string;
      params.withdrawal_no = withdrawal_no;
    }
    if (status || status === '0') {
      this.filterForm.status = Number(status);
      params.status = status;
    }
    if (ctime_start && ctime_end) {
      params.ctime_start = ctime_start;
      params.ctime_end = ctime_end;
      this.filterForm.ctime_start = ctime_start as string;
      this.filterForm.ctime_end = ctime_end as string;
      this.verifyts_data = [moment(ctime_start as string), moment(ctime_end as string)];
    }
    this.pagination.current = +page;
    this.getOrderList(params);
  }


  async LookShopDetail(title: string, encryptAccount: string) {
    const data = {
      encrypt_phone: encryptAccount,
      operate_type: title,
    };
    console.log(data);
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.encryptAccount = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }
  paginationChange(page: number) {
    this.pagination.current = page;
    const { withdrawal_no, status, ctime_start, ctime_end } = this.filterForm;
    const query: any = {
      page,
      pageSize: this.pagination.pageSize,
    };
    if (withdrawal_no) {
      query.withdrawal_no = withdrawal_no;
    }
    if (status || status == 0) {
      query.status = status;
    }
    if (ctime_start && ctime_end) {
      query.ctime_start = ctime_start;
      query.ctime_end = ctime_end;
    }
    this.$router.push({
      query,
    });
  }

  handleRowChange() { }

  reject(id: string) {
    this.rejectModal?.open(id);
  }

  search() {
    const { ctime_start, ctime_end, status, withdrawal_no } = this.filterForm;
    const query: any = {
      page: 1,
      pageSize: this.pagination.pageSize,
    };
    if (withdrawal_no) {
      query.withdrawal_no = withdrawal_no;
    }
    if (status || status == 0) {
      query.status = status as string;
    }
    if (ctime_start && ctime_end) {
      query.ctime_start = ctime_start;
      query.ctime_end = ctime_end;
    }
    this.pagination.current = 1;
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  resetQuery() {
    this.filterForm = {
      withdrawal_no: '',
      status: undefined,
      ctime_start: '',
      ctime_end: '',
    };
    this.verifyts_data = [];
    const query: any = {
      page: 1,
      pageSize: this.pagination.pageSize,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  go(routeName: string, id: string, no: string): void {
    this.$router.push({
      name: routeName,
      params: {
        id,
        no,
      },
    });
  }

  onChange(dates: any) {
    this.verifyts_data = dates;
    this.filterForm.ctime_start = dates[0]?.format('YYYY-MM-DD');
    this.filterForm.ctime_end = dates[1]?.format('YYYY-MM-DD');
  }

  refresh() { }

  async getOrderList(params: any) {
    this.loading = true;
    if (params.ctime_start && params.ctime_end) {
      params.ctime_start = `${params.ctime_start} 00:00:00`;
      params.ctime_end = `${params.ctime_end} 23:59:59`;
    }
    const res = await this.withdrawService.withdrawalOrderList(params);
    if (res) {
      this.loading = false;
      const data = res.list.map((item: IApplyExamineList) => CApplyExamineList.from(item));
      this.data = data;
      this.pagination.total = res.total;
    }
  }

  mounted() {
    // const page = this.pagination.current;
    // const params = {
    //   page,
    //   pageSize: this.pagination.pageSize,
    // };
    // this.getOrderList(params);
    this.routeChange();
  }
}
