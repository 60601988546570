
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import { Route } from 'vue-router';
import lazyInject from '@/di';
import OperationService from '@/services/operation.service';
import { CShop } from '@/models/operation.model';
import LookShopDetail from './components/LookShopDetail.vue';
import { City } from '@/common/interface';
import BaseService from '@/services/base.service';
import UTILService from '@/services/utils.service';
import {
  AuthEnum,
} from '@/services/permission.service';

@Component({
  components: {
    'look-shop-detail': LookShopDetail,
  },
})
export default class BatchShopList extends Vue {
  AuthEnum = AuthEnum;
  @lazyInject(OperationService)
  private operationService!: OperationService;

  @lazyInject(BaseService)
  private baseService!: BaseService;
  @lazyInject(UTILService)
  private utilService!: UTILService;


  @Ref('lookShopDetail')
  lookShopDetail!: LookShopDetail;

  citys: any[] = [];


  form: any = {
    batchName: '',
    city: undefined,
    poi: '',
    shopName: '',
    bossPhone: '',
    auditStatus: '',
  };

  batchNo: any = '';

  columns = [
    {
      title: '批次号',
      dataIndex: 'batchNo',
      key: 'batchNo',
      align: 'center',

    },
    {
      title: '品牌',
      dataIndex: 'kaName',
      key: 'kaName',
      align: 'center',
    },
    {
      title: '城市',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
    },
    {
      title: '门店名称',
      dataIndex: 'shopName',
      key: 'shopName',
      align: 'center',
    },
    {
      title: 'poi',
      dataIndex: 'poi',
      key: 'poi',
      align: 'center',
    },
    {
      title: '老板手机号',
      dataIndex: 'bossPhone',
      key: 'bossPhone',
      align: 'center',
      scopedSlots: { customRender: 'bossPhone' },
    },
    {
      title: '注册过黑卡门店',
      dataIndex: 'entered',
      key: 'registerShop',
      align: 'center',
      scopedSlots: { customRender: 'entered' },
    },
    {
      title: '刊例价',
      dataIndex: 'showPrice',
      key: 'price',
      align: 'center',
    },
    {
      title: '入驻金额',
      dataIndex: 'entranceFee',
      key: 'entranceFee',
      align: 'center',
    },
    {
      title: '审核状态',
      dataIndex: 'reviewStatus',
      key: 'reviewStatus',
      align: 'center',
      scopedSlots: { customRender: 'reviewStatus' },
    },
    {
      title: '操作',
      key: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ];
  data = [];
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    onChange: this.onChangePage,
  };

  encryptAccount = '';

  @Watch('$route', { immediate: true, deep: true })
  onRouteChange(to: Route, from: Route) {
    console.log('路由已变化', to, from);
  }

  @Watch('$route')
  handleRouteChange() {
    const { current, pageSize, city, poi, shopName, bossPhone } = this.$route.query;
    const params: any = {
      page: current || 1,
      pageSize: pageSize || 10,
    };
    if (city) {
      params.city_id = city;
      this.form.city = Number(city);
    }
    if (poi) {
      params.poi = poi;
      this.form.poi = poi;
    }
    if (shopName) {
      params.shopName = shopName;
      this.form.shopName = shopName;
    }
    if (bossPhone) {
      params.bossPhone = bossPhone;
      this.form.bossPhone = bossPhone;
    }
    this.getShopList(params);
  }
  search(type = false) {
    const { auditStatus, city, poi, shopName, bossPhone } = this.form;
    if (!type) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
    }
    const params: any = {
      page: this.pagination.current,
      pageSize: this.pagination.pageSize,
    };
    if (auditStatus) {
      params.auditStatus = auditStatus;
    }
    if (city) {
      params.city = city;
    }
    if (poi) {
      params.poi = poi;
    }
    if (shopName) {
      params.shopName = shopName;
    }
    if (bossPhone) {
      params.bossPhone = bossPhone;
    }
    params.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query: params,
    });
  }

  async LookShopDetail(title: string, value: string, poi: string) {
    const data = {
      encrypt_phone: value,
      operate_type: title,
      poi,
    };
    console.log(data);
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.encryptAccount = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }

  onChangePage(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    this.search(true);
  }


  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.baseService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  handleView(record: any) {
    console.log(record);
    this.lookShopDetail.init(record);
  }

  reset() {
    this.form = {
      city: undefined,
      poi: '',
      shopName: '',
      bossPhone: '',
      auditStatus: '',
    };
    this.search(false);
  }

  mounted() {
    this.batchNo = this.$route.params.batchNo;
    this.handleRouteChange();
    this.getCityList();
  }

  async getShopList(params: any) {
    const res = await this.operationService.getBatchShops(this.batchNo, params);
    this.data = res.list.map((item: any) => CShop.from(item));
    this.pagination.total = res.total;
  }

  /** * 返回 */
  back() {
    this.$router.push({
      name: 'operation.batchManagement',
    });
  }
}
