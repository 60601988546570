/*
 * @Author: Src
 * @Date: 2024-07-16 14:41:02
 * @LastEditTime: 2024-12-22 19:09:58
 * @LastEditors: Src
 * @FilePath: /work-station/src/router/auditRoutes.ts
 * @Description: 头部注释配置模板
 */
import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import AuditAll from '@/views/audit/AuditAll.vue';
import AuditList from '@/views/audit/AuditList.vue';
import AuditDetail from '@/views/audit/AuditDetail.vue';
import TaskDetail from '@/views/audit/TaskDetail.vue';
import { AuthEnum } from '@/services/permission.service';
import AuditComposite from '@/views/audit/AuditComposite.vue';
import AuditShopList from '@/views/audit/AuditShopList.vue';

const auditRoutes: RouteConfig = {
  name: 'audit',
  path: '/audit',
  component: Main,
  redirect: {
    name: 'audit.all',
  },
  meta: {
    title: '审核管理',
  },
  children: [
    {
      name: 'audit.list',
      path: '/audit/list',
      component: AuditList,
      props: {
        auth: [AuthEnum.审核管理],
      },
      meta: {
        title: '待审核列表',
      },
    },
    {
      name: 'audit.all',
      path: '/audit/all',
      component: AuditAll,
      props: {
        auth: [AuthEnum.审核管理],
      },
      meta: {
        title: '全部审核',
      },
    },
    {
      name: 'audit.detail',
      path: '/audit/detail/:id',
      component: AuditDetail,
      props: {
        auth: [AuthEnum.审核管理],
      },
      meta: { title: '审核详情' },
    },
    {
      name: 'audit.task',
      path: '/audit/task/:id',
      component: TaskDetail,
      props: {
        auth: [AuthEnum.审核管理],
      },
      meta: { title: '审核任务' },
    },
    {
      name: 'operation.auditComposite',
      path: '/operation/auditComposite',
      component: AuditComposite,
      meta: {
        title: '品牌门店复合管理',
      },
    },
    {
      name: 'audit.shopList',
      path: '/audit/shopList/:batchNo/:status',
      component: AuditShopList,
      meta: {
        title: '批次门店列表',
      },
    },
  ],
};

export default auditRoutes;
