
import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import { v4 } from 'uuid';
import ActivityService from '@/services/activity.service';
import { SpuSignupableActivity } from '@/common/interface/activity';
import SPUSignup from './SPUSignup.vue';
import { KeyObject } from '@/common/interface';
import PermissionService, { AuthEnum } from '@/services/permission.service';
import SPUPreview from '@/components/spu/SPUPreview.vue';
import {
  IResSPU,
  ISPUCategory,
  ISPUField,
  ISPUProduct,
} from '@/common/interface/spu';
import { SpuNonBusinessHoursEnum } from '../common/SPUForm';
import SPUAutoOffShelfDrawer from './SPUAutoOffShelfDrawer.vue';
import SPUService from '@/services/spu.service';
import SPUEditor from '@/components/spu/SPUEditor.vue';
import { ESPUField } from '@/common/enum/spu';
import { LeadingSPU, SPU } from '@/models/spu.model';
import { SPUFieldFactory } from '@/models/spuFieldFactory';
import _ from 'lodash';

@Component({
  components: {
    SPUSignup,
    SPUPreview,
    SPUAutoOffShelfDrawer,
    SPUEditor,
  },
})
export default class SPUTable extends Vue {
  @lazyInject(ActivityService)
  protected activityService!: ActivityService;

  @lazyInject(PermissionService)
  protected permissionService!: PermissionService;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @Ref()
  spuPreview!: SPUPreview;

  @Ref()
  spuAutoOffShelfDrawer!: SPUAutoOffShelfDrawer;

  @Prop()
  shopId!: string;

  @Prop({
    default: 'small',
  })
  size?: string;

  @Prop({
    default: false,
  })
  needRefresh?: boolean;

  /** 批量商品管理权限 */
  leadingSpuPermmison: KeyObject<boolean> = {};

  /**
   *
   * 分类key
   */
  classifyKey = -1;

  uuid = v4;

  columns = [
    {
      title: '序号',
      dataIndex: 'rowIndex',
      scopedSlots: { customRender: 'rowIndex' },
      key: 'rowIndex',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      ellipsis: true,
    },
    {
      title: '商品图片',
      dataIndex: 'headlineImage',
      key: 'headlineImage',
      ellipsis: true,
      scopedSlots: { customRender: 'headlineImage' },
    },
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      ellipsis: false,
      scopedSlots: { customRender: 'name' },
      width: 240,
    },
    {
      title: '所属分类',
      dataIndex: 'category',
      key: 'category',
      ellipsis: true,
      scopedSlots: { customRender: 'category' },
    },
    {
      title: '服务次数',
      dataIndex: 'effectiveTimes',
      key: 'effectiveTimes',
      ellipsis: true,
    },
    {
      title: '是否热门',
      dataIndex: 'isHot',
      key: 'isHot',
      ellipsis: true,
      scopedSlots: { customRender: 'isHot' },
    },
    {
      title: '市场价',
      dataIndex: 'originalPriceCent',
      key: 'originalPriceCent',
      ellipsis: true,
      scopedSlots: { customRender: 'originalPriceCent' },
    },
    {
      title: '黑卡价',
      dataIndex: 'vipPriceCent',
      key: 'vipPriceCent',
      ellipsis: true,
      scopedSlots: { customRender: 'vipPriceCent' },
    },
    {
      title: '新用户特价',
      dataIndex: 'newUserPriceCent',
      key: 'newUserPriceCent',
      ellipsis: true,
      scopedSlots: { customRender: 'newUserPriceCent' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      ellipsis: true,
      scopedSlots: { customRender: 'action' },
      width: 180,
    },
  ];

  spuDataSource: IResSPU[] = [];

  currentSPUData: IResSPU[] = [];

  /**
   * 分类数据
   */
  classifyData: ISPUCategory[] = [];

  /**
   * 当前选中的分类id
   */
  selectedCategoryId = -1;

  /**
   *
   *
   * 当前二级分类
   */
  get secondCategoryList() {
    if (this.classifyKey === -1) {
      return this.classifyData.reduce((prev, cur) => {
        return prev.concat(cur.children || []);
      }, [] as ISPUCategory[]);
    }
    const firstCategory = this.classifyData.find(
      (item) => item.id === this.classifyKey,
    ) || { children: [] };
    return firstCategory.children;
  }

  isLoading = false;

  SpuNonBusinessHoursEnum = SpuNonBusinessHoursEnum;

  async created() {
    const $asyncP = await this.permissionService.asyncPermissions([
      AuthEnum.批量商品管理,
    ]);
    $asyncP.subscribe((permissions: KeyObject<boolean>) => {
      if (permissions) {
        this.leadingSpuPermmison = permissions;
      } else {
        this.leadingSpuPermmison = {};
      }
    });

    await this.getCategory();
    await this.getShopSpus();
  }

  /** 获取门店商品 */
  async getShopSpus() {
    const data = await this.spuService.shopSpus(this.shopId, this.$el);
    this.spuDataSource = data
      .filter((brief) => {
        if (brief.platFlag !== 3) {
          return brief;
        } else if (brief.isOnline === true) {
          return brief;
        } else {
          return null;
        }
      })
      .filter((item) => {
        return item !== null;
      });
    this.currentSPUData = [...this.spuDataSource];
  }

  tableRowClassName(record: any) {
      if (!record.isOnline) {
      return 'blue';
      }
    }

  /**
   * 获取商品、服务分类
   */
  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      loadingEl: this.$el,
    });
    this.classifyData = data;
  }

  /**
   * 选择分类
   */
  catrgorySelect(id: number) {
    this.selectedCategoryId = id;
  }

  @Watch('classifyKey')
  onClassifyKeyChange() {
    this.selectedCategoryId = -1;
    const cateIdList = (this.secondCategoryList || []).map((item) => item.id);
    this.currentSPUData = this.spuDataSource.filter(
      (item) => cateIdList.indexOf(item.category.id) > -1,
    );
  }

  @Watch('selectedCategoryId')
  onSelectedCategoryIdChange() {
    if (this.selectedCategoryId === -1) {
      const cateIdList = (this.secondCategoryList || []).map((item) => item.id);
      this.currentSPUData = this.spuDataSource.filter(
        (item) => cateIdList.indexOf(item.category.id) > -1,
      );
    } else {
      this.currentSPUData = this.spuDataSource.filter(
        (item) => item.category.id === this.selectedCategoryId,
      );
    }
  }

  @Watch('needRefresh')
  async refresh() {
    if (!this.needRefresh) {
      return;
    }

    await this.getShopSpus();
  }

  /** 确定删除 */
  async confirmDel(id: number) {
    const ret = await this.spuService.spuDelete(this.shopId, id, this.$el);
    if (ret) {
      this.$message.success('删除成功!');
      const index = this.currentSPUData.findIndex((item) => item.id === id);
      if (index !== -1) {
        this.currentSPUData.splice(index, 1);
      }
    }
  }

  /** 上架 下架 */
  async changeOnlineStatus(id: number, isOnline: boolean) {
    const ret = await this.spuService.spuOnlineStatusChange(
      this.shopId,
      id,
      !isOnline,
      this.$el,
    );
    if (ret) {
      this.$message.success(ret);
      const brief = this.currentSPUData.find((item) => item.id === id);
      if (brief) {
        brief.isOnline = !brief.isOnline;
      }
    }
  }

  spuSignupConfig: {
    spuid: string;
    shopid: string;
    signupableActivityList: SpuSignupableActivity[];
  } = {
    spuid: '',
    shopid: this.shopId,
    signupableActivityList: [],
  };

  spuSignupModalVisible = false;

  closeSpuSignupModal() {
    this.spuSignupModalVisible = false;
    this.spuSignupConfig = {
      spuid: '',
      shopid: this.shopId,
      signupableActivityList: [],
    };
  }

  /** 获取商品可报名活动列表 */
  async getSpuSignupableActivity(spuId: string) {
    try {
      this.$set(this.spuSignupConfig, 'spuid', spuId);
      this.$set(this.spuSignupConfig, 'shopid', this.shopId);

      const res = await this.activityService.getSpuSignupableActivityList(
        spuId,
      );
      if (res) {
        this.$set(this.spuSignupConfig, 'signupableActivityList', res);
      }
    } catch (error) {
      this.$message.error(`${error}`, 3000);
    }
    this.spuSignupModalVisible = true;
  }

  async updateSpuSignupConfig(spuid: string) {
    await this.getSpuSignupableActivity(spuid);
  }

  async openAutoOffShelfModal(spuId: string | number) {
    const res = await this.spuService.getAutoOffShelfTime(spuId);
    this.spuAutoOffShelfDrawer.open(
      res || '',
      Number(this.shopId),
      Number(spuId),
    );
  }

  /**  查看批量商品 */
  async previewSpu(id: number) {
    const data = await this.spuService.spuDetail(
      `${this.shopId}`,
      id,
      this.$el,
    );
    if (data) {
      this.editingSpu = SPU.from(data);
      const indicativePrice = await this.queryIndicativePrice({
        shopId: Number(this.shopId),
        categoryId: this.editingSpu.category.id,
      });
      if (indicativePrice) {
        this.editingSpu.indicativePrice = indicativePrice;
      }
      this.initSpuForm(this.editingSpu, true);
    }
  }

  /** 查询类目参考价：批量商品取全国中位价，无需shopId */
  async queryIndicativePrice(param: { categoryId: number; shopId: number }) {
    return this.spuService.indicativePrice(param);
  }

  onClosePreiew() {
    this.editingSpu = new SPU();
  }

  /** 批量修改  */
  editLeadingSpu(id: number) {
    if (
      this.leadingSpuPermmison &&
      this.leadingSpuPermmison[AuthEnum.批量商品管理]
    ) {
      const routeUrl = this.$router.resolve({
        name: 'spu.leading',
        query: {
          spuId: `${id}`,
        },
      });
      window.open(routeUrl.href, '_blank');

      return;
    }
    this.$message.error('没有批量商品管理权限');
  }

  /** SPUEdtor2 */
  @Ref()
  spuEditor!: SPUEditor;

  editingSpu: SPU = new SPU();

  spuForm: { [key: string]: any } = {};

  spuConfig: ISPUField[] = [];

  initSpuFields: ISPUField[] = SPUFieldFactory.init({
    spuType: 'spu',
    vipPriceValidator: this.vipPriceValidator,
    originalPriceValidator: this.originalPriceValidator,
    newUserPriceValidator: this.newUserPriceValidator,
  });

  /** 新增商品 */
  add() {
    this.editingSpu = new SPU();
    this.initSpuForm(this.editingSpu);
  }

  /** 修改商品 */
  async edit(id: number) {
    const data = await this.spuService.spuDetail(this.shopId, id, this.$el);
    if (data) {
      this.editingSpu = SPU.from(data);
      this.initSpuForm(this.editingSpu);
    }
  }

  async initSpuForm(editingSpu: SPU | LeadingSPU, previewLeadingSpu?: boolean) {
    const form: { [key: string]: any } = {};
    const times =
      editingSpu.effectiveTimes > 0 ? editingSpu.effectiveTimes * 100 : 100;

    for (const [key, value] of Object.entries(editingSpu)) {
      switch (key) {
        case ESPUField.EFFECTIVE_TIMES: {
          if (Number(value) > 1) {
            form.isCombo = true;
          } else {
            form.isCombo = false;
          }
          form[key] = value;
          break;
        }
        case ESPUField.ORIGINAL_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.VIP_PRICE_CENT: {
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }
        case ESPUField.NEW_USER_PRICE_CENT: {
          form.hasNewUserPrice = value > 0;
          form[key] = value !== 0 ? (value / times).toFixed(2) : '';
          break;
        }

        default: {
          form[key] = value;
          break;
        }
      }
    }

    form.comboTotalPrice = {
      originalPriceCent: editingSpu.originalPriceCent,
      vipPriceCent: editingSpu.vipPriceCent,
      newUserPriceCent: editingSpu.newUserPriceCent,
    };
    if (previewLeadingSpu === true) {
      this.spuForm = { ...form };
      this.spuPreview.setup(
        await this.initSpuConfig({ previewLeadingSpu: true }),
        this.spuForm,
      );
    } else {
      this.handleUpdate(form, true);
    }
  }

  async initSpuConfig(params: {
    previewLeadingSpu: boolean;
    refreshIndicative?: boolean;
  }) {
    const { previewLeadingSpu, refreshIndicative } = params;

    const spu = { ...this.spuForm };
    const detail = _.cloneDeep(this.initSpuFields);

    const isComboIndex = detail.findIndex(
      (item) => item.label === ESPUField.IS_COMBO,
    );
    const idIdx = detail.findIndex((item) => item.label === ESPUField.ID);
    const originalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.ORIGINAL_PRICE_CENT,
    );
    const vipPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.VIP_PRICE_CENT,
    );
    const newUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.NEW_USER_PRICE_CENT,
    );
    const comboTotalPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.COMBO_TOTAL_PRICE,
    );
    const effectiveTimesIdx = detail.findIndex(
      (item) => item.label === ESPUField.EFFECTIVE_TIMES,
    );
    const headlineImageIdx = detail.findIndex(
      (item) => item.label === ESPUField.HEADLINE_IMAGE,
    );
    const hasNewUserPriceIdx = detail.findIndex(
      (item) => item.label === ESPUField.HAS_NEW_USER_PRICE,
    );
    const imagesIdx = detail.findIndex(
      (item) => item.label === ESPUField.IMAGES,
    );

    if (spu.id === 0) {
      // 新增商品时允许更换是否套餐
      detail[isComboIndex].readonly = false;
      detail[idIdx].isHidden = true;
    } else {
      // 编辑商品不允许更改是否套餐的选项
      detail[isComboIndex].readonly = true;
      detail[idIdx].isHidden = false;
    }

    if (spu.isCombo === true) {
      detail[effectiveTimesIdx].isHidden = false;
      detail[comboTotalPriceIdx].isHidden = false;
      detail[originalPriceIdx].labelName = '单次门市价(元)';
      detail[vipPriceIdx].labelName = '单次黑卡价(元)';
      detail[newUserPriceIdx].labelName = '单次特价(元)';
    } else {
      detail[effectiveTimesIdx].isHidden = true;
      detail[comboTotalPriceIdx].isHidden = true;
      detail[originalPriceIdx].labelName = '门市价(元)';
      detail[vipPriceIdx].labelName = '黑卡价(元)';
      detail[newUserPriceIdx].labelName = '特价(元)';
    }

    if (spu.hasNewUserPrice === true) {
      detail[newUserPriceIdx].isHidden = false;
    } else {
      detail[newUserPriceIdx].isHidden = true;
    }

    if (refreshIndicative === true) {
      const indicativePrice = await this.spuService.indicativePrice({
        shopId: Number(this.shopId),
        categoryId: spu.category.id,
      });
      if (indicativePrice) {
        this.editingSpu.indicativePrice = indicativePrice;
        // eslint-disable-next-line standard/computed-property-even-spacing
        detail[
          vipPriceIdx
        ].extra = `参考价：该商品分类，同城均价¥${indicativePrice}`;
      } else {
        detail[vipPriceIdx].extra = '暂无参考价';
      }
    } else {
      detail[vipPriceIdx].extra =
        this.editingSpu.indicativePrice > 0
          ? `参考价：该商品分类，同城均价¥${this.editingSpu.indicativePrice}`
          : '暂无参考价';
    }

    if (previewLeadingSpu === true) {
      delete detail[hasNewUserPriceIdx].extra;
      delete detail[headlineImageIdx].extra;
      delete detail[imagesIdx].extra;
      if (spu.hasNewUserPrice === true) {
        detail[hasNewUserPriceIdx].isHidden = true;
      } else {
        detail[hasNewUserPriceIdx].isHidden = false;
      }
    }

    return detail;
  }

  vipPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { originalPriceCent, products } = this.spuForm;

    if (products.length) {
      return +value < +this.spuForm.comboTotalPrice.originalPriceCent;
    } else {
      if (+value > 0 && (!originalPriceCent || isNaN(+originalPriceCent))) {
        return true;
      }
      return +value < +originalPriceCent;
    }
  }

  newUserPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { vipPriceCent } = this.spuForm;
    if (!vipPriceCent || isNaN(+vipPriceCent)) {
      return true;
    }
    return +value < +vipPriceCent;
  }

  originalPriceValidator(
    rule: { [key: string]: string | (() => boolean) },
    value: string,
  ) {
    const { products } = this.spuForm;
    if (products.length) {
      return +this.spuForm.comboTotalPrice.originalPriceCent > 0;
    } else {
      return +value > 0;
    }
  }

  async handleSubmit(val: { [key: string]: any }) {
    const {
      category,
      effectiveTimes,
      headlineImage,
      introduction,
      isHot,
      name,
      products,
      images,
      unavailableTime,
      id,
    } = val;
    const { originalPriceCent, vipPriceCent, newUserPriceCent } =
      val.comboTotalPrice;
    const spu: { [key: string]: any } = {
      category,
      headlineImage,
      effectiveTimes,
      name,
      unavailableTime,
      products,
      originalPriceCent,
      vipPriceCent,
      newUserPriceCent,
      isHot,
      introduction,
      images,
    };
    if (id !== 0) {
      // 编辑商品
      spu.id = id;
      const res = await this.spuService.spuEdit(this.shopId, spu, this.$el);
      if (!res) {
        return;
      }
      this.$message.success('修改成功，正在审核中');
      this.spuEditor.close();
    } else {
      // 新增商品
      const res = await this.spuService.spuCreate(this.shopId, spu, this.$el);
      if (!res) {
        return;
      }
      this.$message.success('新增成功，正在审核中');
      this.spuEditor.close();
    }
  }

  async handleUpdate(form: { [key: string]: any }, initial?: boolean) {
    let shouldGetIndicativePrice = false;
    if (initial && initial === true) {
      shouldGetIndicativePrice = form.category.id !== 0;
      this.spuForm = { ...form };
      // 初始化商品
      this.spuEditor.setup(
        await this.initSpuConfig({
          previewLeadingSpu: false,
          refreshIndicative: shouldGetIndicativePrice,
        }),
        this.spuForm,
      );
      return;
    }

    shouldGetIndicativePrice =
      form.category.id !== 0 &&
      this.spuForm &&
      this.spuForm.category.id !== form.category.id;

    this.spuForm = { ...form };

    // 更新商品属性
    if (!this.spuForm.hasNewUserPrice) {
      this.$set(this.spuForm, ESPUField.NEW_USER_PRICE_CENT, '0.00');
    }
    if (!this.spuForm.isCombo) {
      this.$set(this.spuForm, ESPUField.EFFECTIVE_TIMES, 1);
    }
    if (this.spuForm.products && this.spuForm.products.length) {
      const priceCent = (this.spuForm.products as ISPUProduct[])
        .map(({ originalPriceCent }) => +originalPriceCent)
        .reduce((prev, cur) => prev + cur, 0);

      this.$set(
        this.spuForm,
        ESPUField.ORIGINAL_PRICE_CENT,
        (priceCent / 100).toFixed(2),
      );
    }
    const times = this.spuForm.effectiveTimes * 100;
    const comboTotalPrice = {
      originalPriceCent: Math.round(times * this.spuForm.originalPriceCent),
      vipPriceCent: Math.round(times * this.spuForm.vipPriceCent),
      newUserPriceCent:
        this.spuForm.hasNewUserPrice === true
          ? Math.round(times * this.spuForm.newUserPriceCent)
          : 0,
    };
    this.$set(this.spuForm, ESPUField.COMBO_TOTAL_PRICE, comboTotalPrice);

    this.spuEditor.setup(
      await this.initSpuConfig({
        previewLeadingSpu: false,
        refreshIndicative: shouldGetIndicativePrice,
      }),
      this.spuForm,
      true,
    );
  }
}
