import { render, staticRenderFns } from "./LookShopDetail.vue?vue&type=template&id=ffce648c&scoped=true"
import script from "./LookShopDetail.vue?vue&type=script&lang=ts"
export * from "./LookShopDetail.vue?vue&type=script&lang=ts"
import style0 from "./LookShopDetail.vue?vue&type=style&index=0&id=ffce648c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ffce648c",
  null
  
)

export default component.exports