
import { EAuditStatus } from '@/common/enum/audit';
import {
  IResCityAuditTaskNum,
  IResToAuditTaskBrief,
} from '@/common/interface/audit';
import lazyInject from '@/di';
import AuditService from '@/services/audit.service';
import moment, { Moment } from 'moment';
import { Vue, Component, Watch } from 'vue-property-decorator';
import ExamineService from '@/services/examine.service';
import SPUService from '@/services/spu.service';
import ShopService from '@/services/shop.service';
import UserService from '@/services/user.service';

@Component({})
export default class AuditList extends Vue {
  EAuditStatus = EAuditStatus;

  @lazyInject(ExamineService)
  examineService!: ExamineService;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @lazyInject(UserService)
  userService!: UserService;

  @lazyInject(ShopService)
  shopService!: ShopService;

  isVisible = false;

  isExamine = true;

  tagentList: any = [];

  current: any = [];

  typeClass = 0;

  columns = [
    {
      title: 'ID',
      dataIndex: 'nodeId',
      key: 'nodeId',
    },
    {
      title: '任务名称',
      dataIndex: 'taskName',
      key: 'taskName',
      scopedSlots: { customRender: 'taskName' },
    },
    {
      title: '门店名称',
      dataIndex: 'shopName',
      key: 'shopName',
      scopedSlots: { customRender: 'shopName' },
    },
    {
      title: 'POI',
      dataIndex: 'poi',
      key: 'poi',
      scopedSlots: { customRender: 'poi' },
    },
    {
      title: '提交时间',
      dataIndex: 'createTime',
      key: 'createTime',
    },
    {
      title: '发起人',
      key: 'createUserName',
      dataIndex: 'createUserName',
      scopedSlots: { customRender: 'createUserName' },
    },
    {
      title: '审核状态',
      key: 'auditStatus',
      dataIndex: 'auditStatus',
      scopedSlots: { customRender: 'auditStatus' },
    },
  ];

  auditList: IResToAuditTaskBrief[] = [];

  auditProjects: Array<{
    id: number;
    name: string;
  }> = [];

  form: {
    name: string | undefined;
    projectId: number;
    startTime: string;
    endTime: string;
    poi: any;
    shopName: any;
    agentId: any;
    kaId: any;
  } = {
    name: undefined,
    projectId: 0,
    startTime: '',
    endTime: '',
    shopName: '',
    poi: '',
    agentId: '',
    kaId: '',
  };

  pagination = {
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
  };
  kaList: any = [];
  kaListMap: any = [];

  cityAuditTaskNums: IResCityAuditTaskNum[] = [];

  moment = moment;

  onChangeCreateTime(value: Moment[]) {
    if (!value.length) {
      this.form.startTime = '';
      this.form.endTime = '';
      return;
    }

    this.$set(
      this.form,
      'startTime',
      moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
    );
    this.$set(
      this.form,
      'endTime',
      moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    );
  }

  async getCityAuditTaskNums() {
    const res = await this.auditService.cityAuditTaskNums();
    if (res) {
      this.cityAuditTaskNums = res;
    }
  }

  async getNameList(name: string) {
    if (name) {
      this.form.name = name;
      this.cityAuditTaskNums = [];
      return;
    }
    await this.getCityAuditTaskNums();
  }

  async getagentList() {
    const response = (await this.examineService.agentList()) as any;
    if (response) {
      this.tagentList = response;
    }
  }

  agentFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
  async getKaList() {
    const id = this.userService.currentUser()?.id;
    const response = await this.shopService.postKaUserList(id);
    if (response) {
      this.kaList = response;
    }
  }

  handleNameChange(index: number) {
    this.$set(this.form, 'name', this.cityAuditTaskNums[index].name);
  }

  /** 切换table */
  changeType(val: any) {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    const _aname = this.auditProjects.filter((res) => { return res.id == val; });
    if (_aname[0]?.name.includes('审核入驻门店')) {
      this.isExamine = false;
    } else {
      this.isExamine = true;
    }
    this.typeClass = val;
    this.current[0] = val;
    const param = this.extractFilterFormParam();
    Object.assign(param, { projectId: `${val}` });
    this.$router.push({
      query: param,
    });
  }
  extractFilterFormParam() {
    const {
      name,
      poi,
      shopName,
      projectId,
      startTime,
      endTime,
    } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      page: `${this.pagination.current}`,
      projectId,
    };

    if (poi) {
      param.poi = poi;
    }

    if (shopName) {
      param.shopName = shopName;
    }

    if (name) {
      param.name = name;
    }

    if (startTime && endTime) {
      param.startTime = startTime;
      param.endTime = endTime;
    }


    return param;
  }
  paginationChange(current: number) {
    this.pagination.current = current;
    const { name, projectId, startTime, endTime, poi, shopName } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: current,
    };
    if (name) {
      query.name = name;
    }
    if (poi) {
      query.poi = poi;
    }
    if (shopName) {
      query.shopName = shopName;
    }
    if (+projectId) {
      query.projectId = projectId;
    }
    if (startTime) {
      query.startTime = startTime;
    }
    if (endTime) {
      query.endTime = endTime;
    }
    this.$router.push({ query });
  }

  goToAuditDetail(projectId: string, polymerizeId: string) {
    const routeUrl = this.$router.resolve({
      name: 'audit.detail',
      params: {
        id: `${projectId}`,
      },
      query: {
        polymerizeId: `${polymerizeId}`,
      },
    });
    window.open(routeUrl.href, '_blank');
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };
    const { name, projectId, startTime, endTime, poi, shopName, kaId, agentId } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: `${this.pagination.current}`,
    };

    if (name) {
      query.name = name;
    }
    if (poi) {
      query.poi = poi;
    }

    if (shopName) {
      query.shopName = shopName;
    }

    if (+projectId) {
      query.projectId = projectId;
    }

    if (startTime) {
      query.startTime = startTime;
    }
    if (endTime) {
      query.endTime = endTime;
    }
    if (kaId) {
      query.kaId = kaId;
    }
    if (agentId) {
      query.agentId = agentId;
    }

    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({ query });
  }

  async getProject() {
    // this.auditProjects = [{ id: 0, name: '全部' }].concat(
    //   await this.auditService.auditProjects(),
    // );
    this.auditProjects = await this.auditService.auditProjects();
    this.getKaList();
    this.getagentList();
    // this.spuService.kaSpuKas();
    this.typeClass = this.auditProjects[0]?.id;
    this.current[0] = this.auditProjects[0]?.id;
    if (this.auditProjects[0]?.name.includes('审核入驻门店')) {
      this.isExamine = false;
    }
    const param = this.extractFilterFormParam();
    Object.assign(param, { projectId: `${this.auditProjects[0]?.id}` });
    this.$router.push({
      query: param,
    });
  }

  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;
    const { name, projectId, startTime, endTime, poi, shopName } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      pageSize,
      page: current,
      projectId: projectId || 0,
    };

    if (name) {
      this.form.name = name as string;
      param.name = name;
    } else {
      this.form.name = undefined;
    }

    if (projectId) {
      this.form.projectId = +projectId;
    } else {
      this.form.projectId = 0;
    }
    if (poi) {
      this.form.poi = poi;
    } else {
      this.form.poi = '';
    }
    if (shopName) {
      this.form.shopName = shopName;
    } else {
      this.form.shopName = '';
    }
    if (startTime) {
      this.form.startTime = startTime as string;
      param.startTime = startTime;
    } else {
      this.form.startTime = '';
    }
    if (endTime) {
      this.form.endTime = endTime as string;
      param.endTime = endTime;
    } else {
      this.form.endTime = '';
    }
    Object.assign(param, this.form);

    await this.toAuditList(param);
  }

  @lazyInject(AuditService)
  protected auditService!: AuditService;

  /**
   * 获取待审核列表
   */
  async toAuditList(params: {
    pageSize?: number;
    page: number;
    name?: string;
    startTime?: string;
    endTime?: string;
  }) {
    const res = await this.auditService.toAuditList(params, this.$el);
    if (res) {
      this.auditList = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  /**
   * 页面刷新
   */
  refresh() {
    const { projectId } = this.$route.query;

    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    this.form = {
      name: undefined,
      poi: '',
      shopName: '',
      projectId: 0,
      startTime: '',
      endTime: '',
      agentId: '',
      kaId: '',
    };
    const query: any = {
      page: 1,
      projectId,
    };
    this.$router.push({
      query,
    });
  }

  async created() {
    await this.getProject();
    this.$nextTick(async () => {
      const { name, projectId, startTime, endTime, poi, shopName } = this.$route.query;
      if (name) {
        this.$set(this.form, 'name', name);
      }
      if (projectId) {
        this.$set(this.form, 'projectId', projectId);
      }
      if (poi) {
        this.$set(this.form, 'poi', poi);
      }
      if (shopName) {
        this.$set(this.form, 'shopName', shopName);
      }
      if (startTime) {
        this.$set(this.form, 'startTime', startTime);
      }
      if (endTime) {
        this.$set(this.form, 'endTime', endTime);
      }
      await this.handleSearch();
      await this.getCityAuditTaskNums();
    });
  }
}
