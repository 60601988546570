
import { Vue, Component, Prop } from 'vue-property-decorator';
import { ISPUCategory } from '@/common/interface/spu';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';

@Component({})
export default class SpuDefaultServicesModal extends Vue {
  @lazyInject(SPUService)
  protected spuService!: SPUService;

  visible = false;

  @Prop({ type: Array, default: () => [] })
  allSpuCategory!: ISPUCategory[];

  @Prop({ type: Array, default: () => [] })
  secondCategoryList!: ISPUCategory[];

  type = 'add';

  pType = false;

  childCategoryList: ISPUCategory[] = [];

  title = '新增商品服务详情默认内容';

  userInfo: any = {};

  form = {
    pid: undefined,
    cid: undefined,
    default_item: '',
  };
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 18 },
    },
  };
  formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 18, offset: 6 },
    },
  };

  open(type: 'add' | 'edit' | 'look', record?: any) {
    if (type === 'add') {
      this.title = '新增商品服务详情默认内容';
    } else if (type === 'edit') {
      this.title = '编辑商品服务详情默认内容';
    } else {
      this.title = '查看商品服务详情默认内容';
    }
    this.visible = true;
    this.type = type;
    if (type === 'edit') {
      if (record.pid !== 0) {
        this.handleFcateChange(record.pid);
        this.form.cid = record.id;
        this.form.pid = record.pid;
        this.pType = true;
      } else {
        this.pType = false;
        this.form.pid = record.id;
      }
      this.form.default_item = record.default_item;
    } else if (type === 'look') {
      if (record.pid !== 0) {
        this.handleFcateChange(record.pid);
        this.form.cid = record.id;
        this.form.pid = record.pid;
        this.pType = true;
      } else {
        this.form.pid = record.id;
        this.pType = false;
      }
      this.userInfo.firstName = record.firstName;
      this.userInfo.firstCreateTime = record.firstCreateTime;
      this.userInfo.lastName = record.lastName;
      this.userInfo.lastUpdateTime = record.lastUpdateTime;
      this.form.default_item = record.default_item;
    }
  }
  handleLog() {
    // this.logModal.open(this.form);
    const id = this.form.cid || this.form.pid;
    const routeUrl = this.$router.resolve({
      name: 'spu.logList',
      params: {
        id: `${id}`,
        type: '2',
      },
    });

    window.open(routeUrl.href, '_blank');
  }
  handleFcateChange(value: number) {
    console.log(value);
    this.childCategoryList = this.allSpuCategory.find((item) => item.id === value)?.children || [];
    this.form.cid = undefined;
  }
  cancel() {
    this.form = {
      pid: undefined,
      cid: undefined,
      default_item: '',
    };
    this.visible = false;
  }

  async handleSubmit() {
    console.log(this.form);
    const data: any = {
      id: this.form.pid,
      default_item: this.form.default_item,
    };
    if (this.form.cid) {
      data.id = this.form.cid;
    }
    const res = await this.spuService.postSpuDefaultDefaultItem(data);
    if (res) {
      this.$message.success('操作成功');
      this.$emit('getList');
      this.cancel();
    }
  }
}
