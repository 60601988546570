/*
 * @Author: Src
 * @Date: 2024-07-16 14:41:02
 * @LastEditTime: 2024-12-25 10:04:43
 * @LastEditors: Src
 * @FilePath: /work-station/src/services/utils.service.ts
 * @Description: 头部注释配置模板
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

@injectable()
export default class UTILService {
  @inject(HttpClient)
  protected http!: HttpClient;

  async getOssConfig(shopId: number | string) {
    const url = `/tool/video/upload-access-credentials?shopId=${shopId}`;
    try {
      const res = (await this.http.get(url)) as {
        access_id: string;
        host: string;
        policy: string;
        signature: string;
        callback: string;
        dir: string;
        file_name: string;
      };
      return res;
    } catch (error) {
      return null;
    }
  }

  async postPhoneDecrypt(data: any) {
    try {
      const res = await this.http.post('/tool/phone/decrypt', data) as any;
      return res;
    } catch (e) {
      return null;
    }
  }
}
