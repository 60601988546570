
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import { ISPUCategory } from '@/common/interface/spu';
import SPUService from '@/services/spu.service';
import lazyInject from '@/di';
import SpuServicesModal from './components/SpuServicesModal.vue';
import CopyPropertyModal from './components/copyPropertyModal.vue';
import { SpuServicesList } from '@/models/spu.model';
import { AuthEnum } from '@/services/permission.service';

@Component({
  components: {
    'spu-services-modal': SpuServicesModal,
    'copy-property-modal': CopyPropertyModal,
  },
})
export default class SpuServices extends Vue {
  AuthEnum = AuthEnum;

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @lazyInject(SPUService)
  protected SPUService!: SPUService;

  @Ref('spu_services_modal')
  spuServicesModal!: SpuServicesModal;

  @Ref('copy_property_modal')
  copyPropertyModal!: CopyPropertyModal;

  form = {
    pid: '',
    cid: '',
    item: '',
    status: '',
  };
  columns: any[] = [
    {
      title: '一级类目',
      dataIndex: 'firstCategory',
      align: 'center',
    },
    {
      title: '二级类目',
      dataIndex: 'secondCategory',
      align: 'center',
    },
    {
      title: '属性名称',
      dataIndex: 'item',
      align: 'center',
    },
    {
      title: '属性值类型',
      dataIndex: 'category',
      align: 'center',
      scopedSlots: { customRender: 'category' },
    },
    {
      title: '是否必填',
      dataIndex: 'required',
      align: 'center',
      scopedSlots: { customRender: 'required' },
    },
    {
      title: '添加人',
      dataIndex: 'createUser',
      align: 'center',
    },
    {
      title: '添加时间',
      dataIndex: 'createdAt',
      align: 'center',
      width: 280,
    },
    {
      title: '状态',
      dataIndex: 'status',
      align: 'center',
      width: 100,
      scopedSlots: { customRender: 'status' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
      width: 220,
    },
  ];

  spuType = false;

  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    onChange: this.onPageChange,
  };

  dataSource: any[] = [];

  allSpuCategory: ISPUCategory[] = [];

  secondCategoryList: ISPUCategory[] = [];

  @Watch('$route')
  onQueryChange() {
    const { pid, cid, item, status, page, pageSize } = this.$route.query;
    const data: any = {
      page: page || 1,
      pageSize: pageSize || 10,
    };
    if (pid) {
      data.pid = pid;
      this.$set(this.form, 'pid', Number(pid));
    }
    if (cid) {
      data.cid = cid;
      this.$set(this.form, 'cid', Number(cid));
    }
    if (item) {
      data.item = item;
      this.$set(this.form, 'item', item);
    }
    if (status) {
      data.status = status;
      this.$set(this.form, 'status', status);
    }
    this.getShopDetailItemList(data);
  }

  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      loadingEl: this.$el,
    });

    this.allSpuCategory = data;

    this.secondCategoryList = data.reduce((prev, cur) => {
      return prev.concat(cur.children || []);
    }, [] as ISPUCategory[]);
  }

  async getShopDetailItemList(data: any) {
    const res = await this.SPUService.getShopDetailItemList(data);
    if (res) {
      this.dataSource = res.list.map((item) => SpuServicesList.from(item));
      this.pagination.total = res.total;
    }
  }

  onChangeFcate(val: number) {
    this.$set(this.form, 'cid', '');
    if (val) {
      this.$set(this.form, 'pid', val);
      this.filterBySpuCategory();
    }
  }

  filterBySpuCategory() {
    if (Number(this.form.pid) < 0 || !this.form.pid) {
      return this.secondCategoryList;
    } else {
      for (const idx of this.allSpuCategory) {
        if (Number(this.form.pid) === idx.id) {
          return idx.children;
        }
      }
    }
  }

  onChangeCate(val: number) {
    if (!val) {
      return;
    }

    const allSpuCategory = [...this.allSpuCategory];
    const extractFcate = (all: ISPUCategory[]) => {
      let fcateId;
      for (const item of all) {
        for (const child of item.children!) {
          if (val === child.id) {
            fcateId = item.id;
            break;
          }
        }
      }
      return fcateId;
    };

    this.$set(this.form, 'pid', extractFcate(allSpuCategory));
    this.filterBySpuCategory();
    this.$set(this.form, 'cid', val);
  }

  onPageChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    let query: any = {
      page,
      pageSize,
    };
    const queryParams = this.fromQuery();
    query = Object.assign(query, queryParams);
    this.$router.push({
      query,
    });
  }

  fromQuery() {
    const { pid, cid, item, status } = this.form;
    const query: any = {};
    if (pid) {
      query.pid = pid;
    }
    if (cid) {
      query.cid = cid;
    }
    if (item) {
      query.item = item;
    }
    if (status) {
      query.status = status;
    }
    return query;
  }

  search() {
    let query: any = {
      page: 1,
      pageSize: 10,
    };
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    const queryParams = this.fromQuery();
    query = Object.assign(query, queryParams);
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  copyProperty() {
    this.copyPropertyModal.open();
  }

  addProperty() {
    this.spuType = false;
    this.spuServicesModal.open();
  }

  async editProperty(record: any) {
    this.spuType = false;
    const data = await this.spuService.getShopDetailItemDetail(record.id);
    if (data) {
      this.spuServicesModal.open(data);
    }
  }

  async detailProperty(record: any) {
    console.log(record);
    this.spuType = true;
    const data = await this.spuService.getShopDetailItemDetail(record.id);
    if (data) {
      this.spuServicesModal.open(data);
    }
  }
  async deleteProperty(record: any) {
    const res = await this.spuService.deleteShopDetailItem(record.id);
    if (res) {
      this.$message.success('删除成功');
      this.search();
    }
  }
  refresh() {
    console.log('refresh');
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    const query: any = {
      page: 1,
      pageSize: 10,
    };
    this.form = {
      pid: '',
      cid: '',
      item: '',
      status: '',
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  mounted() {
    this.getCategory();
    this.onQueryChange();
  }
}
