
import lazyInject from '@/di';
import { Vue, Component, Watch } from 'vue-property-decorator';
import { WithdrawStatusEnum } from '@/common/enum/withdraw';
import WithdrawService from '@/services/withdraw.service';
import { WithdrawOrderItem } from '@/common/interface/withdraw';
import { $delay } from '@/common/utils';
import ShopService from '@/services/shop.service';
import { City } from '@/common/interface/shop';
import UTILService from '@/services/utils.service';
import {
  AuthEnum,
} from '@/services/permission.service';

@Component({})
export default class WithdrawList extends Vue {
  AuthEnum = AuthEnum;
  @lazyInject(UTILService)
  private utilService!: UTILService;

  notificationKey = 'updatable';

  requesting = false;

  WithdrawStatusEnum = WithdrawStatusEnum;

  withdrawStatusList: WithdrawStatusEnum[] = [
    WithdrawStatusEnum.全部,
    WithdrawStatusEnum.待处理,
    // WithdrawStatusEnum.操作中, // 转账，撤销结果返回前的状态
    WithdrawStatusEnum.已完成,
    WithdrawStatusEnum.已撤销,
  ];

  withdrawList: WithdrawOrderItem[] = [];

  encryptAccount = '';

  filterForm: {
    shopName: string;
    status: number;
    regionId: string | number;
  } = {
      shopName: '',
      status: -1,
      regionId: '',
    };

  pagination = {
    pageSizeOptions: ['10', '20', '30', '40', '50', '300', '500'],
    showSizeChanger: true,
    total: 0,
    pageSize: 20,
    current: 1,
    onChange: this.paginationChange,
    onShowSizeChange: this.onPageSizeChange,
  };

  citys: City[] = [];

  @lazyInject(WithdrawService)
  protected withdrawService!: WithdrawService;

  @lazyInject(ShopService)
  shopService!: ShopService;

  successfulTransfer: number[] = [];

  failedTransfer: number[] = [];

  get successfulTransferAmountCent() {
    if (!this.successfulTransfer.length) {
      return 0;
    }
    return this.successfulTransfer.reduce((prev: number, current: number) => {
      return prev + current;
    });
  }

  get failedTransferAmountCent() {
    if (!this.failedTransfer.length) {
      return 0;
    }
    return this.failedTransfer.reduce((prev: number, current: number) => {
      return prev + current;
    });
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.shopService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  /** 选中项转账 */
  async transferSelectedOrders() {
    if (this.successfulTransfer.length || this.failedTransfer.length) {
      this.resetTransferList();
    }

    for await (const orderId of this.selectedRowOrderIds) {
      try {
        if (!this.requesting) {
          this.requesting = true;
        }

        const res = await this.withdrawService.transfer(orderId);
        if (res) {
          this.successfulTransfer.push(
            this.withdrawList.filter((item) => {
              return item.orderId === orderId;
            })[0].amountCent,
          );
        }
      } catch (error) {
        this.failedTransfer.push(
          this.withdrawList.filter((item) => {
            return item.orderId === orderId;
          })[0].amountCent,
        );
      } finally {
        this.openNotification();
        if (
          this.selectedRowOrderIds.length -
          this.successfulTransfer.length -
          this.failedTransfer.length ===
          0
        ) {
          this.requesting = false;
          await $delay(1500);
          this.closeNotification();
        }
      }
    }

    if (this.successfulTransfer.length) {
      this.resetTransferList();
      // 有转账成功的，刷新列表；
      await this.handleSearch();

      this.selectedRowOrderIds = [];
      this.selectedRowAmount = 0;
    }
  }

  resetTransferList() {
    this.successfulTransfer = [];
    this.failedTransfer = [];
  }

  async LookShopDetail(title: string, value: string) {
    const data = {
      encrypt_phone: value,
      operate_type: title,
    };
    console.log(data);
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.encryptAccount = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }

  openNotification() {
    this.$notification.open({
      key: this.notificationKey,
      description: `已成功${this.successfulTransfer.length}笔，金额${(
        this.successfulTransferAmountCent / 100
      ).toFixed(2)}元；已失败${this.failedTransfer.length}笔，金额${(
        this.failedTransferAmountCent / 100
      ).toFixed(2)}元；剩余${this.selectedRowOrderIds.length -
      this.successfulTransfer.length -
      this.failedTransfer.length
        }笔，金额${(
          (this.selectedRowAmount -
            this.successfulTransferAmountCent -
            this.failedTransferAmountCent) /
          100
        ).toFixed(2)}元`,
      message: '温馨提示',
      duration: 0,
    });
  }

  closeNotification() {
    this.$notification.close(this.notificationKey);
  }

  async transfer(orderId: number) {
    const res = await this.withdrawService.transfer(orderId);
    if (res) {
      // 转账成功后，更新当前页列表状态；
      await this.handleSearch();
    }
  }

  /** 撤销 */
  async cancel(orderId: number) {
    const res = await this.withdrawService.cancel(orderId);
    if (res) {
      // 撤销成功后，更新当前页列表状态；
      await this.handleSearch();
    }
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      pageSizeOptions: ['10', '20', '30', '40', '50', '300', '500'],
      showSizeChanger: true,
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
      onShowSizeChange: this.onPageSizeChange,
    };

    this.filterForm = {
      shopName: '',
      status: 0,
      regionId: '',
    };
    this.$router.push({
      query: {},
    });
  }

  selectedRowOrderIds: number[] = [];

  selectedRowAmount = 0;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange(selectedRowKeys: number[], selectedRows: any) {
    if (!selectedRowKeys.length) {
      this.selectedRowOrderIds = [];
      this.selectedRowAmount = 0;
      return;
    }

    this.selectedRowOrderIds = selectedRowKeys;
    this.selectedRowAmount = selectedRows
      .map((row: WithdrawOrderItem) => +row.amountCent)
      .reduce((previousValue: number, currentValue: number) => {
        return previousValue + currentValue;
      });
  }

  extractRouterQueryAndFillFilterForm() {
    const { shopName, status, regionId } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {};

    if (shopName) {
      param.shopName = shopName;
      this.filterForm.shopName = shopName as string;
    }

    if (typeof status !== 'undefined') {
      param.status = status;
      this.filterForm.status = +status;
    }

    if (regionId) {
      param.regionId = regionId;
      this.filterForm.regionId = +regionId;
    }

    return param;
  }

  extractFilterFormParam() {
    const { shopName, status, regionId } = this.filterForm;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {
      page: `${this.pagination.current}`,
    };

    if (status !== -1) {
      param.status = status;
    }

    if (shopName) {
      param.shopName = shopName;
    }

    if (regionId) {
      param.regionId = regionId;
    }

    return param;
  }

  /** 点击查询 */
  search() {
    this.$set(this.pagination, 'current', 1);
    this.$set(this.pagination, 'total', 0);

    const param = this.extractFilterFormParam();
    Object.assign(param, {
      page: `${this.pagination.current}`,
      timestamp: Math.round(Date.now() / 1000),
    });

    this.$router.push({
      query: param,
    });
  }

  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;

    const param = this.extractRouterQueryAndFillFilterForm();

    Object.assign(param, {
      pageSize,
      page: current,
    });

    await this.all(param);
  }

  /**
   * 获取（状态不传代表全部）提现列表
   */
  async all(params: {
    pageSize?: number;
    page: number;
    shopName?: string;
    regionId?: string;
    status?: number;
  }) {
    const res = await this.withdrawService.all(params, this.$el);

    if (res) {
      this.withdrawList = res.list;
      console.log(this.withdrawList);
      Object.assign(this.pagination, {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
      });
    }
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    const query = this.extractRouterQueryAndFillFilterForm();
    Object.assign(query, { page: current });

    this.$router.push({ query });
  }

  onPageSizeChange(current: number, size: number) {
    const previousPage = this.pagination.current;

    this.pagination.pageSize = size;
    // 变更每页条目数时，重新从第1页展示
    this.pagination.current = 1;
    this.pagination.total = 0;

    const query = this.extractRouterQueryAndFillFilterForm();
    Object.assign(query, { page: 1 });

    this.$router.push({ query });

    if (previousPage === 1) {
      this.handleSearch();
    }
  }

  async created() {
    this.$nextTick(async () => {
      await this.handleSearch();
      this.getCityList();
    });
  }
}
