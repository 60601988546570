
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { ISPUCategory } from '@/common/interface/spu';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import { FormModel } from 'ant-design-vue';

@Component({})
export default class CopyPropertyModal extends Vue {
  @lazyInject(SPUService)
  protected spuService!: SPUService;

  visible = false;

  title = '复制属性';

  @Ref()
  readonly formRef!: FormModel;

  rules = {
    from_pid: [{ required: true, message: '请选择一级类目' }],
    from_cid: [{ required: true, message: '请选择二级类目' }],
    to_pid: [{ required: true, message: '请选择一级类目' }],
    to_cid: [{ required: true, message: '请选择二级类目' }],
  };

  @Prop({ type: Array, default: () => [] })
  allSpuCategory!: ISPUCategory[];

  @Prop({ type: Array, default: () => [] })
  secondCategoryList!: ISPUCategory[];


  childCategoryList: ISPUCategory[] = [];

  childToCategoryList: ISPUCategory[] = [];

  form = {
    from_pid: undefined,
    from_cid: undefined,
    to_pid: undefined,
    to_cid: undefined,
  };

  formItemLayout = {
    labelCol: {
      xs: { span: 12 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 12 },
      sm: { span: 10 },
    },
  };
  formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  open() {
    this.visible = true;
  }
  handleFcateChange(value: number) {
    this.form.from_cid = undefined;
    this.childCategoryList = this.allSpuCategory.find((item) => item.id === value)?.children || [];
  }
  handleToFcateChange(value: number) {
    this.form.to_cid = undefined;
    this.childToCategoryList = this.allSpuCategory.find((item) => item.id === value)?.children || [];
  }
  cancel() {
    this.form = {
      from_pid: undefined,
      from_cid: undefined,
      to_pid: undefined,
      to_cid: undefined,
    };
    this.formRef.resetFields();
    this.visible = false;
  }

  handleSubmit() {
    console.log(this.form);
    this.formRef.validate((valid) => {
      if (valid) {
        this.submit();
      }
    });
  }
  async submit() {
    const res = await this.spuService.copyProperty(this.form);
    if (res) {
      this.$message.success('复制成功');
      this.$emit('onQueryChange');
      this.cancel();
    }
  }
}
