
import { Component, Vue } from 'vue-property-decorator';
import lazyInject from '@/di';
import { BrandService } from '@/services/brand_service';

@Component
export default class GiftDays extends Vue {
  form = {
    brand: '',
    gift_days: '',
    ka_id: '',
  };
  visible = false;

  @lazyInject(BrandService)
  protected brandService!: BrandService;

  async init(row: any) {
    // this.form = row;
    const { name, gift_days, id } = row;
    this.form.brand = name;
    this.form.gift_days = gift_days;
    this.form.ka_id = id;
    try {
      const res = await this.brandService.brandGiftDays(id);
      this.form.gift_days = res;
    } catch (error) {
      console.log(error);
    }
    this.visible = true;
  }
  handleCancel() {
    this.visible = false;
    this.form = {
      brand: '',
      gift_days: '',
      ka_id: '',
    };
  }
  async handleOk() {
    this.visible = false;
    const res = await this.brandService.updateBrand(this.form);
    if (res) {
      this.$emit('refresh');
    }
  }
}
