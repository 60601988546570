
import moment from 'moment';
import { Vue, Component, Watch } from 'vue-property-decorator';
import lazyInject from '@/di';
import {
  City,
  Kalist,
} from '@/common/interface/shop';

import ShopService from '@/services/shop.service';
import UserService from '@/services/user.service';
import WithdrawService from '@/services/withdraw.service';

@Component({})
export default class SettlementDoc extends Vue {
  selectedRowKeys: any = []; // Check here to configure the default column

  kalist: Kalist[] = [];

  @lazyInject(UserService)
  userService!: UserService;

  @lazyInject(ShopService)
  shopService!: ShopService;

  @lazyInject(WithdrawService)
  withdrawService!: WithdrawService;

  selectPriceDate = '';

  verifyts_data: any = [];

  ctime_data: any = [];

  citys: City[] = [];

  offsetDays: number = 86400000 * 30;

  filterForm: {
    brand: any;
    verifyts_start: string;
    verifyts_end: string;
    order_no: string;
    regionId: string | number;
    shopName: string;
    city: string;
    ctime_start: string;
    ctime_end: string;
  } = {
      brand: undefined,
      verifyts_start: '',
      verifyts_end: '',
      order_no: '',
      shopName: '',
      regionId: '',
      city: '',
      ctime_start: '',
      ctime_end: '',
    };
  columns = [
    {
      title: '序号',
      dataIndex: 'id',
      scopedSlots: { customRender: 'index' },
      align: 'center',
      width: 80,
    },
    {
      title: '高德订单号',
      dataIndex: 'order_no',
      align: 'center',
      width: 250,
    },
    {
      title: '城市',
      dataIndex: 'city',
      align: 'center',
      width: 80,
    },
    {
      title: '门店名称',
      dataIndex: 'shop_name',
      align: 'center',
      width: 200,
    },
    {
      title: 'SKU',
      dataIndex: 'goods_id',
      align: 'center',
      width: 100,
    },
    {
      title: '商品名称',
      dataIndex: 'goods_name',
      align: 'center',
      width: 200,
    },
    {
      title: '品牌',
      dataIndex: 'ka_name',
      align: 'center',
      width: 80,
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      align: 'center',
      width: 80,
    },
    {
      title: '总核销次数',
      dataIndex: 'tnum',
      align: 'center',
      width: 110,
    },
    {
      title: '核销次数',
      dataIndex: 'cnum',
      align: 'center',
      width: 100,
    },
    {
      title: '下单时间',
      dataIndex: 'ctime',
      align: 'center',
      width: 150,
    },
    {
      title: '订单金额',
      dataIndex: 'ori_price',
      align: 'center',
      width: 120,
    },
    {
      title: '优惠金额',
      dataIndex: 'coupon',
      align: 'center',
      width: 120,
    },
    {
      title: '实收金额',
      dataIndex: 'price',
      align: 'center',
      width: 120,
    },
    {
      title: '抽拥比例',
      dataIndex: 'com_ratio',
      align: 'center',
      width: 120,
      scopedSlots: { customRender: 'comRatio' },
    },
    {
      title: '结算金额',
      dataIndex: 'verify_price',
      align: 'center',
      width: 120,
    },

    {
      title: '核销时间',
      dataIndex: 'verifyts',
      align: 'center',
      width: 150,
    },
  ];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
    showTotal: (total: any) => `共 ${total} 条`,
  };

  data = [];

  loading = true;

  paginationChange(page: number, pageSize: number) {
    const { verifyts_start, verifyts_end, ctime_start, ctime_end, regionId, shopName, order_no, brand } = this.filterForm;
    const query: any = {
      page,
      pageSize,
    };
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    if (verifyts_start) {
      query.verifyts_start = verifyts_start;
    }
    if (verifyts_end) {
      query.verifyts_end = verifyts_end;
    }
    if (ctime_start) {
      query.ctime_start = ctime_start;
    }
    if (ctime_end) {
      query.ctime_end = ctime_end;
    }
    if (regionId) {
      query.regionId = regionId;
    }
    if (shopName) {
      query.shopName = shopName;
    }
    if (order_no) {
      query.order_no = order_no;
    }
    if (brand) {
      query.brand = brand;
    }
    this.$router.push({ query });
  }

  handleRowChange() { }

  @Watch('$route')
  routeChange() {
    const { verifyts_start, verifyts_end, ctime_start, ctime_end, regionId, shopName, order_no, brand, page, pageSize } = this.$route.query;
    const query: any = {
      page: page || this.pagination.current,
      pageSize: pageSize || this.pagination.pageSize,
    };
    if (verifyts_start) {
      query.verifyts_start = verifyts_start;
    }
    if (verifyts_end) {
      query.verifyts_end = verifyts_end;
    } else if (this.verifyts_data.length > 0) {
      query.verifyts_start = this.verifyts_data[0].format('YYYY-MM-DD');
      query.verifyts_end = this.verifyts_data[1].format('YYYY-MM-DD');
    }
    if (ctime_start) {
      query.ctime_start = ctime_start;
    } else if (this.verifyts_data.length > 0) {
      query.verifyts_start = this.verifyts_data[0].format('YYYY-MM-DD');
      query.verifyts_end = this.verifyts_data[1].format('YYYY-MM-DD');
    }
    if (ctime_end) {
      query.ctime_end = ctime_end;
    }
    if (regionId) {
      query.regionId = regionId;
    }
    if (shopName) {
      query.shopName = shopName;
    }
    if (order_no) {
      query.order_no = order_no;
    }
    if (brand) {
      query.brand = brand;
    } else {
      query.brand = this.filterForm.brand;
    }
    this.getverifylist(query);
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.shopService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }
  /**
   * 核销时间范围改变
   * @param date 日期范围
   */
  calendarPriceRangeChange(date: any) {
    this.selectPriceDate = date[0];
  }

  /**
   * 核销时间范围改变
   * @param date 日期范围
   */
  changePriceRangeDate(date: any) {
    if (date) {
      this.selectPriceDate = '';
    } else {
      this.selectPriceDate = this.filterForm.verifyts_start;
    }
  }

  /**
   * 禁用日期
   * @param current 当前日期
   * @returns 是否禁用
   */
  disabledDate(current: any) {
    if (this.selectPriceDate) {
      const selectV = moment(this.selectPriceDate, 'YYYY-MM-DD').valueOf();
      return current > moment(new Date(selectV + this.offsetDays), 'YYYY-MM-DD') ||
        current < moment(new Date(selectV - this.offsetDays), 'YYYY-MM-DD');
    } else {
      return false;
    }
  }

  onChange(dates: any, dateStrings: any, field: string) {
    const currentField = field;
    if (dates) {
      if (currentField === 'ctimeTime') {
        this.filterForm.ctime_start = dates[0].format('YYYY-MM-DD');
        this.filterForm.ctime_end = dates[1].format('YYYY-MM-DD');
        this.ctime_data = dates;
      } else if (currentField === 'verifyTime') {
        this.filterForm.verifyts_start = dates[0].format('YYYY-MM-DD');
        this.filterForm.verifyts_end = dates[1].format('YYYY-MM-DD');
        this.verifyts_data = dates;
      }
    } else if (currentField === 'ctimeTime') {
      this.filterForm.ctime_start = '';
      this.filterForm.ctime_end = '';
    } else if (currentField === 'verifyTime') {
      this.filterForm.verifyts_start = '';
      this.filterForm.verifyts_end = '';
    }
  }

  async handleDownload() {
    const params = {
      export: true,
      ...this.filterForm,
    };
    if (params.verifyts_start) {
      params.verifyts_start = `${params.verifyts_start} 00:00:00`;
    }
    if (params.verifyts_end) {
      params.verifyts_end = `${params.verifyts_end} 23:59:59`;
    }
    if (params.ctime_start) {
      params.ctime_start = `${params.ctime_start} 00:00:00`;
    }
    if (params.ctime_end) {
      params.ctime_end = `${params.ctime_end} 23:59:59`;
    }
    const data = new Date();
    const formatObj = {
      y: data.getFullYear(),
      m: data.getMonth() + 1,
      d: data.getDate(),
      h: data.getHours(),
      i: data.getMinutes(),
      s: data.getSeconds(),
      a: data.getDay(),
    };
    const Y = `${formatObj.y}-${formatObj.m > 9 ? formatObj.m : `0${formatObj.m}`}-${formatObj.d > 9 ? formatObj.d : `0${formatObj.d}`} ${formatObj.h > 9 ? formatObj.h : `0${formatObj.h}`}:${formatObj.i > 9 ? formatObj.i : `0${formatObj.i}`}:${formatObj.s > 9 ? formatObj.s : `0${formatObj.s}`}`;
    const response = await this.withdrawService.withdrawVerifyOrder(params);
    if (response) {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const link = document.createElement('a');
      const url = URL.createObjectURL(blob);
      link.href = url;
      link.download = `结算单_${Y}.xlsx`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  async getKaList() {
    const id = this.userService.currentUser()?.id;
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    currentDate.setDate(currentDate.getDate() + 1);
    this.filterForm.verifyts_start = currentDate.toISOString().split('T')[0];
    this.filterForm.verifyts_end = new Date().toISOString().split('T')[0];
    this.verifyts_data = [moment(this.filterForm.verifyts_start), moment(this.filterForm.verifyts_end)];
    const response = await this.shopService.postKaUserList(id);

    if (response) {
      this.kalist = response;
      this.filterForm.brand = response[0].kaId;
      const params = {
        brand: this.filterForm.brand,
        verifyts_start: `${this.filterForm.verifyts_start}`,
        verifyts_end: `${this.filterForm.verifyts_end}`,
        page: 1,
        pageSize: 10,
      };
      this.getverifylist(params);
    }
  }

  async getverifylist(params: any) {
    this.loading = true;
    if (params.verifyts_start) {
      params.verifyts_start = `${params.verifyts_start} 00:00:00`;
    }
    if (params.verifyts_end) {
      params.verifyts_end = `${params.verifyts_end} 23:59:59`;
    }
    if (params.ctime_start) {
      params.ctime_start = `${params.ctime_start} 00:00:00`;
    }
    if (params.ctime_end) {
      params.ctime_end = `${params.ctime_end} 23:59:59`;
    }
    const response = await this.withdrawService.withdrawVerifyOrder(params);
    if (response) {
      this.loading = false;
      this.data = response.list;
      this.pagination.total = response.total;
    }
  }

  resetQuery() {
    this.filterForm = {
      brand: this.kalist[0].kaId,
      verifyts_start: '',
      verifyts_end: '',
      order_no: '',
      shopName: '',
      regionId: '',
      city: '',
      ctime_start: '',
      ctime_end: '',
    };
    this.verifyts_data = [];
    this.ctime_data = [];
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() - 1);
    currentDate.setDate(currentDate.getDate() + 1);
    this.filterForm.verifyts_start = currentDate.toISOString().split('T')[0];
    this.filterForm.verifyts_end = new Date().toISOString().split('T')[0];
    this.verifyts_data = [moment(this.filterForm.verifyts_start), moment(this.filterForm.verifyts_end)];
    const query: any = {
      page: 1,
      pageSize: 10,
      brand: this.filterForm.brand,
      verifyts_start: this.filterForm.verifyts_start,
      verifyts_end: this.filterForm.verifyts_end,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({ query });
  }

  search() {
    const { verifyts_start, verifyts_end, ctime_start, ctime_end, regionId, shopName, order_no, brand } = this.filterForm;
    const query: any = {
      page: this.pagination.current,
      pageSize: this.pagination.pageSize,
    };
    if (verifyts_start) {
      query.verifyts_start = `${verifyts_start}`;
    }
    if (verifyts_end) {
      query.verifyts_end = `${verifyts_end}`;
    }
    if (ctime_start) {
      query.ctime_start = `${ctime_start}`;
    }
    if (ctime_end) {
      query.ctime_end = `${ctime_end}`;
    }
    if (regionId) {
      query.regionId = regionId;
    }
    if (shopName) {
      query.shopName = shopName;
    }
    if (order_no) {
      query.order_no = order_no;
    }
    if (brand) {
      query.brand = brand;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({ query });
  }
  refresh() { }
  mounted() {
    this.getKaList();
    this.getCityList();
  }
}
