/*
 * @Author: Src
 * @Date: 2024-12-20 14:53:45
 * @LastEditTime: 2024-12-26 16:36:02
 * @LastEditors: Src
 * @FilePath: /work-station/src/services/operation.service.ts
 * @Description: 头部注释配置模板
 */
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

@injectable()
export default class OperationService {
  @inject(HttpClient)
  protected http!: HttpClient;

  /**
   * 获取店铺列表
   * @param params
   * @returns
   */
  async getShopList(params: any) {
    try {
      const res = await this.http.get('/ka_shop_enter/shop/list', { params }) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 获取批次列表
   * @param params
   * @returns
   */
  async getBatchList(params: any) {
    try {
      const res = await this.http.get('/ka_shop_enter/batch/list', { params }) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 获取批次门店列表
   * @param batchNo
   * @param params
   * @returns
   */
  async getBatchShops(batchNo: string, params: any) {
    try {
      const res = await this.http.get(`/ka_shop_enter/batch/shops/${batchNo}`, { params }) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 加入批次
   * @param batchNo
   * @param params
   * @returns
   */
  async joinBatch(batchNo: string, params: any) {
    try {
      const res = await this.http.post(`/ka_shop_enter/join_batch/${batchNo}`, params) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 从批次中移除
   * @param batchNo
   * @param params
   * @returns
   */
  async delFromBatch(batchNo: string, params: any) {
    try {
      const res = await this.http.post(`/ka_shop_enter/del_from_batch/${batchNo}`, params) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 添加批次
   * @param params
   * @returns
   */
  async addBatch(params: any) {
    try {
      const res = await this.http.post('/ka_shop_enter/batch/add', params) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 编辑批次
   * @param params
   * @returns
   */
  async editBatch(params: any) {
    try {
      const res = await this.http.post('/ka_shop_enter/batch/edit', params) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 获取批次编号
   * @returns
   */
  async getBatchNumber() {
    try {
      const res = await this.http.get('/ka_shop_enter/batch/number') as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 获取店铺详情
   * @param id
   * @returns
   */
  async getShopDetail(id: any) {
    try {
      const res = await this.http.get(`/ka_shop_enter/shop_detail/${id}`) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 删除批次
   * @param batchNo
   * @param params
   * @returns
   */
  async delBatch(batchNo: string, params: any) {
    try {
      const res = await this.http.post(`/ka_shop_enter/del_batch/${batchNo}`, params) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 品牌侧修改接口
   * @param id
   * @param params
   * @returns
   */
  async shopEdit(id: any, params: any) {
    try {
      const res = await this.http.post(`/ka_shop_enter/shop_edit/${id}`, params) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  /**
   * 德友惠复核接口
   * @param id
   * @param params
   * @returns
   */
  async revEdit(id: any, params: any) {
    try {
      const res = await this.http.post(`/ka_shop_enter/rev_edit/${id}`, params) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  /**
   * 删除店铺
   * @param id
   * @returns
   */
  async delShop(id: any) {
    try {
      const res = await this.http.post(`/ka_shop_enter/shop/delete/${id}`) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  /**
   * 审核店铺
   * @param id
   * @returns
   */
  async shopAudit(id: any) {
    try {
      const res = await this.http.post(`/ka_shop_enter/shop/audit/${id}`) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  /**
   * 品牌代运营审批通过
   * @param id
   * @param params
   * @returns
   */
  async revPass(id: any, params?: any) {
    try {
      const res = await this.http.post(`/ka_shop_enter/rev_pass/${id}`, params) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }


  /**
   * 提交批次
   * @param batchNo
   * @returns
   */
  async submitBatch(batchNo: string) {
    try {
      const res = await this.http.post(`/ka_shop_enter/batch/submit/${batchNo}`) as any;
      return res;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}

