
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { ActivitySignup } from '@/models/activity.model';
import lazyInject from '@/di';
import { AccountService } from '@/services/account_service';
import { handleTree } from '@/common/utils';
import { FormModel } from 'ant-design-vue';

@Component
export default class DepartmentModal extends Vue {
  treeData: any[] = [];

  @Prop({
    default: () => {
      return [];
    },
  })
  treeDataArry!: ActivitySignup[];

  visible = false;

  @Ref('formRef')
  formRef!: FormModel;

  @lazyInject(AccountService)
  protected accountService!: AccountService;

  parentId = 0;

  deptName = '';

  rules: any = {
    deptName: [{ required: true, message: '请输入部门名称', trigger: 'blur' }],
    parentId: [{ required: true, message: '请选择上级部门', trigger: 'blur' }],
    // orderNum: [{ required: true, message: '请输入部门排序', trigger: 'blur' }],
  };

  form: any = {
    deptName: '',
    parentId: null,
    orderNum: '',
    leader: '',
    phone: '',
    status: true,
    email: '',
  };

  value = '';

  type = 'add';

  title = '新增部门';

  permissionListOfRole: any[] = [];

  fetching = false;

  async searchPermission(value: string) {
    console.log(value);
    try {
      const res = await this.accountService.getLeaderList({
        page: 1,
        pageSize: 1000,
        userName: value,
      });
      this.permissionOptions = res.list;
    } catch (error) {
      console.log(error);
    }
  }

  permissionOptions: any[] = [];

  async handleChange(value: any) {
    console.log(value);
    this.permissionListOfRole = value;
  }

  init(value?: any, type = 'add') {
    this.type = type;
    this.parentId = value?.parentId;
    if (type === 'add') {
      this.title = '新增部门';
    } else if (type === 'edit') {
      this.title = '编辑部门';
      this.form.parentId = value?.parentId || null;
      this.form.deptName = value?.deptName || '';
      this.form.orderNum = value?.orderNum || 0;
      this.form.leader = value?.leader || '';
      this.form.phone = value?.phone || '';
      this.form.id = value?.id || '';
      this.form.leaderId = value?.leaderId || '';
      if (value?.status === 0) {
        this.form.status = false;
      } else {
        this.form.status = true;
      }
      this.form.email = value?.email || '';
      const leader = [];
      for (const i in this.form.leader) {
        leader.push({ key: i, label: this.form.leader[i] });
      }
      this.permissionListOfRole = leader;
    } else if (type === 'next') {
      this.title = '增加下级部门';
      this.form.parentId = value?.id || null;
    }
    this.$nextTick(() => {
      this.treeData = handleTree(this.treeDataArry, 'id', 'parentId', 'children');
      this.visible = true;
    });
  }
  async handleOk() {
    this.formRef.validate((valid: boolean) => {
      if (valid) {
        this.submit();
      }
    });
  }

  async submit() {
    const leader = [];
    const leaderId = [];
    for (const i in this.permissionListOfRole) {
      leader.push(this.permissionListOfRole[i].label);
      leaderId.push(this.permissionListOfRole[i].key);
    }
    const data = {
      dept_name: this.form.deptName,
      parent_id: this.form.parentId,
      order_num: this.form.orderNum,
      leader: leader.join(','),
      id: this.form.id,
      leader_id: leaderId.join(','),
      phone: this.form.phone,
      status: this.form.status ? 1 : 0,
      email: this.form.email,
    };
    console.log(data);
    try {
      if (this.type === 'add' || this.type === 'next') {
        try {
          const res = await this.accountService.sysDeptAdd(data);
          if (res) {
            this.visible = false;
            this.handleCancel();
            this.$message.success('新增成功');
            this.$emit('refresh');
          }
        } catch (error) {
          console.log(error);
        }
      } else if (this.type === 'edit') {
        try {
          const res = await this.accountService.sysDeptEdit(data);
          if (res) {
            this.visible = false;
            this.handleCancel();
            this.$message.success('编辑成功');
            this.$emit('refresh');
          }
        } catch (error) {
          console.log(error);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
  handleCancel() {
    this.form = {
      deptName: '',
      parentId: null,
      orderNum: '',
      leader: '',
      phone: '',
      status: true,
      email: '',
    };
    this.formRef.resetFields();
    this.permissionListOfRole = [];
    this.visible = false;
  }
}
