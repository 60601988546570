
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import AuditShopList from './AuditShopList.vue';
import lazyInject from '@/di';
import AuthService from '@/services/auth.service';
import { CBatchManagement } from '@/models/operation.model';
import UserService from '@/services/user.service';
import moment from 'moment';
import ShopService from '@/services/shop.service';

@Component({
  components: {
    'audit-shop-list': AuditShopList,
  },
})
export default class AuditComposite extends Vue {
  @Ref('auditShopList')
  auditShopList!: AuditShopList;


  @lazyInject(AuthService)
  authService!: AuthService;


  @lazyInject(UserService)
  private userService!: UserService;

  @lazyInject(ShopService)
  private shopService!: ShopService;

  moment = moment;


  paginationData: any = {
    current: 1,
    pageSize: 10,
    total: 0,
    onChange: this.onChangePage,
  };

  form: {
    startTime: any;
    endTime: any;
    shopName: any;
    bossPhone: any;
    status: any;
    ka_id: any;
  } = {
      startTime: '',
      endTime: '',
      shopName: '',
      bossPhone: '',
      status: undefined,
      ka_id: undefined,
    };

  kalist: any[] = [];

  columns = [
    {
      title: '品牌',
      dataIndex: 'kaName',
      align: 'center',
    },
    {
      title: '批次编号',
      dataIndex: 'batchNo',
      align: 'center',
    },
    {
      title: '门店数量',
      dataIndex: 'shopNum',
      align: 'center',
    },
    {
      title: '提交人',
      dataIndex: 'createUserName',
      align: 'center',
    },
    {
      title: '提交时间',
      dataIndex: 'createdAt',
      align: 'center',
    },
    {
      title: '审核人',
      dataIndex: 'auditUserName',
      align: 'center',
      scopedSlots: { customRender: 'auditUserName' },
    },
    {
      title: '审核时间',
      dataIndex: 'auditTs',
      align: 'center',
      scopedSlots: { customRender: 'auditTs' },
    },
    {
      title: '状态',
      dataIndex: 'auditStatus',
      align: 'center',
      scopedSlots: { customRender: 'auditStatus' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
    },
  ];

  data = [];


  @Watch('$route')
  onQueryChange() {
    const { status, ka_id, startTime, endTime, page, pageSize } = this.$route.query;
    const params: any = {

      page: page || 1,
      pageSize: pageSize || 10,
    };
    if (status) {
      params.status = status;
      this.form.status = status;
    }
    if (ka_id) {
      params.ka_id = ka_id;
      this.form.ka_id = Number(ka_id);
    }
    if (startTime) {
      params.start_time = startTime;
      this.form.startTime = startTime;
    }
    if (endTime) {
      params.end_time = endTime;
      this.form.endTime = endTime;
    }
    this.getAuditShopList(params);
  }

  handleSearch(type = false) {
    console.log(this.paginationData);
    const { startTime, endTime, ka_id, status } = this.form;
    if (!type) {
      this.paginationData.current = 1;
      this.paginationData.pageSize = 10;
    }
    const { current, pageSize } = this.paginationData;
    const params: any = {
      page: current,
      pageSize,

    };
    if (startTime) {
      params.startTime = startTime;
    }
    if (endTime) {
      params.endTime = endTime;
    }
    if (ka_id) {
      params.ka_id = ka_id;
    }
    if (status) {
      params.status = status;
    }
    params.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query: params,
    });
  }

  handleReset() {
    console.log(this.form);
    this.form = {
      startTime: '',
      endTime: '',
      shopName: '',
      bossPhone: '',
      status: undefined,
      ka_id: undefined,
    };
    this.paginationData.current = 1;
    this.paginationData.pageSize = 10;
    this.$router.push({
      query: {},
    });
    this.handleSearch(false);
  }
  handleDetail(record: any) {
    console.log(record);
    this.auditShopList.init(record);
  }


  onChange(value: any, dateString: any) {
    console.log(dateString);
    const startTime = dateString[0];
    const endTime = dateString[1];
    this.form.startTime = startTime;
    this.form.endTime = endTime;
  }
  onChangePage(page: any, pageSize: any) {
    console.log(page, pageSize);
    this.paginationData.current = page;
    this.paginationData.pageSize = pageSize;
    this.handleSearch(true);
  }

  async getAuditShopList(params: any) {
    const res = await this.authService.getRevShopList(params);
    this.data = res.list.map((item: any) => CBatchManagement.from(item));
    this.paginationData.total = res.total;
  }

  async getKaList(val?: any) {
    const id = this.userService.currentUser()?.id;
    const response = await this.shopService.postKaUserList(id);
    if (response) {
      this.kalist = response;
      this.form.ka_id = response.find((item: any) => item.kaName === val)?.kaId;
    }
  }

  mounted() {
    this.getKaList();
    this.onQueryChange();
  }
}
