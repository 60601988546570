/*
 * @Author: Src
 * @Date: 2024-10-14 17:56:47
 * @LastEditTime: 2024-12-23 16:29:47
 * @LastEditors: Src
 * @FilePath: /work-station/src/router/questionnaireRoutes.ts
 * @Description: 头部注释配置模板
 */
import { RouteConfig } from 'vue-router';
import AuthHome from '@/views/auth/AuthHome.vue';
import ExamineQuestionnaire from '@/views/examine/ExamineQuestionnaire.vue';
import ActivityAules from '@/views/activity_aules/index.vue';
import Notice from '@/views/activity_aules/notice.vue';
import Notice1 from '@/views/activity_aules/notice_1.vue';

const authRoutes: RouteConfig = {
  name: 'examine.home',
  path: '/examine',
  component: AuthHome,
  children: [
    {
      name: 'examine.questionnaire',
      path: '/examine/questionnaire',
      component: ExamineQuestionnaire,
      meta: { title: '市场调研' },
    },
    {
      name: 'examine.activity_aules',
      path: '/examine/activity_aules',
      component: ActivityAules,
      meta: { title: '活动规则' },
    },
    {
      name: 'examine.notice',
      path: '/examine/notice',
      component: Notice,
      meta: { title: '温馨提示' },
    },
    {
      name: 'examine.notice_1',
      path: '/examine/notice_1',
      component: Notice1,
      meta: { title: '推广通运营通知' },
    },
  ],
};

export default authRoutes;
