import { render, staticRenderFns } from "./LabelDetailDrawer.vue?vue&type=template&id=42415722&scoped=true"
import script from "./LabelDetailDrawer.vue?vue&type=script&lang=ts"
export * from "./LabelDetailDrawer.vue?vue&type=script&lang=ts"
import style0 from "./LabelDetailDrawer.vue?vue&type=style&index=0&id=42415722&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "42415722",
  null
  
)

export default component.exports