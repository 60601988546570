var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-drawer',{attrs:{"title":"推送设置","visible":_vm.drawerVisible,"width":580,"body-style":{ paddingBottom: '80px' },"maskClosable":false},on:{"close":_vm.onClose}},[_c('a-form-model',{attrs:{"model":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 16 }}},[_c('a-form-model-item',{attrs:{"label":"推送方式"}},[_c('a-checkbox-group',{attrs:{"name":"checkboxgroup","value":_vm.form.pushType.map(item => {return _vm.PushChannelEnum[item]}),"options":_vm.pushTypeList},on:{"change":_vm.onPushChannelChange}})],1),_c('a-form-model-item',{attrs:{"label":"本次推送白名单"}},[_c('div',{staticClass:"push-white-list"},[_c('a-upload',{attrs:{"name":"file","accept":"application/vnd.ms-excel, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","action":`${_vm.BASE_URL}/template-spu/check-poi`,"before-upload":_vm.beforeUpload,"disabled":_vm.uploading,"headers":{ AUTHORIZATION: _vm.token || '' },"show-upload-list":true,"file-list":_vm.fileList,"remove":_vm.onRemove},on:{"change":_vm.handleUploadPOIS}},[_c('a-button',{attrs:{"type":"link","disabled":_vm.uploading || _vm.fileList.length > 0}},[_vm._v(" 点击上传门店POIID ")])],1),_c('a-button',{staticStyle:{},attrs:{"type":"link","download":"","href":`${_vm.BASE_URL}/shop/brand-shop/tmeplate-download`}},[_vm._v("下载模板")])],1)])],1),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-popconfirm',{attrs:{"title":"确定要发送吗？","ok-text":"确定","cancel-text":"取消"},on:{"confirm":_vm.submit}},[_c('a-button',{attrs:{"type":"primary","disabled":_vm.disabled}},[_vm._v("发送")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }