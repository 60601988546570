/*
 * @Author: Src
 * @Date: 2024-11-13 16:01:04
 * @LastEditTime: 2024-11-13 18:09:04
 * @LastEditors: Src
 * @FilePath: /work-station/src/router/systemRoutes.ts
 * @Description: 头部注释配置模板
 */
import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import Config from '@/views/system/Config.vue';

const systemRoutes: RouteConfig = {
  name: 'system',
  path: '/system',
  meta: {
    title: '系统管理',
  },
  redirect: {
    name: 'system.config',
  },
  component: Main,
  children: [
    {
      name: 'system.config',
      path: '/system/config',
      component: Config,
      meta: {
        title: '参数配置',
      },
    },
  ],
};

export default systemRoutes;
