/*
 * @Author: Src
 * @Date: 2024-12-17 18:40:18
 * @LastEditTime: 2024-12-25 15:42:04
 * @LastEditors: Src
 * @FilePath: /work-station/src/models/shopFieldFactory.ts
 * @Description: 头部注释配置模板
 */
import { EShopAuditField } from '@/common/enum/shop';
import { IShop } from '@/common/interface/shop';
import _ from 'lodash';


export class ShopFieldFactory {
    static init() {
        let spuFields: IShop[] = [];
        spuFields = _.cloneDeep(InitSpuFields);
        return spuFields;
    }
    static numberValidator(
        rule: { [key: string]: string | (() => boolean) },
        value: string,
    ) {
        return !isNaN(+value);
    }

    static categoryValidator(
        rule: { [key: string]: string | (() => boolean) },
        value: any,
    ) {
        return value != null && value.id !== 0;
    }
}

const InitSpuFields: IShop[] = [
    {
        labelName: '城市',
        label: EShopAuditField.city,
    },
    {
        labelName: '门店名称',
        label: EShopAuditField.shopName,
    },
    {
        labelName: '营业执照',
        label: EShopAuditField.shopLicense,
    },
    {
        labelName: '城市刊例价',
        label: EShopAuditField.showPrice,
        disabled: true,
    },
    {
        labelName: '入驻费用',
        label: EShopAuditField.entranceFee,
        disabled: true,
    },
    {
        labelName: '开业时间',
        label: EShopAuditField.businessYear,
    },
    {
        labelName: '营业时间',
        label: EShopAuditField.businessHours,
    },
    {
        labelName: '门店收款支付宝实名',
        label: EShopAuditField.alipayName,
    },
    {
        labelName: '门店收款支付宝账号',
        label: EShopAuditField.alipayAccount,
    },
    {
        labelName: '门店店长手机号',
        label: EShopAuditField.shopownerPhone,
    },
    {
        labelName: '平台联系电话',
        label: EShopAuditField.platformPhone,
    },
    {
        labelName: '高德地图poi',
        label: EShopAuditField.poi,
    },
    {
        labelName: '老板电话',
        label: EShopAuditField.bossPhone,
    },
    {
        labelName: '收银员手机号',
        label: EShopAuditField.cashierPhone,
    },
    {
        labelName: '身份证国徽面',
        label: EShopAuditField.nationalEmblemPhotos,
    },
    {
        labelName: '身份证人像面',
        label: EShopAuditField.portraitPhotos,
    },
    {
        labelName: '赠送天数',
        label: EShopAuditField.sendTime,
    },
    // {
    //     labelName: '审核状态',
    //     label: EShopAuditField.auditStatus,
    // },
];
