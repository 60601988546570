
import { Vue, Component } from 'vue-property-decorator';
import SPUService from '@/services/spu.service';
import lazyInject from '@/di';
import { ISPUCategory } from '@/common/interface/spu';

@Component
export default class ClassificationList extends Vue {
  @lazyInject(SPUService)
  protected spuService!: SPUService;

  dataSource: any = [];

  visible = false;
  form: any = {
    onSiteService: undefined,
    id: '',
  };

  allSpuCategory: ISPUCategory[] = [];
  secondCategoryList: ISPUCategory[] = [];

  columns = [
    {
      title: '类目',
      dataIndex: 'name',
      // align: 'center',
      width: 200,
    },
    {
      title: '属性',
      dataIndex: 'onsite_service',
      scopedSlots: { customRender: 'onsite_service' },
      align: 'center',
    },
    {
      title: '操作',
      dataIndex: 'actions',
      scopedSlots: { customRender: 'actions' },
      align: 'center',
      width: 200,

    },
  ];


  showModal(val: any) {
    this.form.id = val.id;
    this.form.onSiteService = val.onsite_service ? '1' : '0';
    this.visible = true;
  }

  async handleOk() {
    try {
      const res = await this.spuService.editOnSiteService(this.form);
      if (res) {
        this.visible = false;
        this.$message.success('修改成功');
        this.getList();
        this.form.onSiteService = undefined;
        this.form.id = '';
      }
    } catch (error) {
      return error;
    }
  }

  async getList() {
    const res = await this.spuService.getShopDetailItemListBySpuId({
      aggregation: true,
    });
    this.dataSource = res;
  }
  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      loadingEl: this.$el,
    });

    this.allSpuCategory = data;

    this.secondCategoryList = data.reduce((prev, cur) => {
      return prev.concat(cur.children || []);
    }, [] as ISPUCategory[]);
  }
  mounted() {
    this.getCategory();
    this.getList();
  }
}
