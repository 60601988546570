
import { Vue, Component } from 'vue-property-decorator';
import { IShop } from '@/common/interface/shop';
import { ShopFieldFactory } from '@/models/shopFieldFactory';
import lazyInject from '@/di';
import OperationService from '@/services/operation.service';
import { EShopAuditField } from '@/common/enum/shop';
import uploudFile from './components/uploudFile.vue';
import { component as Viewer } from 'v-viewer';
import {
  AuthEnum,
} from '@/services/permission.service';


@Component({
  components: {
    'uploud-file': uploudFile,
    Viewer,
  },
})

export default class StoreDetail extends Vue {
  shopFields: IShop[] = ShopFieldFactory.init();

  EShopAuditField = EShopAuditField;

  AuthEnum = AuthEnum;

  @lazyInject(OperationService)
  operationService!: OperationService;


  distype = false;


  form: any = {
    shop_name: '3',
    shop_address: '3',
    shop_phone: '3',
    shop_contact: '3',
    shop_contact_phone: '3',
    shop_contact_email: '3',
  };
  async onFormChange() {
    try {
      const res = await this.operationService.getShopDetail(this.$route.query.id);
      this.form = res;
    } catch (e) {
      console.warn(e);
    }
  }
  async onSubmit() {
    const { show_price, entrance_fee } = this.form;
    const params = {
      show_price,
      entrance_fee,
    };
    const data = await this.operationService.shopEdit(this.$route.query.id, params);
    if (data) {
      const res = await this.operationService.shopAudit(this.$route.query.id) as any;
      if (res) {
        this.$router.push({ name: 'operation.storeOperation' });
      }
    }
  }

  getDisabled(value: any) {
    if (this.distype) {
      return false;
    } else {
      return !value;
    }
  }
  handleChange(value: any, fieldLabel: any) {
    console.log(value, fieldLabel);
    this.form[fieldLabel] = value.id;
  }
  async onDelShop() {
    try {
      const res = await this.operationService.delShop(this.$route.query.id);
      if (res) {
        this.$router.push({ name: 'operation.storeOperation' });
      }
    } catch (e) {
      console.warn(e);
    }
  }
  changePrice() {
    this.distype = true;
  }
  toPrice() {
    this.distype = false;
  }
  async savePrice() {
    const { shop_name, shop_license_url_id, business_year, business_hours, alipay_account, alipay_name, platform_phone, cashier_phone, boss_phone, shopowner_phone, show_price, entrance_fee, send_time, portrait_photos_url_id, national_emblem_photos_url_id } = this.form;

    const params: any = {};
    if (show_price) {
      params.show_price = show_price;
    }
    if (entrance_fee) {
      params.entrance_fee = entrance_fee;
    }
    if (send_time) {
      params.send_time = send_time;
    }
    if (portrait_photos_url_id) {
      params.portrait_photos_url_id = portrait_photos_url_id;
    }
    if (national_emblem_photos_url_id) {
      params.national_emblem_photos_url_id = national_emblem_photos_url_id;
    }
    if (shop_name) {
      params.shop_name = shop_name;
    }
    if (shop_license_url_id) {
      params.shop_license_url_id = shop_license_url_id;
    }
    if (business_year) {
      params.business_year = business_year;
    }
    if (business_hours) {
      params.business_hours = business_hours;
    }
    if (alipay_account) {
      params.alipay_account = alipay_account;
    }
    if (alipay_name) {
      params.alipay_name = alipay_name;
    }
    if (platform_phone.indexOf('*') === -1) {
      params.platform_phone = platform_phone;
    }
    if (cashier_phone.indexOf('*') === -1) {
      params.cashier_phone = cashier_phone;
    }
    if (boss_phone.indexOf('*') === -1) {
      params.boss_phone = boss_phone;
    }
    if (shopowner_phone.indexOf('*') === -1) {
      params.shopowner_phone = shopowner_phone;
    }
    const res = await this.operationService.shopEdit(this.$route.query.id, params);
    if (res) {
      this.$router.push({ name: 'operation.storeOperation' });
    }
  }

  mounted() {
    this.onFormChange();
  }
}
