
import { Vue, Component, Ref } from 'vue-property-decorator';
import { IShop } from '@/common/interface/shop';
import { ShopFieldFactory } from '@/models/shopFieldFactory';
import lazyInject from '@/di';
import OperationService from '@/services/operation.service';
import { EShopAuditField } from '@/common/enum/shop';
import { component as Viewer } from 'v-viewer';
import LookPhone from '@/components/LookPhone.vue';
import {
  AuthEnum,
} from '@/services/permission.service';

@Component({
  components: {
    Viewer,
    'look-phone': LookPhone,
  },
})
export default class LookShopDetail extends Vue {
  AuthEnum = AuthEnum;
  visible = false;
  record: any;
  shopFields: IShop[] = ShopFieldFactory.init();
  EShopAuditField = EShopAuditField;

  @lazyInject(OperationService)
  private operationService!: OperationService;

  @Ref('lookPhone')
  private lookPhone!: LookPhone;

  form: any = {};
  init(record: any) {
    this.visible = true;
    console.log(record.city);
    this.funShopDetail(record.id);
    this.record = record;
  }
  async funShopDetail(id: any) {
    const res = await this.operationService.getShopDetail(id);
    this.form = res;
  }
  getDisabled() {
    return true;
  }
  close() {
    this.visible = false;
    this.form = {};
  }

  save() {
    this.visible = false;
    this.form = {};
  }
  LookShopDetail(title: string, value: string) {
    console.log(title, value);
    let _d = '';
    let _t = '';
    if (title === EShopAuditField.bossPhone) {
      _d = this.form.encrypt_boss_phone;
      _t = '门店入驻-老板电话';
    }
    if (title === EShopAuditField.shopownerPhone) {
      _d = this.form.encrypt_shopowner_phone;
      _t = '门店入驻-店长电话';
    }
    if (title === EShopAuditField.platformPhone) {
      _d = this.form.encrypt_platform_phone;
      _t = '门店入驻-平台电话';
    }
    if (title === EShopAuditField.cashierPhone) {
      _d = this.form.encrypt_cashier_phone;
      _t = '门店入驻-收银员电话';
    }
    this.lookPhone.init(_t, _d);
  }

  permissionLook(label: string) {
    if (label === EShopAuditField.bossPhone && this.form.encrypt_boss_phone) {
      return true;
    }
    if (label === EShopAuditField.shopownerPhone && this.form.encrypt_shopowner_phone) {
      return true;
    }
    if (label === EShopAuditField.platformPhone && this.form.encrypt_platform_phone) {
      return true;
    }
    if (label === EShopAuditField.cashierPhone && this.form.encrypt_cashier_phone) {
      return true;
    }
    return false;
  }
}
