import { render, staticRenderFns } from "./DepartmentModal.vue?vue&type=template&id=5ebdedbe&scoped=true"
import script from "./DepartmentModal.vue?vue&type=script&lang=ts"
export * from "./DepartmentModal.vue?vue&type=script&lang=ts"
import style0 from "./DepartmentModal.vue?vue&type=style&index=0&id=5ebdedbe&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ebdedbe",
  null
  
)

export default component.exports