
import { Vue, Component } from 'vue-property-decorator';
import lazyInject from '@/di';

import UTILService from '@/services/utils.service';

@Component({})
export default class LookPhone extends Vue {
  @lazyInject(UTILService)
  private utilService!: UTILService;

  visible = false;

  phone: any = null;

  hideModal() {
    this.visible = false;
    this.phone = null;
  }

  async init(type: string, val: any, poi?: string) {
    this.visible = true;
    const data = {
      encrypt_phone: val,
      operate_type: type,
      poi,
    };
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.visible = true;
        this.phone = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }
}
