var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('a-drawer',{attrs:{"title":"编辑商品自动下架时间","width":650,"visible":_vm.drawerVisible,"body-style":{ paddingBottom: '80px' },"maskClosable":false},on:{"close":_vm.onClose}},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.form,"label-col":{ span: 4 },"wrapper-col":{ span: 18 },"rules":_vm.rules,"has-feedback":""}},[_c('a-form-model-item',{attrs:{"label":"自动下架"}},[_c('a-switch',{model:{value:(_vm.form.shouldOffShelf),callback:function ($$v) {_vm.$set(_vm.form, "shouldOffShelf", $$v)},expression:"form.shouldOffShelf"}})],1),(_vm.form.shouldOffShelf)?[_c('a-form-model-item',{attrs:{"label":"自动下架时间","name":"autoOffShelfTime","prop":"autoOffShelfTime"}},[_c('a-date-picker',{staticStyle:{"width":"100%"},attrs:{"show-time":"","placeholder":"请选择自动下架时间：年月日时分，且至少设置在10分钟以后","default-value":_vm.form.autoOffShelfTime !== ''
              ? _vm.moment(_vm.form.autoOffShelfTime, 'YYYY-MM-DD HH:mm')
              : null,"format":'YYYY-MM-DD HH:mm'},on:{"change":_vm.onChangeAutoOffShelfTime}})],1)]:_vm._e()],2),_c('div',{style:({
      position: 'absolute',
      right: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      zIndex: 1,
    })},[_c('a-button',{style:({ marginRight: '8px' }),on:{"click":_vm.onClose}},[_vm._v(" 取消 ")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.onOk}},[_vm._v(" 确定 ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }