
import { Vue, Component, Ref, Prop } from 'vue-property-decorator';
import LookShopDetail from './LookShopDetail.vue';
import lazyInject from '@/di';
import OperationService from '@/services/operation.service';
import { CShop } from '@/models/operation.model';
import { City } from '@/common/interface/shop';
import UTILService from '@/services/utils.service';
import {
  AuthEnum,
} from '@/services/permission.service';

@Component({
  components: {
    'look-shop-detail': LookShopDetail,
  },
})
export default class AddShopDrawer extends Vue {
  AuthEnum = AuthEnum;
  @lazyInject(OperationService)
  private operationService!: OperationService;

  @Ref('lookShopDetail')
  lookShopDetail!: LookShopDetail;

  @lazyInject(UTILService)
  private utilService!: UTILService;

  @Prop()
  citys!: City[];

  type = '';

  title = '';

  visible = false;
  form = {
    city_id: undefined,
    poi: '',
    shop_name: '',
    phone: '',
  };
  columns = [
    {
      title: '品牌名称',
      dataIndex: 'kaName',
      key: 'kaName',
      align: 'center',
      width: 100,
    },
    {
      title: '城市',
      dataIndex: 'city',
      key: 'city',
      align: 'center',
      width: 100,
    },
    {
      title: '门店名称',
      dataIndex: 'shopName',
      key: 'shop_name',
      align: 'center',
      width: 120,
    },
    {
      title: '高德poi',
      dataIndex: 'poi',
      key: 'poi',
      align: 'center',
      width: 100,
    },
    {
      title: '老板手机号',
      dataIndex: 'bossPhone',
      key: 'bossPhone',
      align: 'center',
      width: 200,
      scopedSlots: { customRender: 'bossPhone' },
    },
    {
      title: '注册过黑卡门店',
      dataIndex: 'entered',
      key: 'entered',
      align: 'center',
      width: 100,
      scopedSlots: { customRender: 'entered' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 100,
      scopedSlots: { customRender: 'action' },
    },
  ];
  encryptAccount = '';
  data = [];
  kaId = 0;
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    onChange: this.onChangePage,
  };
  batchNo = '';
  selectedRowKeys: any[] = [];
  selectedData: any[] = [];
  init(record: any, type: string) {
    const params = {
      batchNo: record.batchNo,
      page: 1,
      pageSize: 10,
    };
    this.selectedRowKeys = [];
    this.selectedData = [];
    this.data = [];
    this.batchNo = record.batchNo;
    this.kaId = record.kaId;
    this.visible = true;
    this.type = type;
    const query = {
      page: 1,
      ka_id: this.kaId,
      pageSize: 10,
    };
    if (type === 'add') {
      this.title = '添加门店';
      this.addShopList(query);
    } else if (type === 'delete') {
      this.title = '移除门店';
      this.getShopList(params);
    }
  }
  async LookShopDetail(title: string, encryptBossPhone: string, poi: string) {
    const data = {
      encrypt_phone: encryptBossPhone,
      operate_type: title,
      poi,
    };
    console.log(data);
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.encryptAccount = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }
  async getShopList(value: any) {
    try {
      const res = await this.operationService.getBatchShops(this.batchNo, value);
      this.data = res.list.map((item: any) => CShop.from(item));
      this.pagination.total = res.total;
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  }
  async addShopList(params: any) {
    try {
      params.status = 1;
      params.ka_id = this.kaId;
      params.enter_batch = 1;
      const res = await this.operationService.getShopList(params) as any;
      this.data = res.list.map((item: any) => CShop.from(item));
      console.log(res);
    } catch (error) {
      console.error(error);
    }
  }
  onChangePage(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    this.search();
  }
  search() {
    const { city_id, poi, shop_name, phone } = this.form;
    const { current, pageSize } = this.pagination;
    const params: any = {
      page: current || 1,
      pageSize: pageSize || 10,
    };
    if (city_id) {
      params.city_id = city_id;
    }
    if (poi) {
      params.poi = poi;
    }
    if (shop_name) {
      params.shop_name = shop_name;
    }
    if (phone) {
      params.phone = phone;
    }
    if (this.type === 'add') {
      this.addShopList(params);
    } else if (this.type === 'delete') {
      this.getShopList(params);
    }
  }
  reset() {
    this.form = {
      city_id: undefined,
      poi: '',
      shop_name: '',
      phone: '',
    };
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.search();
  }
  onChangeData() { }

  handleCancel() {
    this.visible = false;
    this.selectedRowKeys = [];
    this.selectedData = [];
    this.data = [];
  }
  /**
      * 单选
      * @param record
      * @param selected
      */
  onSelect(record: any, selected: any) {
    if (selected) {
      this.selectedRowKeys.push(record.id);
      this.selectedData.push(record);
    } else {
      const index = this.selectedRowKeys.indexOf(record.id);
      if (index > -1) {
        this.selectedRowKeys.splice(index, 1);
      }
      this.selectedData = this.selectedData.filter((item: any) => item.id !== record.id);
    }
  }

  /**
   * 全选
   * @param selected
   * @param selectedRows
   * @param changeRows
   */
  onSelectAll(selected: any, selectedRows: any, changeRows: any) {
    if (selected) {
      changeRows.forEach((row: any) => {
        this.selectedRowKeys.push(row.id);
        this.selectedData.push(row);
      });
    } else {
      changeRows.forEach((row: any) => {
        const index = this.selectedRowKeys.indexOf(row.id);
        if (index > -1) {
          this.selectedRowKeys.splice(index, 1);
        }
        this.selectedData = this.selectedData.filter((item: any) => item.id !== row.id);
      });
    }
  }

  async handleOk() {
    if (this.type === 'add') {
      try {
        if (this.selectedRowKeys.length === 0) {
          this.$message.error('请选择门店');
          return;
        }
        const res = await this.operationService.joinBatch(this.batchNo, { shop_ids: this.selectedRowKeys });
        if (res) {
          this.$message.success('加入批次成功');
          this.visible = false;
          this.$emit('refresh');
        }
      } catch (error) {
        console.error(error);
      }
    } else if (this.type === 'delete') {
      console.log(this.selectedRowKeys);
      try {
        if (this.selectedRowKeys.length === 0) {
          this.$message.error('请选择门店');
          return;
        }
        const res = await this.operationService.delFromBatch(this.batchNo, { shop_ids: this.selectedRowKeys });
        if (res) {
          this.$message.success('移除批次成功');
          this.visible = false;
          this.$emit('refresh');
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  handleView(record: any) {
    console.log(record);
    this.lookShopDetail.init(record);
  }
}
