
import lazyInject from '@/di';
import { Vue, Component, Prop } from 'vue-property-decorator';
import SystemService from '@/services/system.service';
import WithdrawService from '@/services/withdraw.service';

@Component({})
export default class ExamineModal extends Vue {
  @lazyInject(SystemService)
  protected systemService!: SystemService;

  @lazyInject(WithdrawService)
  protected withdrawService!: WithdrawService;

  visible = false;
  inVisible = false;
  form = {
    reason: '',
  };
  rules = {
  };
  @Prop()
  withdrawAccount!: string;

  @Prop()
  withdrawalAccountName!: string;

  open() {
    this.visible = true;
  }
  inOpen() {
    this.inVisible = true;
  }
  async handleOk() {
    const { id } = this.$route.params;
    const params = {
      wid: id,
      status: 1,
    };
    const res = await this.withdrawService.verifyWithdrawalOrder(params);
    if (res) {
      this.visible = false;
      this.$emit('refresh');
    }
  }
  handleCancel() {
    this.inVisible = true;
  }
  cancel() {
    this.visible = false;
  }
  async inHandleOk() {
    const { id } = this.$route.params;
    const params = {
      wid: id,
      status: 2,
      remark: this.form.reason,
    };
    const res = await this.withdrawService.verifyWithdrawalOrder(params);
    if (res) {
      this.inVisible = false;
      this.visible = false;
      this.$emit('refresh');
    }
  }
  inCancel() {
    this.inVisible = false;
  }
}
