
import { ISPUProduct } from '@/common/interface/spu';
import {
  Vue,
  Component,
  Watch,
  Prop,
  Model,
  Emit,
} from 'vue-property-decorator';

@Component({})
export default class ProductsField extends Vue {
  val: ISPUProduct[] = [];

  isVisible = false;

  productItem: {
    name: string;
    num: string | number;
    originalPriceYuan: string | number;
  } = {
    name: '',
    num: '',
    originalPriceYuan: '',
  };

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  refs!: any;

  @Model()
  value!: ISPUProduct[];

  @Watch('value')
  watchValue() {
    if (this.value && this.value.length) {
      this.val = [...this.value];
    } else {
      this.val = [];
    }
  }

  @Emit('update')
  updateVal() {
    return this.val;
  }

  get showProducts() {
    if (this.val.length) {
      return true;
    }
    return false;
  }

  editDetail(add: boolean) {
    if (add) {
      const { num, originalPriceYuan, name } = this.productItem;
      if (!num || !originalPriceYuan || !name) {
        this.$message.error('请填写完整!');
        return;
      }
      if (isNaN(+num) || isNaN(+originalPriceYuan)) {
        this.$message.error('服务次数及服务价格请输入数字!');
        return;
      }
      if (!Number.isInteger(+num)) {
        this.$message.error('服务次数请输入整数!');
        return;
      }
      const _num = Number(originalPriceYuan) / Number(num);
      // console.log(_num, originalPriceYuan, num, 'num');
      // num不能超过100  _num能低于0.01
      // _num大于0.01 小于1  num不能大于5
      if (Number(num) > 10) {
        this.$message.error('服务次数不能超过10次!');
        return;
      }
      if (_num < 0.01) {
        this.$message.error('单次价格不能低于0.01元!');
        return;
      }
      if (_num > 0.01 && _num < 1) {
        if (Number(num) > 5) {
          this.$message.error('服务次数不能大于5次!');
          return;
        }
      }
      this.val.push({
        num: Number(num),
        originalPriceCent: Number(originalPriceYuan) * 100,
        name,
      });
      this.updateVal();
    } else {
      this.isVisible = false;
    }

    this.productItem = {
      name: '',
      num: '',
      originalPriceYuan: '',
    };
  }

  delDetail(idx: number) {
    const list = [...this.val];
    list.splice(idx, 1);
    this.val = list;
    this.updateVal();
  }

  mounted() {
    this.watchValue();
  }
}
