
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import AddBatchModal from './components/AddBatchModal.vue';
import lazyInject from '@/di';
import OperationService from '@/services/operation.service';
import { CShop } from '@/models/operation.model';
import { City } from '@/common/interface';
import BaseService from '@/services/base.service';
import LookShopDetail from './components/LookShopDetail.vue';
import UTILService from '@/services/utils.service';
import {
  AuthEnum,
} from '@/services/permission.service';

@Component({
  components: {
    'add-batch-modal': AddBatchModal,
    'look-shop-detail': LookShopDetail,
  },
})
export default class StoreOperation extends Vue {
  @lazyInject(OperationService)
  operationService!: OperationService;

  AuthEnum = AuthEnum;

  @Ref('addBatchModal')
  addBatchModal!: AddBatchModal;

  @Ref('lookShopDetail')
  lookShopDetail!: LookShopDetail;

  @lazyInject(UTILService)
  private utilService!: UTILService;

  @lazyInject(BaseService)
  baseService!: BaseService;

  shopList: CShop[] = [];

  citys: City[] = [];

  encryptAccount = '';

  form: {
    regionId: any;
    poi: any;
    shopName: any;
    bossPhone: any;
    reviewStatus: any;
  } = {
      regionId: undefined,
      poi: '',
      shopName: '',
      bossPhone: '',
      reviewStatus: undefined,
    };

  selectedRowKeys: any[] = [];

  selectedData: any[] = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  columns = [
    {
      title: '门店名称',
      dataIndex: 'shopName',
      align: 'center',
      width: 200,
    },
    // {
    //   title: 'id',
    //   dataIndex: 'id',
    //   align: 'center',
    // },
    {
      title: '品牌',
      dataIndex: 'kaName',
      align: 'center',
      width: 100,
    },
    {
      title: '城市',
      dataIndex: 'city',
      align: 'center',
      width: 100,
    },
    {
      title: '高德poi',
      dataIndex: 'poi',
      align: 'center',
      width: 140,
    },
    {
      title: '门店老板手机号',
      dataIndex: 'bossPhone',
      align: 'center',
      width: 250,
      scopedSlots: { customRender: 'bossPhone' },
    },
    {
      title: '注册过黑卡门店',
      dataIndex: 'entered',
      align: 'center',
      width: 100,
      scopedSlots: { customRender: 'entered' },
    },
    {
      title: '刊例价',
      dataIndex: 'showPrice',
      align: 'center',
      width: 160,
      scopedSlots: { customRender: 'showPrice' },
    },
    {
      title: '实际入驻金额',
      dataIndex: 'entranceFee',
      align: 'center',
      width: 160,
      scopedSlots: { customRender: 'entranceFee' },
    },
    {
      title: '入驻批次号',
      dataIndex: 'batchNo',
      align: 'center',
      width: 120,
    },
    {
      title: '提交人',
      dataIndex: 'operatorName',
      align: 'center',
      width: 100,
    },
    {
      title: '审核状态',
      dataIndex: 'reviewStatus',
      align: 'center',
      width: 100,
      scopedSlots: { customRender: 'reviewStatus' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
      width: 200,
    },
  ];

  data = [];

  @Watch('$route')
  onFormChange() {
    console.log('query');
    const { regionId, poi, shopName, bossPhone, reviewStatus, page, pageSize } = this.$route.query;
    const params: any = {
      page: page ? Number(page) : 1,
      pageSize: pageSize ? Number(pageSize) : 10,
    };
    if (regionId) {
      this.form.regionId = Number(regionId);
      params.city_id = regionId;
    }
    if (poi) {
      this.form.poi = poi;
      params.poi = poi;
    }
    if (shopName) {
      this.form.shopName = shopName;
      params.shop_name = shopName;
    }
    if (bossPhone) {
      this.form.bossPhone = bossPhone;
      params.mobile = bossPhone;
    }
    if (reviewStatus) {
      this.form.reviewStatus = reviewStatus;
      params.status = reviewStatus;
    }
    this.getList(params);
  }
  cancel() {
    console.log('cancel');
  }

  async LookShopDetail(title: string, value: string, poi: string) {
    const data = {
      encrypt_phone: value,
      operate_type: title,
      poi,
    };
    console.log(data);
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.encryptAccount = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }

  paginationChange(page: any, pageSize: any) {
    console.log('paginationChange', page, pageSize);
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    this.search(true);
  }

  funDisabled(record: any) {
    if (record.reviewStatus == 1) {
      if (record.batchNo == '-') {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

  search(type = false) {
    const { regionId, poi, shopName, bossPhone, reviewStatus } = this.form;
    if (!type) {
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
    }
    const { current, pageSize } = this.pagination;
    const query: any = {
      page: current,
      pageSize,
    };
    if (regionId) {
      query.regionId = regionId;
    }
    if (poi) {
      query.poi = poi;
    }
    if (shopName) {
      query.shopName = shopName;
    }
    if (bossPhone) {
      query.bossPhone = bossPhone;
    }
    if (reviewStatus) {
      query.reviewStatus = reviewStatus;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.baseService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }
  clearSelected() {
    this.selectedRowKeys = [];
    this.selectedData = [];
    this.search(false);
  }
  reset() {
    this.form = {
      regionId: undefined,
      poi: '',
      shopName: '',
      bossPhone: '',
      reviewStatus: undefined,
    };
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.search(false);
  }

  async getList(params: any) {
    console.log('getList', params);
    try {
      const res = await this.operationService.getShopList(params) as any;
      this.shopList = res.list.map((item: any) => CShop.from(item));
      this.data = res.list;
      this.pagination.total = res.total;
    } catch (error) {
      console.log('error', error);
    }
  }

  refresh() {
    console.log('refresh');
    this.search(false);
  }

  /**
     * 单选
     * @param record
     * @param selected
     */
  onSelect(record: any, selected: any) {
    if (selected) {
      this.selectedRowKeys.push(record.id);
      this.selectedData.push(record);
    } else {
      const index = this.selectedRowKeys.indexOf(record.id);
      if (index > -1) {
        this.selectedRowKeys.splice(index, 1);
      }
      this.selectedData = this.selectedData.filter((item: any) => item.id !== record.id);
    }
  }

  /**
   * 全选
   * @param selected
   * @param selectedRows
   * @param changeRows
   */
  onSelectAll(selected: any, selectedRows: any, changeRows: any) {
    if (selected) {
      changeRows.forEach((row: any) => {
        this.selectedRowKeys.push(row.id);
        this.selectedData.push(row);
      });
    } else {
      changeRows.forEach((row: any) => {
        const index = this.selectedRowKeys.indexOf(row.id);
        if (index > -1) {
          this.selectedRowKeys.splice(index, 1);
        }
        this.selectedData = this.selectedData.filter((item: any) => item.id !== row.id);
      });
    }
  }

  onChangeData() { }

  handleAudit(record: any) {
    console.log('handleAudit', record);
    this.$router.push({
      name: 'operation.storeDetail',
      query: {
        id: record.id,
      },
    });
  }

  async handleDelete(record: any) {
    try {
      const res = await this.operationService.delShop(record.id);
      if (res) {
        this.refresh();
      }
    } catch (e) {
      console.warn(e);
    }
  }

  handleBatch(record: any) {
    console.log('handleBatch', record);
    const ids = [record.id];
    const { kaId } = record;
    this.addBatchModal.init(ids, kaId);
  }

  addBatch() {
    console.log('addBatch');
    if (this.selectedRowKeys.length === 0) {
      this.$message.error('请选择门店');
    } else {
      const ids = this.selectedRowKeys;
      let kaId: any[] = [];
      this.selectedData.forEach((item: any) => {
        kaId.push(item.kaId);
      });
      kaId = [...new Set(kaId)];
      console.log(kaId, this.selectedData);
      if (kaId.length > 1) {
        this.$message.error('请选择同一个品牌的门店');
      } else {
        this.addBatchModal.init(ids, kaId[0]);
      }
    }
  }

  handleView(record: any) {
    console.log('handleView', record);
    this.lookShopDetail.init(record);
  }

  mounted() {
    this.onFormChange();
    this.getCityList();
  }
}
