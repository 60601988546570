import { render, staticRenderFns } from "./OnSiteService.vue?vue&type=template&id=7127942b"
import script from "./OnSiteService.vue?vue&type=script&lang=ts"
export * from "./OnSiteService.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports