
import { Vue, Component, Ref } from 'vue-property-decorator';
import { IShop } from '@/common/interface/shop';
import { ShopFieldFactory } from '@/models/shopFieldFactory';
import { EShopAuditField } from '@/common/enum/shop';
import lazyInject from '@/di';
import OperationService from '@/services/operation.service';
import { component as Viewer } from 'v-viewer';
import uploudFile from '@/views/operation/components/uploudFile.vue';
import LookPhone from '@/components/LookPhone.vue';
import {
  AuthEnum,
} from '@/services/permission.service';

@Component({
  components: {
    Viewer,
    'uploud-file': uploudFile,
    'look-phone': LookPhone,
  },
})
export default class LookShopDetail extends Vue {
  visible = false;

  AuthEnum = AuthEnum;
  @lazyInject(OperationService)
  operationService!: OperationService;
  @Ref('lookPhone')
  lookPhone!: LookPhone;

  record: any;
  shopFields: IShop[] = ShopFieldFactory.init();
  type = '';
  EShopAuditField = EShopAuditField;

  form: any = {};
  init(record: any, type: string) {
    this.visible = true;
    this.form = record;
    this.type = type;
  }
  getDisabled() {
    if (this.type === 'view') {
      return true;
    }
    return false;
  }
  close() {
    this.visible = false;
  }

  save() {
    this.visible = false;
  }

  async submit() {
    const { shop_name, shop_license_url_id, business_year, business_hours, alipay_account, alipay_name, platform_phone, cashier_phone, boss_phone, shopowner_phone, show_price, entrance_fee, send_time, portrait_photos_url_id, national_emblem_photos_url_id } = this.form;

    const params: any = {};
    if (show_price) {
      params.show_price = show_price;
    }
    if (entrance_fee) {
      params.entrance_fee = entrance_fee;
    }
    if (send_time) {
      params.send_time = send_time;
    }
    if (portrait_photos_url_id) {
      params.portrait_photos_url_id = portrait_photos_url_id;
    }
    if (national_emblem_photos_url_id) {
      params.national_emblem_photos_url_id = national_emblem_photos_url_id;
    }
    if (shop_name) {
      params.shop_name = shop_name;
    }
    if (shop_license_url_id) {
      params.shop_license_url_id = shop_license_url_id;
    }
    if (business_year) {
      params.business_year = business_year;
    }
    if (business_hours) {
      params.business_hours = business_hours;
    }
    if (alipay_account) {
      params.alipay_account = alipay_account;
    }
    if (alipay_name) {
      params.alipay_name = alipay_name;
    }
    if (platform_phone.indexOf('*') === -1) {
      params.platform_phone = platform_phone;
    }
    if (cashier_phone.indexOf('*') === -1) {
      params.cashier_phone = cashier_phone;
    }
    if (boss_phone.indexOf('*') === -1) {
      params.boss_phone = boss_phone;
    }
    if (shopowner_phone.indexOf('*') === -1) {
      params.shopowner_phone = shopowner_phone;
    }
    try {
      const res = await this.operationService.revEdit(this.form.id, params);
      if (res) {
        this.$emit('refresh');
        this.visible = false;
      }
    } catch (error) {
      console.error(error);
    }
  }
  handleChange(value: any, fieldLabel: any) {
    console.log(value, fieldLabel);
    this.form[fieldLabel] = value.id;
  }
  LookShopDetail(title: string, value: string) {
    console.log(title, value);
    let _d = '';
    let _t = '';
    if (title === EShopAuditField.bossPhone) {
      _d = this.form.encrypt_boss_phone;
      _t = '运营批次审核-老板电话';
    }
    if (title === EShopAuditField.shopownerPhone) {
      _d = this.form.encrypt_shopowner_phone;
      _t = '运营批次审核-店长电话';
    }
    if (title === EShopAuditField.platformPhone) {
      _d = this.form.encrypt_platform_phone;
      _t = '运营批次审核-平台电话';
    }
    if (title === EShopAuditField.cashierPhone) {
      _d = this.form.encrypt_cashier_phone;
      _t = '运营批次审核-收银员电话';
    }
    this.lookPhone.init(_t, _d, this.form.poi);
  }

  permissionLook(label: string) {
    if (label === EShopAuditField.bossPhone && this.form.encrypt_boss_phone) {
      return true;
    }
    if (label === EShopAuditField.shopownerPhone && this.form.encrypt_shopowner_phone) {
      return true;
    }
    if (label === EShopAuditField.platformPhone && this.form.encrypt_platform_phone) {
      return true;
    }
    if (label === EShopAuditField.cashierPhone && this.form.encrypt_cashier_phone) {
      return true;
    }
    return false;
  }
}
