/*
 * @Author: Src
 * @Date: 2024-07-16 14:41:02
 * @LastEditTime: 2024-11-18 19:34:03
 * @LastEditors: Src
 * @FilePath: /work-station/src/common/enum/withdraw.ts
 * @Description: 头部注释配置模板
 */
/** 提现状态枚举 */
export enum WithdrawStatusEnum {
  全部 = -1,
  待处理 = 0,
  操作中 = 1,
  已完成 = 2,
  '' = 3, // 暂无
  已撤销 = 4,
}

/** 提现审核状态 */
export enum WithdrawExamineStatus {
  待审核 = 0,
  待转账 = 1,
  审核未通过 = 2,
  转账失败 = 3,
  提现成功 = 4,
  转账异常 = 5,
}
