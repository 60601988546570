/*
 * @Author: Src
 * @Date: 2024-10-10 15:39:20
 * @LastEditTime: 2024-11-13 09:43:55
 * @LastEditors: Src
 * @FilePath: /work-station/src/services/brand_service.ts
 * @Description: 头部注释配置模板
 */
import { inject, injectable } from 'inversify';
import HttpClient from './http.service';

@injectable()
export class BrandService {
  @inject(HttpClient)
  protected http!: HttpClient;

  /** 品牌列表 */
  async list(
    params: {
      pageSize?: number;
      page: number;
    },
    loadingEl?: Element | Vue,
  ) {
    try {
      const res = (await this.http.get('shop/brand/list', {
        params,
        data: { loadingEl },
      })) as {
        lastPage: number;
        page: number;
        pageSize: string;
        total: number;
        list: Array<{
          brand_code: string;
          create_time: string;
          header_pic: string;
          is_auto_payment: number;
          logo: string;
          name: string;
          owner_id: string;
          protection_rules: string;
          shop_count: number;
          staff_id: number;
        }>;
      };

      return res;
    } catch (error) {
      throw error;
    }
  }

  /** 品牌新增 */
  async create(
    params: {
      name: string;
      owner_id: string;
      staff_id: string;
      brand_code: string;
      header_pic: number;
      webhook_url: string;
      logo: string;
      withdrawal_account_name: string;
      withdrawal_account: string;
    },
    loadingEl?: Element | Vue,
  ) {
    const url = 'shop/brand/new-create';
    try {
      const res = (await this.http.post(url, {
        ...params,
        data: { loadingEl },
      })) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }


  /** 品牌详情 */
  async brandDetail(ka_id: string) {
    const url = `shop/brand/${ka_id}/detail`;
    try {
      const res = (await this.http.get(url)) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }

  /** 品牌修改 */
  async updateBrand(
    params: any,
  ) {
    const url = 'shop/brand/edit/info';
    console.log(params);
    try {
      const res = (await this.http.post(url, params)) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }

  /** 品牌赠送天数 */
  async brandGiftDays(ka_id: string) {
    const url = `shop/query/${ka_id}/gift_days`;
    try {
      const res = (await this.http.get(url)) as string;
      return res;
    } catch (error) {
      throw error;
    }
  }
}
