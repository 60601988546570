
import { Vue, Component, Ref, Watch } from 'vue-property-decorator';
import AddBatch from './components/AddBatch.vue';
import AddShopDrawer from './components/AddShopDrawer.vue';
import lazyInject from '@/di';
import OperationService from '@/services/operation.service';
import { CBatchManagement } from '@/models/operation.model';
import moment from 'moment';
import { City } from '@/common/interface/shop';
import ShopService from '@/services/shop.service';
import UserService from '@/services/user.service';
import {
  AuthEnum,
} from '@/services/permission.service';

@Component({
  components: {
    'add-batch': AddBatch,
    'add-shop-drawer': AddShopDrawer,
  },
})
export default class BatchManagement extends Vue {
  @lazyInject(OperationService)
  private operationService!: OperationService;

  @lazyInject(UserService)
  private userService!: UserService;

  moment = moment;

  AuthEnum = AuthEnum;

  citys: City[] = [];

  brandList: any[] = [];

  @Ref('addBatchModal')
  addBatchModal!: AddBatch;

  @lazyInject(ShopService)
  private shopService!: ShopService;

  @Ref('addShopDrawer')
  addShopDrawer!: AddShopDrawer;

  form: {
    startTime: any;
    endTime: any;
    shopName: any;
    bossPhone: any;
    status: any;
    kaId: any;
  } = {
      startTime: '',
      endTime: '',
      shopName: '',
      bossPhone: '',
      status: undefined,
      kaId: undefined,
    };

  data: any[] = [];

  columns: any[] = [
    {
      title: '品牌',
      dataIndex: 'kaName',
      align: 'center',
    },
    {
      title: '入驻批次编号',
      dataIndex: 'batchNo',
      align: 'center',
    },
    {
      title: '批次名称',
      dataIndex: 'batchName',
      align: 'center',
    },
    {
      title: '创建时间',
      dataIndex: 'createdAt',
      align: 'center',
    },
    {
      title: '批次创建人',
      dataIndex: 'createUserName',
      align: 'center',
    },
    {
      title: '已累计提交门店数',
      dataIndex: 'shopNum',
      align: 'center',
    },
    {
      title: '批次状态',
      dataIndex: 'auditStatus',
      align: 'center',
      scopedSlots: { customRender: 'auditStatus' },
    },
    {
      title: '操作',
      slot: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
      width: '200px',
    },
  ];

  pagination: any = {
    current: 1,
    pageSize: 10,
    total: 0,
    onChange: this.onChangePage,
  };
  @Watch('$route')
  onRouteChange() {
    const { startTime, endTime, status, page, pageSize, kaId } = this.$route.query;
    const params: any = {
      page: page || 1,
      pageSize: pageSize || 10,
    };
    if (startTime) {
      params.start_time = startTime;
      this.form.startTime = startTime;
    }
    if (endTime) {
      params.end_time = endTime;
      this.form.endTime = endTime;
    }
    if (status) {
      params.status = status;
      this.form.status = status;
    }
    if (kaId) {
      params.ka_id = kaId;
      this.form.kaId = Number(kaId);
    }
    this.getBatchList(params);
  }


  async getKaList() {
    const id = this.userService.currentUser()?.id;
    const response = await this.shopService.postKaUserList(id);
    if (response) {
      this.brandList = response;
    }
  }
  onChange(value: any, dateString: any) {
    console.log(dateString);
    const startTime = dateString[0];
    const endTime = dateString[1];
    this.form.startTime = startTime;
    this.form.endTime = endTime;
  }

  search() {
    const { startTime, endTime, status, kaId } = this.form;
    const { current, pageSize } = this.pagination;
    const query: any = {
      page: current,
      pageSize,
    };
    if (startTime) {
      query.startTime = startTime;
    }
    if (endTime) {
      query.endTime = endTime;
    }
    if (status) {
      query.status = status;
    }
    if (kaId) {
      query.kaId = kaId;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }
  onChangePage(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    this.search();
  }
  addBatch(type: string) {
    this.addBatchModal.init(type);
  }

  reset() {
    this.form = {
      startTime: '',
      endTime: '',
      shopName: '',
      bossPhone: '',
      status: undefined,
      kaId: undefined,
    };
    const query: any = {
      page: 1,
      pageSize: 10,
    };
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  handleBatch() {
    console.log('handleBatch');
  }

  lookBatchShopList(record: any) {
    console.log('lookBatchShopList', record);
    this.$router.push({
      name: 'operation.batchShopList',
      params: {
        batchNo: record.batchNo,
      },
    });
  }

  async getBatchList(value: any) {
    try {
      const res = await this.operationService.getBatchList(value);
      this.data = res.list.map((item: any) => CBatchManagement.from(item));
      this.pagination.total = res.total;
      console.log(this.data);
    } catch (error) {
      console.error(error);
    }
  }
  async handleAudit(record: any) {
    console.log('handleAudit', record);
    const res = await this.operationService.submitBatch(record.batchNo);
    if (res) {
      this.$message.success('提交德友惠审核成功');
      this.search();
    }
  }

  handleDelete(record: any, type: string) {
    this.addShopDrawer.init(record, type);
  }

  handleView(record: any, type: string) {
    console.log('handleView', record);
    this.addBatchModal.init(type, record);
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.shopService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  mounted() {
    this.onRouteChange();
    this.getKaList();
    this.getCityList();
  }
}
