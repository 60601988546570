import { render, staticRenderFns } from "./SettlementDoc.vue?vue&type=template&id=fca97436&scoped=true"
import script from "./SettlementDoc.vue?vue&type=script&lang=ts"
export * from "./SettlementDoc.vue?vue&type=script&lang=ts"
import style0 from "./SettlementDoc.vue?vue&type=style&index=0&id=fca97436&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fca97436",
  null
  
)

export default component.exports