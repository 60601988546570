
import { Vue, Component, Ref } from 'vue-property-decorator';
import LookShopDetail from './components/LookShopDetail.vue';
import lazyInject from '@/di';
import OperationService from '@/services/operation.service';
import { CShop } from '@/models/operation.model';
import { City } from '@/common/interface';
import BaseService from '@/services/base.service';
import UTILService from '@/services/utils.service';
import {
  AuthEnum,
} from '@/services/permission.service';

@Component({
  components: {
    'look-shop-detail': LookShopDetail,
  },
})
export default class AuditShopList extends Vue {
  @lazyInject(OperationService)
  operationService!: OperationService;

  @Ref('lookShopDetail')
  lookShopDetail!: LookShopDetail;

  @lazyInject(UTILService)
  utilService!: UTILService;

  @lazyInject(BaseService)
  baseService!: BaseService;

  AuthEnum = AuthEnum;

  visible = false;

  citys: City[] = [];

  batchNo = '';

  batchData: any = {};

  auditStatusType = 0;

  form = {
    city_id: undefined,
    poi: '',
    shopName: '',
    phone: '',
    status: undefined,
  };
  columns = [
    {
      title: '门店名称',
      dataIndex: 'shopName',
      align: 'center',
      width: 200,
    },
    // {
    //   title: 'id',
    //   dataIndex: 'id',
    //   align: 'center',
    // },
    {
      title: '品牌',
      dataIndex: 'kaName',
      align: 'center',
      width: 100,
    },
    {
      title: '城市',
      dataIndex: 'city',
      align: 'center',
      width: 100,
    },
    {
      title: '高德poi',
      dataIndex: 'poi',
      align: 'center',
      width: 140,
    },
    {
      title: '门店老板手机号',
      dataIndex: 'bossPhone',
      align: 'center',
      width: 250,
      scopedSlots: { customRender: 'bossPhone' },
    },
    {
      title: '注册过黑卡门店',
      dataIndex: 'entered',
      align: 'center',
      width: 100,
      scopedSlots: { customRender: 'entered' },
    },
    {
      title: '刊例价',
      dataIndex: 'showPrice',
      align: 'center',
      width: 160,
    },
    {
      title: '实际入驻金额',
      dataIndex: 'entranceFee',
      align: 'center',
      width: 160,
    },
    {
      title: '入驻批次号',
      dataIndex: 'batchNo',
      align: 'center',
      width: 120,
    },
    {
      title: '审核状态',
      dataIndex: 'reviewStatus',
      align: 'center',
      width: 100,
      scopedSlots: { customRender: 'reviewStatus' },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'center',
      scopedSlots: { customRender: 'action' },
      width: 200,
    },
  ];
  data = [];
  pagination = {
    current: 1,
    pageSize: 10,
    total: 0,
    onChange: this.onChangePage,
  };

  encryptAccount = '';
  async LookShopDetail(title: string, encryptBossPhone: string, poi: string) {
    const data = {
      encrypt_phone: encryptBossPhone,
      operate_type: title,
      poi,
    };
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.encryptAccount = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }
  init(val: any) {
    this.batchData = val;
    this.auditStatusType = val.auditStatus;
    this.getBatchShops();
    this.visible = true;
    this.getCityList();
    this.data = [];
  }
  onChangePage(page: any, pageSize: any) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    this.getBatchShops();
  }
  async getBatchShops() {
    try {
      const params = {
        page: this.pagination.current,
        pageSize: this.pagination.pageSize,
        ...this.form,
      };
      const res = await this.operationService.getBatchShops(this.batchData.batchNo, params);
      this.data = res.list.map((item: any) => CShop.from(item));
      this.pagination.total = res.total;
    } catch (error) {
      console.error(error);
    }
  }

  handleSearch() {
    console.log(this.form);
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getBatchShops();
  }

  handleReset() {
    this.form = {
      city_id: undefined,
      poi: '',
      shopName: '',
      phone: '',
      status: undefined,
    };
    this.pagination.current = 1;
    this.pagination.pageSize = 10;
    this.getBatchShops();
  }

  async handleDetail(record: any, type: string) {
    const r = await this.operationService.getShopDetail(record.id);
    this.lookShopDetail.init(r, type);
  }

  handleClose() {
    this.visible = false;
    // this.$emit('refresh');
  }
  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.baseService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const citys = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.citys = citys;
    } else {
      this.citys = [];
    }
  }

  async handleRevPass() {
    const _this = this;
    try {
      const res = await this.operationService.revPass(this.batchData.id);
      if (res == true) {
        this.$message.success('品牌代运营审批通过成功');
        this.handleClose();
        this.$emit('refresh');
      } else if (res) {
        this.$confirm({
          title: '品牌代运营审批提示',
          content: res,
          okText: '继续',
          cancelText: '取消',
          onOk() {
            _this.againRevPass();
          },
          onCancel() {
            console.log('Cancel');
          },
          class: 'test',
        });
      }
    } catch (error) {
      console.error(error);
    }
  }
  async againRevPass() {
    const params = {
      ok: 1,
    };
    const res = await this.operationService.revPass(this.batchData.id, params);
    if (res) {
      this.$message.success('品牌代运营审批通过成功');
      this.handleClose();
      this.$emit('refresh');
    }
  }
}
