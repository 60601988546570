
import { Vue, Component } from 'vue-property-decorator';
import lazyInject from '@/di';
import OperationService from '@/services/operation.service';
import { CBatchManagement } from '@/models/operation.model';

@Component({})
export default class AddBatchModal extends Vue {
  @lazyInject(OperationService)
  private operationService!: OperationService;

  visible = false;

  selectedRowKeys: any[] = [];


  pagination: any = {
    current: 1,
    pageSize: 10,
    total: 0,
    onChange: this.onChange,
  };

  columns: any[] = [
    {
      title: '入驻批次编号',
      dataIndex: 'batchNo',
      align: 'center',
    },
    {
      title: '批次名称',
      dataIndex: 'batchName',
      align: 'center',
    },
    {
      title: '批次创建时间',
      dataIndex: 'createdAt',
      align: 'center',
    },
    {
      title: '批次创造人',
      dataIndex: 'createUserName',
      align: 'center',
    },
    {
      title: '已累计提交门店数量',
      dataIndex: 'shopNum',
      align: 'center',
    },
  ];

  data: any[] = [];

  form: any = {};

  ids: any[] = [];

  async handleOk() {
    console.log('handleOk');
    console.log(this.selectedRowKeys);
    const batchNo = this.selectedRowKeys[0];
    const { ids } = this;
    const res = await this.operationService.joinBatch(batchNo, {
      shop_ids: ids,
    });
    if (res) {
      this.$message.success('加入批次成功');
      this.visible = false;
      this.selectedRowKeys = [];
      this.ids = [];
      this.$emit('refresh');
    }
  }
  onChange(page: any, pageSize: any) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const params = {
      page,
      pageSize: 10,
      status: 0,
    };
    this.getBatchList(params);
  }
  init(ids: any[], kaId: any) {
    const params = {
      page: 1,
      pageSize: 10,
      status: 0,
      ka_id: kaId,
    };
    console.log(ids);
    this.ids = ids;
    this.visible = true;

    this.getBatchList(params);
  }

  async getBatchList(value: any) {
    try {
      const res = await this.operationService.getBatchList(value);
      this.data = res.list.map((item: any) => CBatchManagement.from(item));
      this.pagination.total = res.total;
    } catch (error) {
      console.error(error);
    }
  }
  handleCancel() {
    console.log('handleCancel');
    this.selectedRowKeys = [];
    this.ids = [];
    this.visible = false;
  }


  onChangeData(selectedRowKeys: any[]) {
    const _s = this.selectedRowKeys.join(',');
    const _selectedRowKeys = selectedRowKeys.filter((item: any) => !_s.includes(item));
    this.selectedRowKeys = _selectedRowKeys;
  }

  onSelect(record: any, selected: boolean, selectedRows: any[]) {
    console.log('onSelect', record, selected, selectedRows);
  }

  onSelectAll(selected: boolean, selectedRows: any[], changeRows: any[]) {
    console.log('onSelectAll', selected, selectedRows, changeRows);
  }
}
