/*
 * @Author: Src
 * @Date: 2024-11-13 14:59:40
 * @LastEditTime: 2024-11-18 10:06:19
 * @LastEditors: Src
 * @FilePath: /work-station/src/router/withdrawRoutes.ts
 * @Description: 头部注释配置模板
 */
import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import WithdrawList from '@/views/withdraw/WithdrawList.vue';
import ApplyFor from '@/views/withdraw/ApplyFor.vue';
import ApplyExamine from '@/views/withdraw/ApplyExamine.vue';
import ExaminDetail from '@/views/withdraw/ExaminDetail.vue';
import AuditDetails from '@/views/withdraw/AuditDetails.vue';
import SettlementDoc from '@/views/withdraw/SettlementDoc.vue';
import ReviewDetail from '@/views/withdraw/ReviewDetail.vue';

const withdrawRoutes: RouteConfig = {
  name: 'withdraw',
  path: '/withdraw',
  component: Main,
  redirect: {
    name: 'withdraw.list',
  },
  meta: {
    title: '提现管理',
  },
  children: [
    {
      name: 'withdraw.list',
      path: '/withdraw/list',
      component: WithdrawList,
      meta: {
        title: '提现列表',
      },
    },
    {
      name: 'withdraw.apply',
      path: '/withdraw/applyfor',
      component: ApplyFor,
      meta: {
        title: '提现申请',
      },
    },
    {
      name: 'withdraw.examine',
      path: '/withdraw/examine',
      component: ApplyExamine,
      meta: {
        title: '提现审核',
      },
    },
    {
      name: 'withdraw.ExaminDetail',
      path: '/withdraw/examin_detail/:id/:no',
      component: ExaminDetail,
      meta: {
        title: '审核',
      },
    },
    {
      name: 'withdraw.ReviewDetail',
      path: '/withdraw/review_detail/:id/:no/:type?',
      component: ReviewDetail,
      meta: {
        title: '详情',
      },
    },
    {
      name: 'withdraw.AuditDetails',
      path: '/withdraw/audit_details',
      component: AuditDetails,
      meta: {
        title: '提现明细',
      },
    },
    {
      name: 'withdraw.SettlementDoc',
      path: '/withdraw/settlement_doc',
      component: SettlementDoc,
      meta: {
        title: '结算单',
      },
    },
  ],
};

export default withdrawRoutes;
