/*
 * @Author: Src
 * @Date: 2024-12-18 10:09:56
 * @LastEditTime: 2024-12-23 09:17:01
 * @LastEditors: Src
 * @FilePath: /work-station/src/router/operationRoutes.ts
 * @Description: 品牌运营
 */
import { RouteConfig } from 'vue-router';
import Main from '@/views/Main.vue';
import StoreOperation from '@/views/operation/StoreOperation.vue';
import BatchManagement from '@/views/operation/BatchManagement.vue';
import StoreDetail from '@/views/operation/StoreDetail.vue';
import BatchShopList from '@/views/operation/BatchShopList.vue';

const operationRoutes: RouteConfig = {
  name: 'operation',
  path: '/operation',
  component: Main,
  redirect: {
    name: 'operation.storeOperation',
  },
  meta: {
    title: '品牌运营',
  },
  children: [
    {
      name: 'operation.storeOperation',
      path: '/operation/storeOperation',
      component: StoreOperation,
      meta: {
        title: '门店入驻审核',
      },
    },
    {
      name: 'operation.batchManagement',
      path: '/operation/batchManagement',
      component: BatchManagement,
      meta: {
        title: '批次管理',
      },
    },
    {
      name: 'operation.storeDetail',
      path: '/operation/storeDetail',
      component: StoreDetail,
      meta: {
        title: '门店详情',
      },
    },
    {
      name: 'operation.batchShopList',
      path: '/operation/batchShopList/:batchNo',
      component: BatchShopList,
      meta: {
        title: '批次门店列表',
      },
    },
  ],
};

export default operationRoutes;
