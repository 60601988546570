
import { IShopFormField } from '@/common/interface/shop';
import { EShopField } from '@/common/enum/shop';
import { Vue, Component, Emit, Prop, Watch, Ref } from 'vue-property-decorator';
import { FormModel } from 'ant-design-vue';

import AddressField from './shopFieldTemplate/AddressField.vue';
import AdvertiserMobilePhoneNumberField from './shopFieldTemplate/AdvertiserMobilePhoneNumberField.vue';
import BossIdImageField from './shopFieldTemplate/BossIdImageField.vue';
import DataChedField from './shopFieldTemplate/DataChedField.vue';
import MainBusinessField from './shopFieldTemplate/MainBusinessField.vue';
import ManagerTelsField from './shopFieldTemplate/ManagerTelsField.vue';
import NameField from './shopFieldTemplate/NameField.vue';
import ParkNumField from './shopFieldTemplate/ParkNumField.vue';
import RestAreaField from './shopFieldTemplate/RestAreaField.vue';
import ShopImagesField from './shopFieldTemplate/ShopImagesField.vue';
import StationField from './shopFieldTemplate/StationField.vue';
import ShopLicenseField from './shopFieldTemplate/ShoplicenseField.vue';
import ShopVideosField from './shopFieldTemplate/ShopVideosField.vue';
import StartTimeField from './shopFieldTemplate/StartTimeField.vue';
import TelsField from './shopFieldTemplate/TelsField.vue';
import VideosField from './shopFieldTemplate/VideosField.vue';
import BusinessTimeField from './shopFieldTemplate/BusinessTimeField.vue';
import StaffField from './shopFieldTemplate/StaffField.vue';
import OnSiteService from './shopFieldTemplate/OnSiteService.vue';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';

@Component({
  components: {
    AddressField,
    AdvertiserMobilePhoneNumberField,
    BossIdImageField,
    BusinessTimeField,
    DataChedField,
    MainBusinessField,
    ManagerTelsField,
    NameField,
    ParkNumField,
    RestAreaField,
    ShopImagesField,
    ShopLicenseField,
    ShopVideosField,
    StartTimeField,
    StationField,
    TelsField,
    VideosField,
    OnSiteService,
  },
})
export default class ShopEditor extends Vue {
  isVisible = false;

  title = '';

  EShopField = EShopField;

  field: IShopFormField | null = null;

  @lazyInject(ShopService)
  shopService!: ShopService;

  @Prop()
  encryptTels!: string;

  submittable = false;

  adUserPhone = '';

  adUserBind = false;

  get rules() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rules: any = {};
    if (!this.field || this.field == null) {
      return rules;
    }
    if (!this.field.rule) {
      return rules;
    }

    rules.value = this.field.rule;
    return rules;
  }

  @Prop({
    default: () => {
      return '';
    },
  })
  shopId!: string;

  @Prop()
  editingField!: IShopFormField;

  @Prop()
  poi!: string;

  @Ref()
  readonly ruleForm!: FormModel;

  @Watch('editingField', { deep: true })
  watchEditingField() {
    if (this.editingField == null) {
      return;
    }
    this.title = this.editingField.fieldTitle;
    this.field = { ...this.editingField };
  }

  @Emit('submit')
  onConfirm() {
    return { [`${this.field?.label}`]: this.field?.value };
  }

  getComponent(type: string) {
    switch (type) {
      case EShopField.NAME: {
        return NameField;
      }
      case EShopField.ADDRESS: {
        return AddressField;
      }
      case EShopField.BOSS_ID_IMAGES: {
        return BossIdImageField;
      }
      case EShopField.BUSINESS_TIME: {
        return BusinessTimeField;
      }
      case EShopField.MANAGER_TELS: {
        return ManagerTelsField;
      }
      case EShopField.ADVERTISER_MOBILE_PHONE_NUMBER: {
        return AdvertiserMobilePhoneNumberField;
      }
      case EShopField.START_TIME: {
        return StartTimeField;
      }
      case EShopField.MAIN_BUSINESS: {
        return MainBusinessField;
      }
      case EShopField.TELS: {
        return TelsField;
      }
      case EShopField.DATA_CHED: {
        return DataChedField;
      }
      case EShopField.STAFF: {
        return StaffField;
      }
      case EShopField.STATION: {
        return StationField;
      }
      case EShopField.PARK_NUM: {
        return ParkNumField;
      }
      case EShopField.REST_AREA: {
        return RestAreaField;
      }
      case EShopField.VIDEOS: {
        return VideosField;
      }
      case EShopField.SHOP_LICENSE: {
        return ShopLicenseField;
      }
      case EShopField.SHOP_IMAGES: {
        return ShopImagesField;
      }
      case EShopField.SHOP_VIDEOS: {
        return ShopVideosField;
      }
      case EShopField.ONSITESERVICEFLAG: {
        return OnSiteService;
      }
      default:
        break;
    }
  }
  async shopBk() {
    const { id } = this.$route.params;
    const res = (await this.shopService.adUserBk(id));
    this.adUserPhone = res?.ad_user_phone || '';
    this.adUserBind = res?.bind || false;
  }
  handleForceRefresh() {
    this.$emit('refresh');
  }

  close() {
    this.isVisible = false;
    this.field = null;
  }

  open() {
    this.isVisible = true;
    if (this.field?.label === EShopField.ADVERTISER_MOBILE_PHONE_NUMBER) {
      this.shopBk();
    }
  }

  handleSubmittable(submittable: boolean) {
    this.submittable = submittable;
  }

  mounted() {
    this.watchEditingField();
  }
}
