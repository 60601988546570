
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import ShopService from '@/services/shop.service';
import { FormModel } from 'ant-design-vue';
import OperationService from '@/services/operation.service';


@Component({})
export default class AddBatch extends Vue {
  @lazyInject(OperationService)
  private operationService!: OperationService;

  @lazyInject(UserService)
  private userService!: UserService;

  @lazyInject(ShopService)
  private shopService!: ShopService;

  @Ref()
  ruleForm!: FormModel;

  visible = false;

  title = '';

  type = '';

  formData: any = {
    batch_name: '',
    ka_id: undefined,
    batch_no: '',
  };

  rules: any = {
    ka_id: [{ required: true, message: '请选择品牌' }],
    batch_name: [{ required: true, message: '请输入批次名称' }],
  };

  kalist: any[] = [];
  @Prop()
  brandList!: any[];

  async init(type: any, value?: any) {
    if (type === 'edit') {
      this.title = '修改批次';
      const { batchNo, batchName } = value;
      this.formData = {
        batch_no: batchNo,
        batch_name: batchName,
      };
      this.formData.ka_id = this.brandList.find((item: any) => item.kaName === value.kaName)?.kaId;
    } else if (type === 'add') {
      this.title = '创建批次';
      const res = await this.operationService.getBatchNumber();
      this.formData.batch_no = res.batch_no;
    }
    this.type = type;
    this.visible = true;
  }

  handleCancel() {
    this.visible = false;
    this.formData = {
      batch_name: '',
      ka_id: undefined,
      batch_no: '',
    };
  }

  getKaList(val?: any) {
    this.formData.ka_id = this.brandList.find((item: any) => item.kaName === val)?.kaId;
    console.log(this.formData.ka_id);
  }

  async handleOk() {
    this.ruleForm.validate((valid: boolean) => {
      if (valid) {
        this.addBatch();
      }
    });
  }
  async addBatch() {
    if (this.type === 'add') {
      const res = await this.operationService.addBatch(this.formData);
      if (res) {
        this.$message.success('创建批次成功');
        this.$emit('refresh');
        this.handleCancel();
      }
    } else if (this.type === 'edit') {
      const { batch_no, batch_name } = this.formData;
      const res = await this.operationService.editBatch({
        batch_no,
        batch_name,
      });
      if (res) {
        this.$message.success('修改批次成功');
        this.$emit('refresh');
        this.handleCancel();
      }
    }
  }
}
