
import { EDYHUserStatus, EDYHUserType } from '@/common/enum/account';
import { AgentAccountRes, UserRes } from '@/common/interface/account';
import lazyInject from '@/di';
import { AccountService } from '@/services/account_service';
import { AgentService } from '@/services/agent.service';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import EditSalesStaffRelativeRegionModal from './components/EditSalesStaffRelativeRegionsModal.vue';
import EditUserModal from './components/EditUserModal.vue';
import { handleTree } from '@/common/utils';
import { ActivitySignup } from '@/models/activity.model';
import { Tree } from 'ant-design-vue';


@Component({
  components: {
    'edit-user-modal': EditUserModal,
    'edit-sales-staff-relative-regions-modal':
      EditSalesStaffRelativeRegionModal,
  },
})
export default class UserList extends Vue {
  @lazyInject(AccountService)
  protected accountService!: AccountService;

  @lazyInject(AgentService)
  protected agentService!: AgentService;

  @Ref('editUserModal')
  editUserModal?: EditUserModal;

  @Ref('treeRef')
  treeRef?: Tree;

  @Ref('editSalesStaffRelativeRegionModal')
  editSalesStaffRelativeRegionModal?: EditSalesStaffRelativeRegionModal;

  EDYHUserStatus = EDYHUserStatus;

  EDYHUserType = EDYHUserType;

  userTypeList: EDYHUserType[] = [
    EDYHUserType.全部,
    EDYHUserType.管理员,
    EDYHUserType.审核员,
    EDYHUserType.地推员,
    EDYHUserType.店铺老板,
    EDYHUserType.雷达安装工,
  ];

  filterForm: {
    userName: string;
    phone: string;
    userType: number;
    aid: number;
    id: string;
    deptId: string; // 部门ID
  } = {
      userName: '',
      phone: '',
      userType: 0,
      aid: -1,
      id: '',
      deptId: '',
    };

  pagination = {
    total: 0,
    pageSize: 30,
    current: 1,
    onChange: this.paginationChange,
  };

  userList: UserRes[] = [];

  currentUser: UserRes | null = null;

  agentList: AgentAccountRes[] = [];

  treeData: any[] = [];

  treeDataOld: any[] = [];

  treeDataUser: any[] = [];

  inputValue = '';

  @Watch('$route')
  async handleSearch() {
    const { current, pageSize } = this.pagination;

    const param = this.extractRouterQueryAndFillFilterForm();

    Object.assign(param, {
      pageSize,
      page: current,
    });

    await this.list(param);
  }
  onChange() {
    const value = this.inputValue;
    if (value === '') {
      this.treeData = [];
      this.$nextTick(() => {
        this.treeData = handleTree(this.treeDataOld, 'id', 'parentId', 'children');
      });
      return;
    }
    const Arrt: any[] = [];
    this.treeDataOld.forEach((item: any) => {
      if (item.deptName.indexOf(value) > -1) {
        const parentId = item.ancestors.split(',');
        const parentIdArr = this.funPanduan(parentId);
        Arrt.push(...parentIdArr);
        Arrt.push(item);
      }
    });
    const map = new Map();
    const newArr = Arrt.filter((v) => !map.has(v.id) && map.set(v.id, v));
    this.treeData = handleTree(newArr, 'id', 'parentId', 'children');
  }

  funPanduan(arr: any) {
    const parentId: any[] = [];
    arr.forEach((item: any) => {
      this.treeDataOld.forEach((res: any) => {
        if (res.id === Number(item)) {
          parentId.push(res);
        }
      });
    });
    return parentId;
  }
  paginationChange(current: number) {
    this.pagination.current = current;
    const query = this.extractRouterQueryAndFillFilterForm();
    Object.assign(query, { page: current });

    this.$router.push({ query });
  }

  dragend(event: any) {
    this.filterForm.deptId = event[0];
    this.search();
  }

  extractRouterQueryAndFillFilterForm() {
    const { userName, phone, aid, id, userType, deptId } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {};

    if (typeof userName !== 'undefined') {
      param.userName = userName;
      this.filterForm.userName = userName as string;
    }

    if (typeof phone !== 'undefined') {
      param.phone = phone;
      this.filterForm.phone = phone as string;
    }

    if (typeof aid !== 'undefined' && +aid !== -1) {
      param.aid = aid;
      this.filterForm.aid = +aid;
    }

    if (typeof userType !== 'undefined') {
      param.userType = userType;
      this.filterForm.userType = +userType;
    }

    if (typeof id !== 'undefined') {
      param.id = id;
      this.filterForm.id = id as string;
    }

    if (typeof deptId !== 'undefined') {
      param.deptId = deptId;
      this.filterForm.deptId = deptId as string;
    }

    return param;
  }

  async userStatusChange(userId: number, status: number) {
    const setStatus =
      status === EDYHUserStatus.正常
        ? EDYHUserStatus.禁止登录
        : EDYHUserStatus.正常;
    const res = await this.accountService.setUserStatus(userId, setStatus);
    if (res) {
      const msg = status === EDYHUserStatus.正常 ? '禁用成功！' : '启用成功!';
      this.$message.success(msg);
      await this.handleSearch();
    }
  }

  oepnSalesStaffRelativeRegionModal(user: UserRes) {
    this.currentUser = user;
    this.editSalesStaffRelativeRegionModal?.open();
  }

  /** 手机号检测 */
  testPhoneValid(): boolean {
    return /1[3-9]\d{9}/.test(this.filterForm.phone);
  }

  async sysDeptList(params: any) {
    const res = await this.accountService.sysDeptList(params) as any;
    if (res) {
      const activitySignup = res.map((item: any) => ActivitySignup.from(item)) as any;
      const tree = handleTree(activitySignup, 'id', 'parentId', 'children');
      this.treeDataOld = activitySignup;
      this.treeData = tree;
      this.treeDataUser = tree;
    }
  }

  /** 点击查询 */
  search() {
    this.pagination = {
      total: 0,
      pageSize: 30,
      current: 1,
      onChange: this.paginationChange,
    };

    const { userName, userType, aid, phone, id, deptId } = this.filterForm;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = {
      page: `${this.pagination.current}`,
    };

    if (userName) {
      query.userName = userName;
    }
    if (typeof userType !== 'undefined' && userType !== 0) {
      query.userType = userType;
    }

    if (typeof aid !== 'undefined' && aid !== -1) {
      query.aid = aid;
    }

    if (phone) {
      if (!this.testPhoneValid()) {
        this.$message.warn('手机格式不正确，请重新输入');
        return;
      }
      query.phone = phone;
    }

    if (typeof id !== 'undefined' && +id !== 0) {
      query.id = id;
    }

    if (deptId) {
      query.deptId = deptId;
    }

    query.timestamp = Math.round(Date.now() / 1000);

    this.$router.push({
      query,
    });
  }

  async list(params: {
    pageSize?: number;
    page: number;
    phone?: string;
    aid?: number;
    id?: number;
    userType?: number;
    userName?: string;
  }) {
    const res = await this.accountService.list(params);

    if (res) {
      this.userList = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  async getAgentList() {
    const res = await this.agentService.all();
    if (res) {
      this.agentList = [{ aid: -1, agentName: '全部' }].concat([
        ...res,
      ]) as AgentAccountRes[];
    }
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.filterForm = {
      userName: '',
      phone: '',
      userType: 0,
      aid: -1,
      id: '',
      deptId: '',
    };

    this.pagination = {
      total: 0,
      pageSize: 30,
      current: 1,
      onChange: this.paginationChange,
    };
    this.inputValue = '';
    this.onChange();
    const query: any = {};
    console.log(this.treeRef, 'fff');
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  async created() {
    await this.getAgentList();
    this.$nextTick(async () => {
      await this.handleSearch();
      await this.sysDeptList({ status: 1 });
    });
  }

  oepnEditUserModal(user?: UserRes) {
    if (typeof user === 'undefined') {
      this.currentUser = null;
    } else {
      this.currentUser = user;
    }

    this.editUserModal?.open();
  }
}
