
import { Vue, Component, Prop } from 'vue-property-decorator';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';

@Component({})
export default class LogModal extends Vue {
  @lazyInject(SPUService)
  protected spuService!: SPUService;


  @Prop()
  type!: number;

  visible = false;

  itemId = 0;
  default_item_data: any = {};
  default_last_item_data: any = {};

  data: any = {
    itemData: {
      item: '',
      item_data: [],
      category: '',
      type: '',
      is_required: '',
      status: '',
    },
    lastItemData: {},
  };

  item_data: any = {};

  handleOk() {
    this.item_data = {};
    this.data = {};
    this.visible = false;
  }
  cancel() {
    this.item_data = {};
    this.data = {
      itemData: {
        item: '',
        item_data: [],
        category: '',
        type: '',
        is_required: '',
        status: '',
      },
      lastItemData: {
        item: '',
        item_data: [],
        category: '',
        type: '',
        is_required: '',
        status: '',
      },
    };
    this.visible = false;
  }
  async getLogList(value: any) {
    const params = {
      id: value.id,
    };
    const res = await this.spuService.getShopDetailItemLogDetail(params);
    if (res) {
      // this.item_data = res.item_data;
      const { item_data } = res;
      const last_item_data = res.last;

      const data: any = {
        itemData: {
          item: '',
          item_data: [],
          category: '',
          type: '',
          is_required: '',
          status: '',
        },
        lastItemData: {
          item: '',
          item_data: [],
          category: '',
          type: '',
          is_required: '',
          status: '',
        },
      };
      const KeyList = ['item', 'item_data', 'category', 'type', 'is_required', 'status'];
      if (this.type == 2) {
        this.default_item_data = res.item_data.default_item || '';
        this.default_last_item_data = res.last.default_item || '';
      } else {
        KeyList.forEach((key) => {
          if (key == 'item_data') {
            // console.log(item_data[key], last_item_data[key]);
            if (!last_item_data[key]) {
              data.itemData[key] = item_data[key];
              return;
            }
            if (item_data[key].toString() !== last_item_data[key].toString()) {
              data.itemData[key] = item_data[key];
              data.lastItemData[key] = last_item_data[key];
            }
          } else if (item_data[key] !== last_item_data[key]) {
            data.itemData[key] = item_data[key];
            data.lastItemData[key] = last_item_data[key];
          }
        });
        if (data.lastItemData.item == undefined) {
          data.lastItemData.item = '';
        }
        if (data.lastItemData.category == undefined) {
          data.lastItemData.category = '';
        }
        if (data.lastItemData.item_data == undefined) {
          data.lastItemData.item_data = [];
        }
        if (data.lastItemData.status == undefined) {
          data.lastItemData.status = '';
        }
        this.data = data;
      }
    }
  }

  open(value: any) {
    this.visible = true;

    this.getLogList(value);
  }
}
