/* eslint-disable @typescript-eslint/indent */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import User from '@/models/user';
import { injectable, inject } from 'inversify';
import { LoginParam } from '@/common/interface';
import { AuthType } from '@/common/enum';
import HttpClient from './http.service';
import UserService from './user.service';

/**
 *
 *
 * @export
 * @class Auth
 */
export class Auth {
  phone = '';

  type: AuthType = AuthType.VERIFICATION_CODE;

  password = '';

  captcha = '';

  captchaKey = '';

  // 认证码
  code = '';

  public isPhoneValid() {
    const mobileReg = /1[3-9]\d{9}/;
    return mobileReg.test(this.phone);
  }

  public isPasswordValid() {
    if (this.password.length < 6) {
      return false;
    }
    return true;
  }

  public isVerificationCodeValid() {
    const authCodeReg = /\d{6}/;
    return authCodeReg.test(this.code);
  }

  public isValid(type: AuthType = AuthType.VERIFICATION_CODE): boolean {
    // 默认为验证码登录
    // 密码登录时，传递type以示区别
    if (!this.isPhoneValid()) {
      return false;
    }

    if (type === AuthType.PASSWORD) {
      return this.isPasswordValid();
    } else {
      return this.isVerificationCodeValid();
    }
  }

  static fromPassword(phone: string, password: string): Auth {
    const auth = new Auth();
    auth.phone = phone;
    auth.password = password;
    auth.type = AuthType.PASSWORD;
    return auth;
  }

  static fromVerificationCode(phone: string, verificationCode: string): Auth {
    const auth = new Auth();
    auth.phone = phone;
    auth.code = verificationCode;
    auth.type = AuthType.VERIFICATION_CODE;
    return auth;
  }
}


/**
 *
 *
 * @export
 * @class AuthService
 * @extends {UserService}
 */
@injectable()
export default class AuthService extends UserService {
  @inject(HttpClient)
  protected http!: HttpClient;

  @inject(UserService)
  protected userService!: UserService;

  async login(auth: Auth, loadingEl: Element | Vue): Promise<any> {
    try {
      const param: LoginParam = {
        phone: auth.phone,
        type: auth.type,
        captcha: auth.captcha,
        captchaKey: auth.captchaKey,
        loadingEl,
      };
      if (auth.type === AuthType.PASSWORD) {
        param.password = auth.password;
      } else {
        param.password = auth.code;
      }
      const result: User = await this.http.post('/user/login', param);

      if (result) {
        this.userService.save(result);
      }
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async signup(
    auth: Auth,
  ): Promise<
    | {
      id: string;
      phone: string;
      [key: string]: any;
    }
    | undefined
  > {
    try {
      const result: any = await this.http.post('/user/register', {
        phone: auth.phone,
        password: auth.password,
      });

      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async sendAuth(auth: Auth): Promise<any> {
    try {
      const result = (await this.http.post('/sms-verification-code', {
        phone: auth.phone,
      })) as string;

      if (result) {
        return result;
      } else {
        return Promise.reject(result);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async resetPassword(auth: Auth): Promise<any> {
    const result: any = await this.http.post('/user/reset-password', {
      password: auth.password,
    });

    this.userService.save(result);
  }

  async captcha(phone: any) {
    try {
      const result = (await this.http.get(`/captcha?phone=${phone}`)) as any;
      return result;
    } catch (error) {
      return null;
    }
  }


  async getAuditShopList(params: any) {
    try {
      const res = (await this.http.get('/ka_shop_enter/batch/list/audit', { params })) as any;
      return res;
    } catch (error) {
      return null;
    }
  }

  async getRevShopList(params: any) {
    try {
      const res = (await this.http.get('/ka_shop_enter/rev_list', { params })) as any;
      return res;
    } catch (error) {
      return null;
    }
  }
}
