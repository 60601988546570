/*
 * @Author: Src
 * @Date: 2024-07-16 14:41:02
 * @LastEditTime: 2024-12-29 10:05:17
 * @LastEditors: Src
 * @FilePath: /work-station/src/models/activity.model.ts
 * @Description: 头部注释配置模板
 */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import { ActivityType } from '@/common/enum/activity';
import { IResActivity, ResActivitySignup, IResActivitySignup } from '@/common/interface/activity';
import { Moment } from 'moment';

export default class Activity {
  name = '';

  id?: number;

  actType = ActivityType.秒杀;

  adcodes?: string;

  description = ''; // 活动规则

  totalLimit = 0; // 总库存

  dailyLimit = 0; // 日库存

  icon = ''; // 活动头图

  subIcon = ''; // 专区标题图

  gmtStartTime: Moment | undefined = undefined;

  gmtEndTime: Moment | undefined = undefined;

  activityPriceCent = 0; // 活动价格，单位分；

  platformProductId: number | string = ''; // 平台商品id;

  static from(data: IResActivity) {
    const act = new Activity();
    act.name = data.name;
    act.actType = data.act_type;
    act.description = data.description;
    act.dailyLimit = data.act_day_limit;
    act.totalLimit = data.act_total_limit;
    act.icon = data.icon;
    act.subIcon = data.subIcon;
    act.platformProductId = data.spu_id;
    act.activityPriceCent = data.act_price;
    act.gmtEndTime = data.gmt_end;
    act.gmtStartTime = data.gmt_start;
    if (data.id) {
      act.id = data.id;
    }
    if (data.adcodes && data.adcodes !== '') {
      act.adcodes = data.adcodes;
    }

    return act;
  }
}


export class ActivitySignup implements ResActivitySignup {
  ancestors = '';

  createUserId = '';

  createdAt = '';

  delFlag = 0;

  deptName = '';

  email = '';

  id = 0;

  leader = '';

  leaderId = '';

  orderNum = 0;

  parentId = 0;

  phone = '';

  status = 0;

  updateUserId = '';

  updatedAt = '';

  static from(data: IResActivitySignup) {
    const dept = new ActivitySignup();
    dept.id = data.id;
    dept.deptName = data.dept_name;
    dept.parentId = data.parent_id;
    dept.status = data.status;
    dept.createdAt = data.created_at;
    dept.updatedAt = data.updated_at;
    dept.leader = data.leader;
    dept.leaderId = data.leader_id;
    dept.phone = data.phone;
    dept.email = data.email;
    dept.orderNum = data.order_num;
    dept.createUserId = data.create_user_id;
    dept.updateUserId = data.update_user_id;
    dept.delFlag = data.del_flag;
    dept.ancestors = data.ancestors;
    return dept;
  }
}
