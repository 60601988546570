
import { Vue, Component, Prop } from 'vue-property-decorator';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import ShopService from '@/services/shop.service';

@Component
export default class ExitBrandModal extends Vue {
  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @lazyInject(ShopService)
  protected shopService!: ShopService;

  visible = false;

  form: any = {
    staff: '',
  };

  staffName = '';


  @Prop()
  shopId!: number;

  async handleOk() {
    const res = await this.shopService.removeKa(this.shopId, this.form);
    if (res) {
      this.visible = false;
      this.$emit('refresh', true);
    }
  }

  async init() {
    try {
      const res = await this.spuService.getRule('remove_ka_staff_name');
      this.visible = true;
      this.staffName = res;
      this.form.staff = '';
    } catch (error) {
      this.$message.error('获取规则失败');
    }
  }
}
