
import moment from 'moment';
import { Vue, Component, Watch } from 'vue-property-decorator';
import lazyInject from '@/di';
import ShopService from '@/services/shop.service';
import {
  Kalist,
} from '@/common/interface/shop';
import UserService from '@/services/user.service';
import WithdrawService from '@/services/withdraw.service';
import { CApply } from '@/models/apply.model';
import { IApply } from '@/common/interface/apply';
import {
  AuthEnum,
} from '@/services/permission.service';
import UTILService from '@/services/utils.service';


@Component({})
export default class ApplyFor extends Vue {
  @lazyInject(UTILService)
  utilService!: UTILService;
  moment = moment;

  AuthEnum = AuthEnum;

  withdrawAccount = '';

  encryptAccount = '';

  encryptAccountPhoone = '';

  withdrawAmount = '0.00';

  maxAmount = 0;

  @lazyInject(UserService)
  userService!: UserService;

  @lazyInject(ShopService)
  shopService!: ShopService;

  @lazyInject(WithdrawService)
  withdrawService!: WithdrawService;

  selectedRowKeys: any = []; // Check here to configure the default column

  selectedData: any = [];

  latitude: any = '0';

  longitude: any = '1';

  ctime_data: any = [];

  verifyts_data: any = [];

  selectPriceDate: any = [];

  offsetDays: number = 86400000 * 30;

  filterForm: {
    ka_id: unknown; // 品牌ID（必传）
    ctime_start: string; // 下单时间起
    ctime_end: string; // 下单时间止
    verifyts_start: string; // 核销时间起
    verifyts_end: string; // 核销时间止
  } = {
      ka_id: undefined,
      ctime_start: '',
      ctime_end: '',
      verifyts_start: '',
      verifyts_end: '',
    };

  kalist: Kalist[] = [];

  columns = [
    {
      title: '序号',
      dataIndex: 'id',
      scopedSlots: { customRender: 'index' },
      align: 'center',
      width: 80,
    },
    {
      title: '高德订单号',
      dataIndex: 'orderNo',
      align: 'center',
      width: 200,
    },
    {
      title: 'SKU',
      dataIndex: 'goodsId',
      align: 'center',
      width: 100,
    },
    {
      title: '商品名称',
      dataIndex: 'goodsName',
      align: 'center',
      width: 200,
    },
    {
      title: '品牌',
      dataIndex: 'kaName',
      align: 'center',
      width: 100,
    },
    {
      title: '数量',
      dataIndex: 'quantity',
      align: 'center',
      width: 100,
    },
    {
      title: '总核销次数',
      dataIndex: 'tnum',
      align: 'center',
      width: 100,
    },
    {
      title: '核销次数',
      dataIndex: 'cnum',
      align: 'center',
      width: 100,
    },
    {
      title: '下单时间',
      dataIndex: 'ctime',
      align: 'center',
      width: 200,
    },
    {
      title: '订单金额',
      dataIndex: 'oriPrice',
      align: 'center',
      width: 100,
    },
    {
      title: '优惠金额',
      dataIndex: 'coupon',
      align: 'center',
      width: 100,
    },
    {
      title: '实收金额',
      dataIndex: 'price',
      align: 'center',
      width: 100,
    },
    {
      title: '抽拥比例',
      dataIndex: 'comRatio',
      align: 'center',
      width: 100,
    },
    {
      title: '抽拥金额',
      dataIndex: 'commissionPrice',
      align: 'center',
      width: 100,
    },
    {
      title: '结算金额',
      dataIndex: 'verifyPrice',
      align: 'center',
      width: 100,
    },
    {
      title: '核销状态',
      dataIndex: 'verifyStatus',
      align: 'center',
      width: 100,
    },
    {
      title: '核销时间',
      dataIndex: 'verifyts',
      align: 'center',
      width: 200,
    },
    {
      title: '结算状态',
      dataIndex: 'settleStatus',
      align: 'center',
      width: 100,
    },
  ];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
    showTotal: (total: any) => `共 ${total} 条`,
  };

  data = [];

  loading = true;

  /**
   * 监听路由变化
   */
  @Watch('$route')
  filterFormChange() {
    const { ka_id, ctime_start, ctime_end, verifyts_start, verifyts_end, page, pageSize } = this.$route.query;

    const params: any = {
      page: page ? Number(page) : 1,
      pageSize: pageSize ? Number(pageSize) : 10,
    };
    this.pagination.current = page ? Number(page) : 1;
    if (ka_id) {
      params.ka_id = Number(ka_id);
      this.filterForm.ka_id = Number(ka_id);
    }
    if (ctime_start) {
      this.ctime_data = [moment(ctime_start as string), moment(ctime_end as string)];
      params.ctime_start = ctime_start as string;
      this.filterForm.ctime_start = ctime_start as string;
    }
    if (ctime_end) {
      params.ctime_end = ctime_end as string;
      this.filterForm.ctime_end = ctime_end as string;
    }
    if (verifyts_start) {
      this.verifyts_data = [moment(verifyts_start as string), moment(verifyts_end as string)];
      params.verifyts_start = verifyts_start as string;
      this.filterForm.verifyts_start = verifyts_start as string;
    }
    if (verifyts_end) {
      params.verifyts_end = verifyts_end as string;
      this.filterForm.verifyts_end = verifyts_end as string;
    }
    this.getWithdrawList(params);
  }
  async LookShopDetail(title: string, encryptAccount: string) {
    const data = {
      encrypt_phone: encryptAccount,
      operate_type: title,
    };
    console.log(data);
    try {
      const res = await this.utilService.postPhoneDecrypt(data);
      if (res) {
        this.encryptAccountPhoone = res;
      }
    } catch (error) {
      this.$message.error('解密失败');
    }
  }


  /**
   * 核销时间范围改变
   * @param date 日期范围
   */
  calendarPriceRangeChange(date: any) {
    this.selectPriceDate = date[0];
  }

  /**
   * 核销时间范围改变
   * @param date 日期范围
   */
  changePriceRangeDate(date: any) {
    if (date) {
      this.selectPriceDate = '';
    } else {
      this.selectPriceDate = this.filterForm.verifyts_start;
    }
  }

  /**
   * 禁用日期
   * @param current 当前日期
   * @returns 是否禁用
   */
  disabledDate(current: any) {
    if (this.selectPriceDate) {
      const selectV = moment(this.selectPriceDate, 'YYYY-MM-DD').valueOf();
      return current > moment(new Date(selectV + this.offsetDays), 'YYYY-MM-DD') ||
        current < moment(new Date(selectV - this.offsetDays), 'YYYY-MM-DD');
    } else {
      return false;
    }
  }

  /**
   * 分页改变
   * @param page 当前页
   * @param pageSize 每页条数
   */
  paginationChange(page: number, pageSize: number) {
    this.pagination.current = page;
    this.pagination.pageSize = pageSize;
    const { ka_id, ctime_start, ctime_end, verifyts_start, verifyts_end } = this.$route.query;
    const query: any = {
      ka_id,
      ctime_start,
      ctime_end,
      verifyts_start,
      verifyts_end,
      page,
      pageSize,
    };
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  onChangeData() { }

  /**
   * 单选
   * @param record
   * @param selected
   */
  onSelect(record: any, selected: any) {
    if (selected) {
      this.selectedRowKeys.push(record.id);
      this.selectedData.push(record);
    } else {
      const index = this.selectedRowKeys.indexOf(record.id);
      if (index > -1) {
        this.selectedRowKeys.splice(index, 1);
      }
      this.selectedData = this.selectedData.filter((item: any) => item.id !== record.id);
    }
    this.calculateSettlementAmount();
  }

  /**
   * 全选
   * @param selected
   * @param selectedRows
   * @param changeRows
   */
  onSelectAll(selected: any, selectedRows: any, changeRows: any) {
    if (selected) {
      changeRows.forEach((row: any) => {
        this.selectedRowKeys.push(row.id);
        this.selectedData.push(row);
      });
    } else {
      changeRows.forEach((row: any) => {
        const index = this.selectedRowKeys.indexOf(row.id);
        if (index > -1) {
          this.selectedRowKeys.splice(index, 1);
        }
        this.selectedData = this.selectedData.filter((item: any) => item.id !== row.id);
      });
    }
    this.calculateSettlementAmount();
  }

  /**
   * 计算提现金额
   */
  calculateSettlementAmount() {
    let totalAmount = 0;
    this.selectedData.forEach((item: any) => {
      if (item.verifyPrice) {
        totalAmount += Number(item.verifyPrice);
      }
    });
    this.withdrawAmount = totalAmount.toFixed(2);
  }
  getCheckboxProps() { }

  handleRowChange() { }

  /**
   * 获取提现列表
   * @param params 查询参数
   */
  async getWithdrawList(data: any) {
    this.loading = true;
    const params = {
      ...data,
    };
    if (!data.ka_id) {
      params.ka_id = this.filterForm.ka_id;
    }
    if (data.verifyts_start) {
      params.verifyts_start = `${params.verifyts_start} 00:00:00`;
    }
    if (data.verifyts_end) {
      params.verifyts_end = `${params.verifyts_end} 23:59:59`;
    }
    if (data.ctime_start) {
      params.ctime_start = `${params.ctime_start} 00:00:00`;
    }
    if (data.ctime_end) {
      params.ctime_end = `${params.ctime_end} 23:59:59`;
    }
    const response = await this.withdrawService.kaApplyList(params);
    if (response) {
      this.loading = false;
      const applyList = response.list.map((item: IApply) => CApply.from(item));
      this.data = applyList;
      this.withdrawAccount = response.ka_withdrawal_account.account;
      this.encryptAccount = response.ka_withdrawal_account.encrypt_account;
      this.maxAmount = response.ka_withdrawal_account.max_amount;
      this.pagination.total = response.total;
    }
  }

  /**
   * 搜索
   */
  search() {
    const { ctime_start, ctime_end, verifyts_start, verifyts_end, ka_id } = this.filterForm;
    const query: any = {
      page: 1,
      pageSize: 10,
    };
    if (ka_id) {
      query.ka_id = ka_id;
    }
    if (ctime_start) {
      query.ctime_start = ctime_start;
    }
    if (ctime_end) {
      query.ctime_end = ctime_end;
    }
    if (verifyts_start) {
      query.verifyts_start = verifyts_start;
    }
    if (verifyts_end) {
      query.verifyts_end = verifyts_end;
    }
    query.timestamp = Math.round(Date.now() / 1000);
    this.pagination.current = 1;
    this.selectedRowKeys = [];
    this.selectedData = [];
    this.calculateSettlementAmount();
    this.$router.push({
      query,
    });
  }

  /**
   * 获取品牌列表
   */
  async getKaList() {
    const id = this.userService.currentUser()?.id;
    const response = await this.shopService.postKaUserList(id);
    if (response) {
      this.filterForm.ka_id = response[0]?.kaId;
      this.kalist = response;
      const params = {
        ka_id: this.filterForm.ka_id,
        page: 1,
        pageSize: 10,
      };
      this.getWithdrawList(params);
    }
  }


  /**
   * 提现
   */
  async handleWithdraw() {
    if (!this.selectedData.length) {
      this.$message.warning('最少选择一条，申请提现');
    } else {
      const params = {
        ka_id: this.filterForm.ka_id,
        ids: this.selectedRowKeys,
      };
      if (Number(this.withdrawAmount) > this.maxAmount) {
        this.$message.warning(`提现金额不能超过${this.maxAmount}元`);
      } else {
        const response = await this.withdrawService.applyFor(params);
        if (response) {
          this.$message.success('提现申请成功');
          this.search();
        }
      }
    }
  }

  /**
   * 日期选择器改变事件
   * @param dates 日期范围
   * @param dateStrings 日期字符串
   * @param field 字段名称
   */
  onChange(dates: any, dateStrings: any, field: any) {
    // 获取field参数
    const currentField = field;
    if (dates) {
      if (currentField === 'orderTime') {
        this.filterForm.ctime_start = dates[0].format('YYYY-MM-DD');
        this.filterForm.ctime_end = dates[1].format('YYYY-MM-DD');
      } else if (currentField === 'verifyTime') {
        this.filterForm.verifyts_start = dates[0].format('YYYY-MM-DD');
        this.filterForm.verifyts_end = dates[1].format('YYYY-MM-DD');
        this.verifyts_data = dates;
      }
    } else if (currentField === 'orderTime') {
      this.filterForm.ctime_start = '';
      this.filterForm.ctime_end = '';
    } else if (currentField === 'verifyTime') {
      this.filterForm.verifyts_start = '';
      this.filterForm.verifyts_end = '';
    }
  }

  /**
   * 重置查询
   */
  resetQuery() {
    this.filterForm = {
      ka_id: this.kalist[0]?.kaId,
      ctime_start: '',
      ctime_end: '',
      verifyts_start: '',
      verifyts_end: '',
    };
    this.ctime_data = [];
    this.verifyts_data = [];
    this.selectedRowKeys = [];
    this.selectedData = [];
    this.calculateSettlementAmount();
    const query: any = {
      page: 1,
      ka_id: this.kalist[0]?.kaId,

    };
    this.pagination.current = 1;
    query.timestamp = Math.round(Date.now() / 1000);
    this.$router.push({
      query,
    });
  }

  /**
   * 刷新
   */
  refresh() {
    this.resetQuery();
  }

  mounted() {
    this.getKaList();
    // this.filterFormChange();
  }
}
