
import { ISPUCategory } from '@/common/interface/spu';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import {
  Vue,
  Component,
  Watch,
  Prop,
  Model,
  Emit,
} from 'vue-property-decorator';

@Component({})
export default class CategoryField extends Vue {
  val: ISPUCategory | { id: undefined; name: string } = {
    id: undefined,
    name: '',
  };

  /** 全部二级分类数据 */
  secondaryCategories: ISPUCategory[] = [];

  @Prop()
  placeholder!: string;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  refs!: any;


  @Prop()
  shopId!: any;

  @Model()
  value!: ISPUCategory;

  @Watch('value')
  async watchValue() {
    if (this.shopId) {
      const s = await this.spuService.getIsSupport(this.shopId);
      if (!s) {
        const Arr: any = [];
        const _d = JSON.parse(JSON.stringify(this.allSpuCategory));
        _d.forEach((res: any) => {
          const _res: any = JSON.parse(JSON.stringify(res));
          _res.children = [];
          if (res.children) {
            res.children.forEach((item: any) => {
              if (item.onsite_service === 1) {
                _res.children.push(item);
              }
            });
          }
          if (_res.children.length > 0) {
            Arr.push(_res);
          }
        });
        this.allSpuCategory = Arr;
      }
    }

    if (this.value && this.value.id !== 0) {
      this.val = { ...this.value };
      return;
    }
    this.val = {
      id: undefined,
      name: '',
    };
  }

  @Emit('update')
  updateVal() {
    return this.val;
  }

  @lazyInject(SPUService)
  protected spuService!: SPUService;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterOption(input: any, option: any) {
    if (!option.componentOptions.propsData.label) {
      return;
    }
    return (
      option.componentOptions.propsData.label
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  async onChange(id: number) {
    if (id === this.val.id) {
      return;
    }

    const category = this.secondaryCategories.find((item) => item.id === id);
    if (category) {
      this.val = { ...category };
    }
    this.updateVal();
  }

  getCategoryName(id: number) {
    const target = this.secondaryCategories.find((item) => item.id === id);
    if (target) {
      return target.name;
    }
    return target;
  }
  allSpuCategory: any[] = [];
  async getCategory() {
    const data = await this.spuService.spuCategories({
      aggregation: true,
      loadingEl: this.$el,
    });
    if (data && data.length) {
      this.allSpuCategory = data;
      this.secondaryCategories = data.reduce((prev, cur) => {
        return prev.concat(cur.children || []);
      }, [] as ISPUCategory[]);
    }
  }

  async mounted() {
    await this.getCategory();
    this.watchValue();
  }
}
