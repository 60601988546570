
// import lazyInject from '@/di';
import { Vue, Component } from 'vue-property-decorator';
import { component as Viewer } from 'v-viewer';
import lazyInject from '@/di';
import SystemService from '@/services/system.service';

@Component({
  components: {
    Viewer,
  },
})
export default class DetailBrandModal extends Vue {
  @lazyInject(SystemService)
  protected systemService!: SystemService;

  type = 'add';

  uploading = false;

  titleName = '新增';

  isLoading = false;

  isVisible = false;

  token: string | undefined = undefined;

  imageListId: any = [];

  form = {
    key: '',
    value: '',
    status: 1,
    remark: '',
  };


  rules = {

  };

  async submit() {
    this.isLoading = true;
    let res: any;
    if (this.type === 'add') {
      res = await this.systemService.addConfig(this.form);
    } else {
      res = await this.systemService.editConfig(this.form);
    }
    this.isLoading = false;
    if (res) {
      this.close();
      this.$emit('refresh');
    }
  }

  reset() {

  }

  async open(type: string, record: any) {
    this.isVisible = true;
    this.titleName = type === 'edit' ? '编辑' : '新增';
    this.type = type;
    if (type === 'edit') {
      this.form = JSON.parse(JSON.stringify(record));
    } else {
      this.reset();
      this.form = {
        key: '',
        value: '',
        status: 1,
        remark: '',
      };
    }
  }

  close() {
    this.isVisible = false;
    this.reset();
  }

  created() {

  }
}
