
import { Component, Vue } from 'vue-property-decorator';
import { BASE_URL } from '@/common/constant';
import UserService from '@/services/user.service';
import lazyInject from '@/di';
import { ISPUImageUploadOptions } from '@/common/interface/spu';

@Component
export default class UploadModal extends Vue {
  @lazyInject(UserService)
  protected userService!: UserService;

  uploading = false;

  BASE_URL = BASE_URL;

  token: string | undefined = undefined;

  options: ISPUImageUploadOptions = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };
  visible = false;
  uploadUrl = '';
  headers = {};
  data = {};
  handleCancel() {
    this.visible = false;
  }
  handleOk() {
    this.visible = false;
  }
  init(val: any) {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
    this.visible = true;
    this.data = val;
  }
  handleSuccess(res: any) {
    console.log(res);
  }
  handleError(err: any) {
    console.log(err);
  }
  async onChange(info: any) {
    console.log(info);
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        // await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      this.uploading = false;
      this.$emit('refresh');
      this.visible = false;
      this.$message.success('上传成功');
    }
  }
  beforeUpload(file: { type: string; size: number }) {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      this.$message.error('照片必须小于5MB!');
    }

    return isLt5M;
  }
}
