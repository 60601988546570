
import {
  Vue,
  Component,
  Watch,
  Prop,
  Model,
  Emit,
} from 'vue-property-decorator';
import { BASE_URL } from '@/common/constant';
import lazyInject from '@/di';
import UserService from '@/services/user.service';
import { ISPUImageUploadOptions } from '@/common/interface/spu';
import { $delay } from '@/common/utils';
import { IImage } from '@/common/interface';

@Component({})
export default class HeadlineImageField extends Vue {
  val: IImage = {
    id: 0,
    url: '',
  };

  uploading = false;

  BASE_URL = BASE_URL;

  options: ISPUImageUploadOptions = {
    allowDuplicateUploads: false,
    target: `${BASE_URL}/tool/upload`,
    testChunks: false,
    headers: {},
    chunkSize: 5 * 1024 * 1024,
  };

  token: string | undefined = undefined;

  @Prop()
  placeholder?: string;

  @Prop()
  readonly!: boolean;

  @Prop()
  disabled!: boolean;

  @Prop()
  refs!: any;

  @Prop()
  fieldLabel!: string;

  @Model()
  value!: IImage;

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = { ...this.value };
    } else {
      this.val = { id: 0, url: '' };
    }
  }

  @Emit('update')
  updateVal() {
    return this.val;
  }

  @lazyInject(UserService)
  protected userService!: UserService;

  beforeUpload(file: { type: string; size: number }) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      this.$message.error('请上传 png/jpeg 格式的照片!');
    }
    return isJpgOrPng;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async onChange(info: any) {
    if (info.file.status === 'uploading') {
      this.uploading = true;
    }
    if (info.file.status === 'error') {
      this.uploading = false;
      const { code, error } = info.file.response;
      if (code === 400) {
        this.$message.error(error);
        await $delay(1000);
        this.$router.push({
          path: '/login',
          query: {
            redirect: this.$route.fullPath,
          },
        });
      } else {
        this.$message.error(error);
      }
    }
    if (info.file.status === 'done') {
      const { data } = JSON.parse(info.file.xhr.response);
      const result = {
        id: data[0]?.id,
        url: data[0]?.url,
      };
      this.val = { ...result };
      this.updateVal();
      this.$emit('change', this.val, this.fieldLabel);
      this.uploading = false;
    }
  }

  created() {
    this.options.headers.AUTHORIZATION = this.userService.currentUser()?.token;
    this.token = this.userService.currentUser()?.token;
  }

  mounted() {
    this.watchValue();
  }
}
