
import { Vue, Component, Watch, Prop, Model, Ref } from 'vue-property-decorator';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import { DebounceTime } from '@/common/decorator';
import { FormModel } from 'ant-design-vue';

@Component({})
export default class IntroductionField extends Vue {
  val = '';
  dddd = '是否需要预约';
  oldId = 0;

  @Ref()
  ruleForm!: FormModel;

  dataList: any[] = [];

  textData: any = [];

  introduction = '';

  intType = false;

  @lazyInject(SPUService)
  spuService!: SPUService;

  @Prop()
  placeholder?: string;

  @Prop()
  readonly!: boolean;

  @Prop()
  form!: any;
  @Prop()
  disabled?: boolean;

  @Prop()
  refs!: any;

  @Model('change', {
    type: String,
  })
  value!: string;

  @DebounceTime()
  @Watch('form.category')
  async watchCategory() {
    if (this.oldId !== this.form.category.id) {
      this.dataList = [];
      this.textData = [];
      if (!this.form.id) {
        const res = await this.spuService.getcategoryIdDetail(this.form.category.id);
        if (res) {
          let test = '';
          if (res.default_item) {
            test = res.default_item;
          } else if (res.p_default_item) {
            test = res.p_default_item;
          }
          this.val = test;
        }
      }
      this.oldId = this.form.category.id;
      const res = await this.spuService.postShopDetailItem({
        cid: this.form.category.id,
      });
      if (res) {
        this.dataList = res;
        this.dataList.forEach((item: any) => {
          if (item.category === 2) {
            item.value = [];
          }
          item.required = false;
        });
        const textArr = this.value.split('●');
        textArr.forEach((item: any) => {
          const _t = item.replace(/\n/g, '');
          const text = _t.split(': ');
          if (text.length > 1) {
            this.dataList.forEach((tam: any) => {
              if (tam.item === text[0]) {
                const _d = text[1].split(',');
                if (_d.length > 1) {
                  tam.value = _d;
                } else {
                  tam.value = text[1];
                }
              }
            });
          }
        });
      }
    }
  }


  public myMethod(): boolean {
    const typeB: any = [];
    this.dataList.forEach((item: any) => {
      if (item.category === 2 && item.value.length === 0) {
        item.required = true;
        typeB.push(false);
      } else if (!item.value) {
        item.required = true;
        typeB.push(false);
      } else {
        item.required = false;
        typeB.push(true);
      }
    });
    const set = new Set(typeB);
    const newArr = Array.from(set);
    if (newArr.length === 2) {
      return false;
    } else {
      const _n = newArr[0] as boolean;
      return _n;
    }
  }

  @Watch('form.id')
  watchForm() {}

  @Watch('val')
  watchInternalVal(): void {
    if (!this.val) {
      this.dataList.forEach((item: any) => {
        if (item.category === 2) {
          item.value = [];
        } else {
          item.value = undefined;
        }
      });
    }
    this.$emit('change', this.val);
  }

  @Watch('value')
  watchValue() {
    if (this.value) {
      this.val = this.value;
    } else {
      this.val = '';
    }
  }

  funIntType() {
    this.intType = !this.intType;
  }
  onChange(index: any, value: any) {
    const _v = value.join(',');
    this.fun(index, _v);
  }
  handleChange(index: any, value: any) {
    this.fun(index, value);
  }
  fun(index: any, value: any) {
    console.log(index, value);
    const data: any = this.dataList;
    // const { textData } = this;
    // console.log(this.val);
    // const textArr = this.val.split('\n');
    // console.log(textArr, textData);
    // data.forEach((item: any, i: any) => {
    //   if (i === index) {
    //     if (textData.find((rem: any) => rem.item === item.item)) {
    //       textData.find((rem: any) => rem.item === item.item).value = value;
    //     } else {
    //       textData.push({
    //         item: item.item,
    //         value,
    //       });
    //     }
    //   }
    // });
    const textData: any = [];
    data.forEach((item: any) => {
      const _v = Object.prototype.toString.call(item.value) === '[object Array]' ? item.value.join(',') : item.value;
      if (_v) {
        textData.push({
          item: item.item,
          value: _v,
        });
      }
    });
    this.val = textData.map((item: any) => `●${item.item}: \n${item.value}`).join('\n\n');
  }
  mounted() {
    this.watchValue();
    this.watchCategory();
    this.oldId = 0;
    // console.log(this.form.introduction);
    this.introduction = this.form.introduction;
  }
}
