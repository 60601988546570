
import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
import { ISPUCategory } from '@/common/interface/spu';
import lazyInject from '@/di';
import SPUService from '@/services/spu.service';
import { FormModel } from 'ant-design-vue';
import LogModal from './logModal.vue';

@Component({
  components: {
    'log-modal': LogModal,
  },
})
export default class SpuServicesModal extends Vue {
  @lazyInject(SPUService)
  protected spuService!: SPUService;

  @Ref()
  readonly logModal!: LogModal;

  visible = false;

  @Ref()
  readonly formRef!: FormModel;

  title = '新增商品服务详情属性';

  @Prop({ type: Array, default: () => [] })
  allSpuCategory!: ISPUCategory[];

  @Prop({ type: Array, default: () => [] })
  secondCategoryList!: ISPUCategory[];

  @Prop({ type: Boolean, default: false })
  spuType!: boolean;


  childCategoryList: ISPUCategory[] = [];
  form = {
    id: undefined,
    pid: undefined,
    cid: undefined,
    item: '',
    list: [{ value: '' }] as any,
    required: true,
    status: true,
    category: '',
  };
  userInfo = {
    create_user: {
      name: '',
      at: '',
    },
    edit_user: {
      name: '',
      at: '',
    },
  };
  formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };
  formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 16, offset: 6 },
    },
  };
  handleLog() {
    // this.logModal.open(this.form);

    const routeUrl = this.$router.resolve({
      name: 'spu.logList',
      params: {
        id: `${this.form.id}`,
        type: '1',
      },
    });

    window.open(routeUrl.href, '_blank');
  }

  open(data?: any) {
    this.visible = true;
    if (data) {
      this.title = this.spuType ? '查看商品服务详情属性' : '编辑商品服务详情属性';
      const _d = JSON.parse(JSON.stringify(data));
      this.userInfo = {
        create_user: {
          name: _d.create_user.name,
          at: _d.create_user.created_at,
        },
        edit_user: {
          name: _d.edit_user.name,
          at: _d.edit_user.created_at,
        },
      };
      _d.list = _d.item_data.map((item: any) => ({ value: item }));
      _d.required = _d.required === 2;
      _d.status = _d.status === 1;
      _d.pid = _d.pid || undefined;
      _d.cid = _d.cid || undefined;
      this.handleFcateChange(_d.pid);
      delete _d.item_data;
      this.form = _d;
    }
  }
  handleFcateChange(value: number) {
    this.form.cid = undefined;
    this.childCategoryList = this.allSpuCategory.find((item) => item.id === value)?.children || [];
  }
  cancel() {
    this.form = {
      id: undefined,
      pid: undefined,
      cid: undefined,
      item: '',
      list: [{ value: '' }] as any,
      required: true,
      status: true,
      category: '',
    };
    this.formRef.resetFields();
    this.visible = false;
  }

  remove(k: string) {
    const { form } = this;
    // can use data-binding to get
    const keys = form.list;
    // We need at least one passenger
    if (keys.length === 1) {
      return;
    }

    // can use data-binding to set
    form.list = keys.filter((key: string) => key !== k);
  }
  add() {
    const { form } = this;
    // can use data-binding to get
    const keys = form.list;
    keys.push({ value: '', key: Date.now() });
  }
  handleSubmit() {
    this.formRef.validate((valid: any) => {
      if (valid) {
        console.log(this.form);
        const data: any = {
          ...this.form,
          item_data: this.form.list.map((item: any) => item.value),
        };
        data.required = data.required ? 2 : 1;
        data.status = data.status ? 1 : 2;
        delete data.list;
        data.item_data = data.item_data.filter((item: any) => item !== null && item !== undefined && item !== '');
        if (this.hasDuplicates(data.item_data)) {
          this.$message.error('属性值不能重复');
          return;
        }
        if (this.form.id) {
          this.funEdit(data);
        } else {
          this.funAdd(data);
        }
      }
    });
  }
  hasDuplicates(array: any) {
    return new Set(array).size !== array.length;
  }
  async funEdit(data: any) {
    delete data.create_user;
    delete data.edit_user;
    delete data.query_last_update_log;
    const res = await this.spuService.editShopDetailItem(this.form.id, data);
    if (res) {
      this.$message.success('编辑成功');
      this.$emit('onQueryChange');
      this.cancel();
    }
  }
  async funAdd(data: any) {
    const res = await this.spuService.addShopDetailItem(data);
    if (res) {
      this.$message.success('新增成功');
      this.$emit('onQueryChange');
      this.cancel();
    }
  }
}
