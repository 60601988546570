import { render, staticRenderFns } from "./SPUTable.vue?vue&type=template&id=94d6828e&scoped=true"
import script from "./SPUTable.vue?vue&type=script&lang=ts"
export * from "./SPUTable.vue?vue&type=script&lang=ts"
import style0 from "./SPUTable.vue?vue&type=style&index=0&id=94d6828e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "94d6828e",
  null
  
)

export default component.exports