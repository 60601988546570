
import lazyInject from '@/di';
import { Vue, Component } from 'vue-property-decorator';
import SystemService from '@/services/system.service';
import WithdrawService from '@/services/withdraw.service';

@Component({})
export default class ExamineModal extends Vue {
  @lazyInject(SystemService)
  protected systemService!: SystemService;

  @lazyInject(WithdrawService)
  protected withdrawService!: WithdrawService;

  visible = false;
  inVisible = false;
  form = {
    reason: '',
  };
  rules = {
  };

  id = '';

  open(id: string) {
    this.id = id;
    this.visible = true;
  }
  async handleOk() {
    const { id } = this;
    const params = {
      wid: id,
      status: 1,
    };
    const res = await this.withdrawService.verifyWithdrawalOrder(params);
    if (res) {
      this.visible = false;
    }
  }
  handleCancel() {
    this.inVisible = true;
  }
  cancel(id: string) {
    this.id = id;
    this.visible = false;
  }
  async inHandleOk() {
    const params = {
      wid: this.id,
      status: 2,
      remark: this.form.reason,
    };
    const res = await this.withdrawService.verifyWithdrawalOrder(params);
    if (res) {
      this.inVisible = false;
      this.visible = false;
      this.$emit('refresh');
    }
  }
  inCancel() {
    this.inVisible = false;
  }
}
