
import { City } from '@/common/interface';
import {
  ECOrderRefundAction,
  ECOrderRefundProgress,
  ECOrderStatus,
} from '@/common/enum/order';
import lazyInject from '@/di';
import OrderService from '@/services/order.service';
import { Vue, Component, Watch, Ref } from 'vue-property-decorator';
import BaseBIPage from './components/BaseBIPage.vue';
import moment, { Moment } from 'moment';
import { ICOrder } from '@/common/interface/order';
import { COrder } from '@/models/order.model';
import BCOrderRemarkModal from './components/BCOrderRemarkModal.vue';
import COrderTransferModal from './components/COrderTransferModal.vue';
import { AuthEnum } from '@/services/permission.service';

interface Form {
  orderId: string; // 订单号
  orderStatus: ECOrderStatus; // 订单状态
  regionId: string | number; // 城市
  shopName: string; // 店铺名称
  poi: string;
  refundProgress: ECOrderRefundProgress;
  createStartTime: Moment | null;
  createEndTime: Moment | null;
  refundStartTime: Moment | null;
  refundEndTime: Moment | null;
}

@Component({
  components: {
    BaseBIPage,
    BCOrderRemarkModal,
    COrderTransferModal,
  },
})
export default class COrderList extends Vue {
  AuthEnum = AuthEnum;

  ECOrderStatus = ECOrderStatus;

  ECOrderRefundAction = ECOrderRefundAction;

  loading = false;

  form: Form = {
    orderId: '',
    orderStatus: ECOrderStatus.全部,
    regionId: '',
    shopName: '',
    poi: '',
    refundProgress: ECOrderRefundProgress.空, // 线下退款进度
    createStartTime: null,
    createEndTime: null,
    refundStartTime: null,
    refundEndTime: null,
  };

  cities: City[] = [];

  pagination = {
    total: 0,
    pageSize: 10,
    current: 1,
    onChange: this.paginationChange,
  };

  listData: ICOrder[] = [];

  orderStatusList: ECOrderStatus[] = [
    ECOrderStatus.全部,
    ECOrderStatus.未核销,
    ECOrderStatus.已核销,
  ];

  refundProgressList: ECOrderRefundProgress[] = [
    ECOrderRefundProgress.空,
    ECOrderRefundProgress.线下退款待转账,
    ECOrderRefundProgress.线下退款已转账,
  ];

  @Ref('bcOrderRemarkModal')
  bcOrderRemarkModal!: BCOrderRemarkModal;

  @Ref('cOrderTransferModal')
  cOrderTransferModal!: COrderTransferModal;

  @lazyInject(OrderService)
  orderService!: OrderService;

  /** 列表查询 */
  @Watch('$route')
  async handleSearch() {
    const { pageSize, current } = this.pagination;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = this.extractRouterQueryAndFillForm(true);
    param.pageSize = pageSize;
    param.page = current;

    await this.list(param);
  }

  openRefundModal(
    orderId: string,
    refundInfo?: {
      channel: string;
      transferOrderNum: string;
      refundAmount: string;
      refundTime: string;
      payee: string;
    },
  ) {
    this.cOrderTransferModal.open(orderId, refundInfo);
  }

  onChangeRefundTime(value: Moment[]) {
    if (!value.length) {
      this.form.refundStartTime = null;
      this.form.refundEndTime = null;
      return;
    }

    this.$set(
      this.form,
      'refundStartTime',
      moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
    );
    this.$set(
      this.form,
      'refundEndTime',
      moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    );
  }

  onChangeCreateTime(value: Moment[]) {
    if (!value.length) {
      this.form.createStartTime = null;
      this.form.createEndTime = null;
      return;
    }

    this.$set(
      this.form,
      'createStartTime',
      moment(value[0]).format('YYYY-MM-DD HH:mm:ss'),
    );
    this.$set(
      this.form,
      'createEndTime',
      moment(value[1]).format('YYYY-MM-DD HH:mm:ss'),
    );
  }

  openRemarkModal(orderId: string, remark: string) {
    this.bcOrderRemarkModal.open({
      type: 'COrder',
      title: '订单备注',
      orderId,
      text: remark,
    });
  }

  /** 省市过滤 */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  regionFilterOption(input: string, option: any) {
    return (
      option.componentOptions.children[0].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  extractFormParam() {
    const {
      poi,
      shopName,
      orderId,
      regionId,
      orderStatus,
      refundProgress,
      createStartTime,
      createEndTime,
      refundStartTime,
      refundEndTime,
    } = this.form;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {};

    if (poi) {
      param.poi = poi;
    }
    if (shopName) {
      param.shopName = shopName;
    }
    if (orderId) {
      param.orderId = orderId;
    }
    if (regionId) {
      param.regionId = regionId;
    }
    if (orderStatus !== ECOrderStatus.全部) {
      param.orderStatus = orderStatus;
    }
    if (refundProgress !== ECOrderRefundProgress.空) {
      param.refundProgress = refundProgress;
    }
    if (createStartTime && createEndTime) {
      param.createStartTime =
        typeof createStartTime === 'string'
          ? createStartTime
          : createStartTime.format('YYYY-MM-DD HH:mm:ss');
      param.createEndTime =
        typeof createEndTime === 'string'
          ? createEndTime
          : createEndTime.format('YYYY-MM-DD HH:mm:ss');
    }
    if (refundStartTime && refundEndTime) {
      param.refundStartTime =
        typeof refundStartTime === 'string'
          ? refundStartTime
          : refundStartTime.format('YYYY-MM-DD HH:mm:ss');
      param.refundEndTime =
        typeof refundEndTime === 'string'
          ? refundEndTime
          : refundEndTime.format('YYYY-MM-DD HH:mm:ss');
    }
    return param;
  }

  extractRouterQueryAndFillForm(shouldFillForm?: boolean) {
    const {
      poi,
      shopName,
      orderId,
      regionId,
      orderStatus,
      refundProgress,
      createStartTime,
      createEndTime,
      refundStartTime,
      refundEndTime,
    } = this.$route.query;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const param: any = {};
    if (poi) {
      param.poi = poi;
      if (shouldFillForm) {
        this.form.poi = poi as string;
      }
    }
    if (shopName) {
      param.shopName = shopName;
      if (shouldFillForm) {
        this.form.shopName = shopName as string;
      }
    }
    if (orderId) {
      param.orderId = orderId;
      if (shouldFillForm) {
        this.form.orderId = orderId as string;
      }
    }
    if (regionId) {
      param.regionId = regionId;
      if (shouldFillForm) {
        this.form.regionId = +regionId;
      }
    } else if (shouldFillForm) {
      this.form.regionId = '';
    }

    if (orderStatus) {
      param.orderStatus = orderStatus;
      if (shouldFillForm) {
        this.form.orderStatus = +orderStatus as ECOrderStatus;
      }
    } else if (shouldFillForm) {
      this.form.orderStatus = ECOrderStatus.全部;
    }
    if (refundProgress) {
      param.refundProgress = refundProgress;
      if (shouldFillForm) {
        this.form.refundProgress = +refundProgress as ECOrderRefundProgress;
      }
    } else {
      this.form.refundProgress = ECOrderRefundProgress.空;
    }

    if (
      createStartTime &&
      moment(createStartTime as string, moment.ISO_8601).isValid() &&
      createEndTime &&
      moment(createEndTime as string, moment.ISO_8601).isValid()
    ) {
      param.createStartTime = createStartTime;
      param.createEndTime = createEndTime;

      if (shouldFillForm) {
        this.form.createStartTime = moment(createStartTime as string);
        this.form.createEndTime = moment(createEndTime as string);
      }
    }
    if (
      refundStartTime &&
      moment(refundStartTime as string, moment.ISO_8601).isValid() &&
      refundEndTime &&
      moment(refundEndTime as string, moment.ISO_8601).isValid()
    ) {
      param.refundStartTime = refundStartTime;
      param.refundEndTime = refundEndTime;

      if (shouldFillForm) {
        this.form.refundStartTime = moment(refundStartTime as string);
        this.form.refundEndTime = moment(refundEndTime as string);
      }
    }
    return param;
  }

  paginationChange(current: number) {
    this.pagination.current = current;
    this.handleSearch();
  }

  resetForm() {
    this.form = {
      orderId: '',
      orderStatus: ECOrderStatus.全部,
      regionId: '',
      shopName: '',
      poi: '',
      refundProgress: ECOrderRefundProgress.空,
      createStartTime: null,
      createEndTime: null,
      refundStartTime: null,
      refundEndTime: null,
    };
  }

  /**
   * 页面刷新
   */
  refresh() {
    this.pagination = {
      total: 0,
      pageSize: 10,
      current: 1,
      onChange: this.paginationChange,
    };
    this.resetForm();
    this.$router.push({
      query: {},
    });
  }

  onClickBtn(item: COrder, action: ECOrderRefundAction) {
    // eslint-disable-next-line no-console
    console.log('点击按钮---', item, action);
  }

  async initRefund(order: COrder) {
    if (this.loading) {
      return;
    }
    this.loading = true;
    try {
      const res = await this.orderService.COrderRefundInitiate(order.id);
      if (res) {
        this.$message.success(`${res}`);
        this.handleSearch();
      }
      this.loading = false;
    } catch (error) {
      this.loading = false;
    }
  }

  /** 获取城市里列表 */
  async getCityList() {
    const response = (await this.orderService.getCityList()) as unknown as {
      [key: string]: {
        province: City;
        city: City[];
      };
    };
    if (response) {
      const cities = Object.values(response)
        .map((item) => item.city)
        .reduce((prev, cur) => prev.concat(cur), []);
      this.cities = cities;
    } else {
      this.cities = [];
    }
  }

  async list(params: {
    pageSize: number;
    page: number;
    orderId?: string; // 订单号
    orderStatus?: string; // 订单状态
    regionId?: string | number; // 城市
    shopName?: string; // 店铺名称
    poi?: string;
    refundProgress?: string;
    createStartTime?: string;
    createEndTime?: string;
    refundStartTime?: string;
    refundEndTime?: string;
  }) {
    const res = await this.orderService.COrderList(params);
    if (res) {
      this.listData = res.list;
      this.pagination = {
        total: res.total,
        current: res.page,
        pageSize: Number(res.pageSize),
        onChange: this.paginationChange,
      };
    }
  }

  /** 点击查询 */
  async search() {
    this.pagination = {
      total: 0,
      pageSize: 20,
      current: 1,
      onChange: this.paginationChange,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const query: any = this.extractFormParam();
    query.page = `${this.pagination.current}`;
    query.timestamp = Math.round(Date.now() / 1000);

    this.$router.push({
      query,
    });
  }

  async mounted() {
    const { page } = this.$route.query;
    if (typeof page !== 'undefined') {
      this.pagination.current = +page;
    }
    await this.getCityList();
    this.handleSearch();
  }
}
